
@import './../../styles/settings';
@import './../../styles/color';
@import './../../styles/mixins';

.nav-section {
	position: absolute;
	z-index: 10;
	width: 100vw;
	top: 0;
	left: 0;

	.top-nav {
		@include flexbox;
		@include flex-direction(row);
		@include justify-content(space-between);
		margin: $grid*4 0;

		.left-side {
			$logoBrdWidth: 6px;

			.header-logo{
				// border: $logoBrdWidth double $primaryColor;
				// //color: $txtColor;
				// //font-family: $fontFamilyHeadline;
				// font-size: 1.2rem;
				// line-height: ($grid*5) - ($logoBrdWidth*2);
				// text-align: center;
				// vertical-align: middle;
				// height: ($grid*5) - ($logoBrdWidth*2);
				// width: ($grid*5) - ($logoBrdWidth*2);
				//font-family: $fontFamilyLogoTitle;
				// font-family: $fontFamilyHeadline;
				font-weight: 600;
				//line-height: $grid*3;
				text-align: center;
				vertical-align: middle;
				//height: $grid*3;
				//background-color: $primaryColor;
				//color: $secondaryColor;
				// font-size: $h5;
				//padding: $grid $grid*2;

				span.logo-top-brd::before,
				span.logo-top-brd::after,
				span.logo-bottom-brd::before, 
				span.logo-bottom-brd::after{
					position: absolute;
					content: "";
					height: $grid;
					width: $grid;	
					background-color: transparent;
					border: $grid/4 solid $primaryColor;	
					
					@at-root .invert &{ border-color: $secondaryColor; }
				} 

				$logoCornerBrdPos: $grid*2;
				span.logo-top-brd::before{
					margin-top: -$logoCornerBrdPos;
					margin-left: -($logoCornerBrdPos + $grid);
					border-right: none;
					border-bottom: none;
				}
				span.logo-top-brd::after{
					margin-top: -$logoCornerBrdPos;
					margin-left: $logoCornerBrdPos;
					border-left: none;
					border-bottom: none;
				}
				span.logo-bottom-brd::before{
					margin-top: $logoCornerBrdPos + $grid;
					margin-left: -($logoCornerBrdPos + $grid);
					border-right: none;
					border-top: none;
				}
				span.logo-bottom-brd::after{
					margin-top: $logoCornerBrdPos + $grid;
					margin-left: $logoCornerBrdPos;
					border-left: none;
					border-top: none;
				}
			}
		}

		.right-side {
			ul {
				@include flexbox;
				@include flex-direction(row);
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					text-transform: uppercase;
					font-weight: 600;
					padding: $grid 0 $grid/2 0;
					margin: 0 $grid*2;
					a, a:hover, a:focus, a.active{ 
						//color: $txtColor;
						text-decoration: none;
					}

					&:hover {
						cursor: pointer;
						padding-bottom: $grid - 2;
						border-bottom: 2px solid $txtColor;
					}
				}
			}
		}
	}
}

.invert .nav-section{
	.left-side .logo-placeholder{
		border-color: $secondaryColor;
	}
	.right-side li:hover{ border-bottom-color: $secondaryColor !important; }
}
