@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Alike);
@import url(https://fonts.googleapis.com/css?family=Sacramento);
.grid-width {
  width: 100%; }

span.h0.headline {
  font-size: 119.20929px; }

h1.headline {
  font-size: 119.20929px;
  height: 145.28632px; }

h2.headline {
  font-size: 48.82813px; }

h3.headline {
  font-size: 39.0625px; }

h4.headline {
  font-size: 31.25px; }

h5.headline {
  font-size: 25px; }

h6.headline {
  font-size: 20px; }

.modal-is-open {
  overflow: hidden; }

.modal {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  transform: scale(0);
  background-color: rgba(252, 248, 243, 0.98); }
  .modal.close {
    transform: scale(1);
    -webkit-animation: unfoldOut 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: unfoldOut 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
    .modal.close .modal-content {
      -webkit-animation: zoomOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
              animation: zoomOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
  .modal.open {
    display: block;
    transform: scaleY(0.01) scaleX(0);
    -webkit-animation: unfoldIn 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            animation: unfoldIn 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
    .modal.open .modal-content {
      transform: scale(0);
      -webkit-animation: zoomIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
              animation: zoomIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; }
  .modal.invert {
    background-color: rgba(0, 0, 0, 0.98);
    color: #fcf8f3; }
  .modal .modal-close, .modal .modal-next, .modal .modal-prev {
    height: 40px;
    width: 40px;
    cursor: pointer;
    color: #fcf8f3;
    background-color: rgba(43, 46, 63, 0.5); }
    .modal .modal-close:not(.dis):hover, .modal .modal-next:not(.dis):hover, .modal .modal-prev:not(.dis):hover {
      background-color: black; }
    .modal .modal-close.dis, .modal .modal-next.dis, .modal .modal-prev.dis {
      opacity: 0.3;
      color: rgba(252, 248, 243, 0.5);
      cursor: default; }
    .modal .modal-close i.icon, .modal .modal-next i.icon, .modal .modal-prev i.icon {
      display: inline-block;
      line-height: 40px;
      height: 40px;
      width: 40px;
      text-align: center; }
  .modal .modal-close {
    position: absolute;
    top: 24px;
    right: 24px; }
  .modal .modal-nav-controller {
    position: absolute;
    bottom: 24px;
    right: 24px;
    display: flex;
    -moz-flex-direction: row;
    flex-direction: row; }
    .modal .modal-nav-controller .modal-counter {
      line-height: 40px;
      padding: 0 16px; }
    .modal .modal-nav-controller .modal-prev, .modal .modal-nav-controller .modal-next {
      margin-left: 2px; }
  .modal .modal-photography {
    display: flex;
    -moz-flex-direction: row;
    flex-direction: row; }
    .modal .modal-photography .modal-photo {
      display: flex;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center;
      -moz-flex-grow: 2;
      -ms-flex-grow: 2;
      flex-grow: 2;
      min-height: 100vh; }
      .modal .modal-photography .modal-photo img {
        margin: 0 auto;
        max-width: 100%;
        max-height: 100vh; }
    .modal .modal-photography .modal-photo-details {
      width: 360px;
      margin: 24px;
      background-color: rgba(43, 46, 63, 0.5);
      border-radius: 0;
      display: flex;
      -moz-flex-direction: column;
      flex-direction: column; }
      .modal .modal-photography .modal-photo-details .details, .modal .modal-photography .modal-photo-details .map {
        height: 50%; }
      .modal .modal-photography .modal-photo-details .details {
        display: flex;
        -moz-flex-direction: column;
        flex-direction: column;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        padding: 48px; }
        .modal .modal-photography .modal-photo-details .details .detail {
          width: 348px;
          margin-bottom: 24px;
          display: flex;
          -moz-flex-direction: row;
          flex-direction: row; }
          .modal .modal-photography .modal-photo-details .details .detail span {
            display: flex;
            -moz-flex-direction: column;
            flex-direction: column; }
            .modal .modal-photography .modal-photo-details .details .detail span .label {
              font-size: 12.8px;
              color: rgba(252, 248, 243, 0.5); }
            .modal .modal-photography .modal-photo-details .details .detail span .val {
              font-size: 16px; }
      .modal .modal-photography .modal-photo-details .map .mapboxgl-map {
        border-radius: 0 0 0 0; }
        .modal .modal-photography .modal-photo-details .map .mapboxgl-map .mapboxgl-ctrl {
          display: none !important;
          visibility: hidden;
          height: 1px;
          width: 1px;
          left: 0;
          bottom: 0; }
      .modal .modal-photography .modal-photo-details .map .mapboxgl-marker i.icon {
        font-size: 4rem;
        color: #d63c3c;
        text-shadow: 2px 0 4px rgba(43, 46, 63, 0.24); }
      .modal .modal-photography .modal-photo-details .map .no-map-msg {
        width: 100%;
        height: 100%;
        background-color: rgba(252, 248, 243, 0.05);
        display: flex;
        -moz-flex-direction: column;
        flex-direction: column;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        -ms-flex-pack: center;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center; }
        .modal .modal-photography .modal-photo-details .map .no-map-msg i.icon {
          font-size: 8rem;
          line-height: 12rem;
          opacity: .1; }

@-webkit-keyframes unfoldIn {
  0% {
    transform: scaleY(0.005) scaleX(0); }
  50% {
    transform: scaleY(0.005) scaleX(1); }
  100% {
    transform: scaleY(1) scaleX(1); } }

@keyframes unfoldIn {
  0% {
    transform: scaleY(0.005) scaleX(0); }
  50% {
    transform: scaleY(0.005) scaleX(1); }
  100% {
    transform: scaleY(1) scaleX(1); } }

@-webkit-keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1); }
  50% {
    transform: scaleY(0.005) scaleX(1); }
  100% {
    transform: scaleY(0.005) scaleX(0); } }

@keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1); }
  50% {
    transform: scaleY(0.005) scaleX(1); }
  100% {
    transform: scaleY(0.005) scaleX(0); } }

@-webkit-keyframes zoomIn {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes zoomIn {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes zoomOut {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes zoomOut {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

svg.progress-rect rect.progress-rect-stroke {
  stroke: rgba(43, 46, 63, 0.2); }

svg.progress-rect rect.progress-rect-progress-cover {
  stroke: #fcf8f3; }

svg.progress-rect rect.progress-rect-progress {
  stroke: #2b2e3f; }

svg.progress-rect .main-txt {
  font-size: 31.25px; }

section.section-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 24px; }

.skill {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  margin-bottom: 24px; }
  .skill .skill-name {
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 16px; }
  .skill .skill-rating {
    display: flex;
    -moz-flex-direction: row;
    flex-direction: row; }
    .skill .skill-rating .skill-rating-point {
      margin-right: 4px;
      width: 24px;
      height: 2px;
      background-color: rgba(43, 46, 63, 0.2); }
      .skill .skill-rating .skill-rating-point.active {
        background-color: #2b2e3f; }

.social-icon {
  display: flex;
  -moz-flex-direction: row;
  flex-direction: row;
  min-width: 48px;
  height: 48px;
  background-color: #2b2e3f;
  font-family: "Alike", serif;
  font-size: 20px;
  color: #fcf8f3;
  line-height: 48px;
  text-align: center;
  vertical-align: middle;
  text-decoration: none; }
  .social-icon:hover {
    color: #fcf8f3; }
  .social-icon .social-abbr {
    width: 48px; }
    .social-icon .social-abbr + .social-name {
      display: none;
      padding: 0 16px; }
    .social-icon .social-abbr:hover + .social-name {
      display: block; }

.social-icon-wrapper {
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start; }
  .social-icon-wrapper .social-icon {
    margin-bottom: 1px;
    float: left; }
  .social-icon-wrapper.inline {
    -moz-flex-direction: row;
    flex-direction: row; }
    .social-icon-wrapper.inline .social-icon {
      margin-right: 1px;
      float: left; }

.nav-section {
  position: absolute;
  z-index: 10;
  width: 100vw;
  top: 0;
  left: 0; }
  .nav-section .top-nav {
    display: flex;
    -moz-flex-direction: row;
    flex-direction: row;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin: 32px 0; }
    .nav-section .top-nav .left-side .header-logo {
      font-weight: 600;
      text-align: center;
      vertical-align: middle; }
      .nav-section .top-nav .left-side .header-logo span.logo-top-brd::before,
      .nav-section .top-nav .left-side .header-logo span.logo-top-brd::after,
      .nav-section .top-nav .left-side .header-logo span.logo-bottom-brd::before,
      .nav-section .top-nav .left-side .header-logo span.logo-bottom-brd::after {
        position: absolute;
        content: "";
        height: 8px;
        width: 8px;
        background-color: transparent;
        border: 2px solid #2b2e3f; }
        .invert .nav-section .top-nav .left-side .header-logo span.logo-top-brd::before, .invert
        .nav-section .top-nav .left-side .header-logo span.logo-top-brd::after, .invert
        .nav-section .top-nav .left-side .header-logo span.logo-bottom-brd::before, .invert
        .nav-section .top-nav .left-side .header-logo span.logo-bottom-brd::after {
          border-color: #fcf8f3; }
      .nav-section .top-nav .left-side .header-logo span.logo-top-brd::before {
        margin-top: -16px;
        margin-left: -24px;
        border-right: none;
        border-bottom: none; }
      .nav-section .top-nav .left-side .header-logo span.logo-top-brd::after {
        margin-top: -16px;
        margin-left: 16px;
        border-left: none;
        border-bottom: none; }
      .nav-section .top-nav .left-side .header-logo span.logo-bottom-brd::before {
        margin-top: 24px;
        margin-left: -24px;
        border-right: none;
        border-top: none; }
      .nav-section .top-nav .left-side .header-logo span.logo-bottom-brd::after {
        margin-top: 24px;
        margin-left: 16px;
        border-left: none;
        border-top: none; }
    .nav-section .top-nav .right-side ul {
      display: flex;
      -moz-flex-direction: row;
      flex-direction: row;
      list-style: none;
      padding: 0;
      margin: 0; }
      .nav-section .top-nav .right-side ul li {
        text-transform: uppercase;
        font-weight: 600;
        padding: 8px 0 4px 0;
        margin: 0 16px; }
        .nav-section .top-nav .right-side ul li a, .nav-section .top-nav .right-side ul li a:hover, .nav-section .top-nav .right-side ul li a:focus, .nav-section .top-nav .right-side ul li a.active {
          text-decoration: none; }
        .nav-section .top-nav .right-side ul li:hover {
          cursor: pointer;
          padding-bottom: 6px;
          border-bottom: 2px solid #2b2e3f; }

.invert .nav-section .left-side .logo-placeholder {
  border-color: #fcf8f3; }

.invert .nav-section .right-side li:hover {
  border-bottom-color: #fcf8f3 !important; }

footer {
  text-align: center;
  padding: 16px 24px;
  padding: 240px 0 40px 0; }
  footer div {
    content: " ";
    width: 80px;
    height: 2px;
    background-color: #2b2e3f;
    margin: 16px auto; }
    .invert footer div {
      background-color: #fcf8f3; }

.main-container.invert {
  background-color: #2b2e3f;
  color: #fcf8f3 !important; }
  .main-container.invert a, .main-container.invert a:hover, .main-container.invert a:focus, .main-container.invert a.active {
    color: #fcf8f3; }

.home-cover {
  height: 100vh;
  width: 100vw;
  background-image: url(../../static/media/piriArtworkBg.1a2cc213.jpg);
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; }
  .home-cover .section-container.header {
    height: 100vh; }
    .home-cover .section-container.header .cover-bg-content svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%); }
    .home-cover .section-container.header .cover-bg-content .stripps div {
      height: 40px;
      width: 100%;
      background-color: rgba(252, 248, 243, 0.8);
      margin-bottom: 16px; }
    .home-cover .section-container.header .cover-bg-content .stripps span.txt {
      width: 100%;
      position: relative;
      display: flex;
      -moz-flex-direction: column;
      flex-direction: column;
      -ms-flex-shrink: 2;
      flex-shrink: 2;
      margin-bottom: -208px; }
      .home-cover .section-container.header .cover-bg-content .stripps span.txt span {
        height: 96px; }
        .home-cover .section-container.header .cover-bg-content .stripps span.txt span.left {
          -ms-align-self: flex-start;
          align-self: flex-start; }
        .home-cover .section-container.header .cover-bg-content .stripps span.txt span.right {
          -ms-align-self: flex-end;
          align-self: flex-end;
          margin-top: 16px; }
        .home-cover .section-container.header .cover-bg-content .stripps span.txt span h1 {
          font-family: "Open Sans", sans-serif;
          text-transform: uppercase;
          color: rgba(252, 248, 243, 0.8);
          width: auto;
          padding: 0 24px;
          margin: 0;
          line-height: 90px;
          height: 96px;
          background: url(../../static/media/piriArtworkBg.1a2cc213.jpg) no-repeat top center fixed;
          background-size: cover; }
          .home-cover .section-container.header .cover-bg-content .stripps span.txt span h1.left {
            padding-left: 0; }
          .home-cover .section-container.header .cover-bg-content .stripps span.txt span h1.right {
            padding-right: 0; }
  .home-cover.switchBgToDeco {
    background: #fcf8f3; }
    .home-cover.switchBgToDeco .stripps div {
      background: url(../../static/media/piriArtworkBg.1a2cc213.jpg) no-repeat top center fixed;
      background-size: cover; }
    .home-cover.switchBgToDeco .stripps span.txt span {
      background-color: #fcf8f3; }
      .home-cover.switchBgToDeco .stripps span.txt span h1 {
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text !important;
        background-size: unset !important;
        color: #2b2e3f;
        position: relative;
        z-index: 3; }

.home-content .section-container.about {
  height: 100vh; }
  .home-content .section-container.about .super-title {
    width: 304px;
    height: 304px;
    position: relative;
    overflow: hidden;
    top: 120px;
    transform: rotate(-45deg);
    border: 4px solid #2b2e3f; }
    .home-content .section-container.about .super-title::before {
      content: "";
      position: absolute;
      width: 200%;
      height: 200%;
      top: -50%;
      left: -50%;
      z-index: -1;
      background: url(../../static/media/piriArtworkBg.1a2cc213.jpg) no-repeat top center fixed;
      background-size: cover;
      transform: rotate(45deg); }
    .home-content .section-container.about .super-title svg {
      transform: rotate(45deg);
      margin-left: -80px;
      margin-right: 40px; }
    .home-content .section-container.about .super-title .h0 {
      color: #2b2e3f;
      line-height: 320px;
      display: block;
      text-align: center; }
  .home-content .section-container.about .content {
    position: relative;
    top: 224px;
    max-width: 55em;
    margin-left: auto; }

.home-content .section-container.experience, .home-content .section-container.education, .home-content .section-container.skills {
  min-height: 100vh; }
  .home-content .section-container.experience .home-item, .home-content .section-container.education .home-item, .home-content .section-container.skills .home-item {
    display: flex;
    -moz-flex-direction: row;
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px; }
    .home-content .section-container.experience .home-item .home-section-title, .home-content .section-container.education .home-item .home-section-title, .home-content .section-container.skills .home-item .home-section-title {
      margin-top: 4px; }
      .home-content .section-container.experience .home-item .home-section-title div, .home-content .section-container.education .home-item .home-section-title div, .home-content .section-container.skills .home-item .home-section-title div {
        height: 2px;
        width: 1em;
        margin-top: 8px;
        background-color: #2b2e3f; }

.photo-section {
  min-height: 100vh;
  width: 100vw;
  padding-top: 160px !important;
  padding-bottom: 160px !important; }
  .photo-section img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
    .photo-section img:hover {
      -webkit-filter: none;
      filter: none; }

@charset "UTF-8";
* {
  transition: all 0.2s ease-in-out; }

*:focus, *:active {
  outline: none; }

*, *:before, *:after {
  box-sizing: border-box; }

html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto; }

body {
  height: 100%;
  width: 100%;
  background-color: #fcf8f3; }

/* Float */
.float.left {
  float: left; }

.float.right {
  float: right; }

/* Resetting Padding and Margin*/
.clear-padding {
  padding: 0 !important; }
  .clear-padding-left {
    padding-left: 0 !important; }
  .clear-padding-right {
    padding-right: 0 !important; }
  .clear-padding-top {
    padding-top: 0 !important; }
  .clear-padding-bottom {
    padding-bottom: 0 !important; }
  .clear-padding-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .clear-padding-lr {
    padding-left: 0 !important;
    padding-right: 0 !important; }

.clear-margin {
  margin: 0 !important; }
  .clear-margin-left {
    margin-left: 0 !important; }
  .clear-margin-right {
    margin-right: 0 !important; }
  .clear-margin-top {
    margin-top: 0 !important; }
  .clear-margin-bottom {
    margin-bottom: 0 !important; }
  .clear-margin-tb {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .clear-margin-lr {
    margin-left: 0 !important;
    margin-right: 0 !important; }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-1px {
  padding: 1px !important; }
  @media (max-width: 767px) {
    .padding-1px-mobile {
      padding: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-1px-tablet {
      padding: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-1px-computer {
      padding: 1px !important; } }
  @media (min-width: 1200px) {
    .padding-1px-largeScreen {
      padding: 1px !important; } }
  @media print {
    .padding-1px-print {
      padding: 1px !important; } }

.padding-top-1px {
  padding-top: 1px !important; }
  @media (max-width: 767px) {
    .padding-top-1px-mobile {
      padding-top: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-1px-tablet {
      padding-top: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-1px-computer {
      padding-top: 1px !important; } }
  @media (min-width: 1200px) {
    .padding-top-1px-largeScreen {
      padding-top: 1px !important; } }
  @media print {
    .padding-top-1px-print {
      padding-top: 1px !important; } }

.padding-bottom-1px {
  padding-bottom: 1px !important; }
  @media (max-width: 767px) {
    .padding-bottom-1px-mobile {
      padding-bottom: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-1px-tablet {
      padding-bottom: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-1px-computer {
      padding-bottom: 1px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-1px-largeScreen {
      padding-bottom: 1px !important; } }
  @media print {
    .padding-bottom-1px-print {
      padding-bottom: 1px !important; } }

.padding-left-1px {
  padding-left: 1px !important; }
  @media (max-width: 767px) {
    .padding-left-1px-mobile {
      padding-left: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-1px-tablet {
      padding-left: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-1px-computer {
      padding-left: 1px !important; } }
  @media (min-width: 1200px) {
    .padding-left-1px-largeScreen {
      padding-left: 1px !important; } }
  @media print {
    .padding-left-1px-print {
      padding-left: 1px !important; } }

.padding-right-1px {
  padding-right: 1px !important; }
  @media (max-width: 767px) {
    .padding-right-1px-mobile {
      padding-right: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-1px-tablet {
      padding-right: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-1px-computer {
      padding-right: 1px !important; } }
  @media (min-width: 1200px) {
    .padding-right-1px-largeScreen {
      padding-right: 1px !important; } }
  @media print {
    .padding-right-1px-print {
      padding-right: 1px !important; } }

.padding-tb-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important; }
  @media (max-width: 767px) {
    .padding-tb-1px-mobile {
      padding-top: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-1px-tablet {
      padding-top: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-1px-computer {
      padding-top: 1px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-1px-largeScreen {
      padding-top: 1px !important; } }
  @media print {
    .padding-tb-1px-print {
      padding-top: 1px !important; } }
  @media (max-width: 767px) {
    .padding-tb-1px-mobile {
      padding-bottom: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-1px-tablet {
      padding-bottom: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-1px-computer {
      padding-bottom: 1px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-1px-largeScreen {
      padding-bottom: 1px !important; } }
  @media print {
    .padding-tb-1px-print {
      padding-bottom: 1px !important; } }

.padding-lr-1px {
  padding-left: 1px !important;
  padding-right: 1px !important; }
  @media (max-width: 767px) {
    .padding-lr-1px-mobile {
      padding-left: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-1px-tablet {
      padding-left: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-1px-computer {
      padding-left: 1px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-1px-largeScreen {
      padding-left: 1px !important; } }
  @media print {
    .padding-lr-1px-print {
      padding-left: 1px !important; } }
  @media (max-width: 767px) {
    .padding-lr-1px-mobile {
      padding-right: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-1px-tablet {
      padding-right: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-1px-computer {
      padding-right: 1px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-1px-largeScreen {
      padding-right: 1px !important; } }
  @media print {
    .padding-lr-1px-print {
      padding-right: 1px !important; } }

.margin-1px {
  margin: 1px !important; }
  @media (max-width: 767px) {
    .margin-1px-mobile {
      margin: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-1px-tablet {
      margin: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-1px-computer {
      margin: 1px !important; } }
  @media (min-width: 1200px) {
    .margin-1px-largeScreen {
      margin: 1px !important; } }
  @media print {
    .margin-1px-print {
      margin: 1px !important; } }

.margin-top-1px {
  margin-top: 1px !important; }
  @media (max-width: 767px) {
    .margin-top-1px-mobile {
      margin-top: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-1px-tablet {
      margin-top: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-1px-computer {
      margin-top: 1px !important; } }
  @media (min-width: 1200px) {
    .margin-top-1px-largeScreen {
      margin-top: 1px !important; } }
  @media print {
    .margin-top-1px-print {
      margin-top: 1px !important; } }

.margin-bottom-1px {
  margin-bottom: 1px !important; }
  @media (max-width: 767px) {
    .margin-bottom-1px-mobile {
      margin-bottom: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-1px-tablet {
      margin-bottom: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-1px-computer {
      margin-bottom: 1px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-1px-largeScreen {
      margin-bottom: 1px !important; } }
  @media print {
    .margin-bottom-1px-print {
      margin-bottom: 1px !important; } }

.margin-left-1px {
  margin-left: 1px !important; }
  @media (max-width: 767px) {
    .margin-left-1px-mobile {
      margin-left: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-1px-tablet {
      margin-left: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-1px-computer {
      margin-left: 1px !important; } }
  @media (min-width: 1200px) {
    .margin-left-1px-largeScreen {
      margin-left: 1px !important; } }
  @media print {
    .margin-left-1px-print {
      margin-left: 1px !important; } }

.margin-right-1px {
  margin-right: 1px !important; }
  @media (max-width: 767px) {
    .margin-right-1px-mobile {
      margin-right: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-1px-tablet {
      margin-right: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-1px-computer {
      margin-right: 1px !important; } }
  @media (min-width: 1200px) {
    .margin-right-1px-largeScreen {
      margin-right: 1px !important; } }
  @media print {
    .margin-right-1px-print {
      margin-right: 1px !important; } }

.margin-tb-1px {
  margin-top: 1px !important;
  margin-bottom: 1px !important; }
  @media (max-width: 767px) {
    .margin-tb-1px-mobile {
      margin-top: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-1px-tablet {
      margin-top: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-1px-computer {
      margin-top: 1px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-1px-largeScreen {
      margin-top: 1px !important; } }
  @media print {
    .margin-tb-1px-print {
      margin-top: 1px !important; } }
  @media (max-width: 767px) {
    .margin-tb-1px-mobile {
      margin-bottom: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-1px-tablet {
      margin-bottom: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-1px-computer {
      margin-bottom: 1px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-1px-largeScreen {
      margin-bottom: 1px !important; } }
  @media print {
    .margin-tb-1px-print {
      margin-bottom: 1px !important; } }

.margin-lr-1px {
  margin-left: 1px !important;
  margin-right: 1px !important; }
  @media (max-width: 767px) {
    .margin-lr-1px-mobile {
      margin-left: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-1px-tablet {
      margin-left: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-1px-computer {
      margin-left: 1px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-1px-largeScreen {
      margin-left: 1px !important; } }
  @media print {
    .margin-lr-1px-print {
      margin-left: 1px !important; } }
  @media (max-width: 767px) {
    .margin-lr-1px-mobile {
      margin-right: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-1px-tablet {
      margin-right: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-1px-computer {
      margin-right: 1px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-1px-largeScreen {
      margin-right: 1px !important; } }
  @media print {
    .margin-lr-1px-print {
      margin-right: 1px !important; } }

/* Negative Margin*/
.neg-margin-1px {
  margin: 1px !important; }
  @media (max-width: 767px) {
    .neg-margin-1px-mobile {
      margin: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-1px-tablet {
      margin: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-1px-computer {
      margin: 1px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-1px-largeScreen {
      margin: 1px !important; } }
  @media print {
    .neg-margin-1px-print {
      margin: 1px !important; } }

.neg-margin-top-1px {
  margin-top: 1px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-1px-mobile {
      margin-top: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-1px-tablet {
      margin-top: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-1px-computer {
      margin-top: 1px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-1px-largeScreen {
      margin-top: 1px !important; } }
  @media print {
    .neg-margin-top-1px-print {
      margin-top: 1px !important; } }

.neg-margin-bottom-1px {
  margin-bottom: 1px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-1px-mobile {
      margin-bottom: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-1px-tablet {
      margin-bottom: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-1px-computer {
      margin-bottom: 1px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-1px-largeScreen {
      margin-bottom: 1px !important; } }
  @media print {
    .neg-margin-bottom-1px-print {
      margin-bottom: 1px !important; } }

.neg-margin-left-1px {
  margin-left: 1px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-1px-mobile {
      margin-left: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-1px-tablet {
      margin-left: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-1px-computer {
      margin-left: 1px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-1px-largeScreen {
      margin-left: 1px !important; } }
  @media print {
    .neg-margin-left-1px-print {
      margin-left: 1px !important; } }

.neg-margin-right-1px {
  margin-right: 1px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-1px-mobile {
      margin-right: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-1px-tablet {
      margin-right: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-1px-computer {
      margin-right: 1px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-1px-largeScreen {
      margin-right: 1px !important; } }
  @media print {
    .neg-margin-right-1px-print {
      margin-right: 1px !important; } }

.neg-margin-tb-1px {
  margin-top: 1px !important;
  margin-bottom: 1px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-1px-mobile {
      margin-top: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-1px-tablet {
      margin-top: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-1px-computer {
      margin-top: 1px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-1px-largeScreen {
      margin-top: 1px !important; } }
  @media print {
    .neg-margin-tb-1px-print {
      margin-top: 1px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-1px-mobile {
      margin-bottom: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-1px-tablet {
      margin-bottom: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-1px-computer {
      margin-bottom: 1px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-1px-largeScreen {
      margin-bottom: 1px !important; } }
  @media print {
    .neg-margin-tb-1px-print {
      margin-bottom: 1px !important; } }

.neg-margin-lr-1px {
  margin-left: 1px !important;
  margin-right: 1px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-1px-mobile {
      margin-left: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-1px-tablet {
      margin-left: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-1px-computer {
      margin-left: 1px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-1px-largeScreen {
      margin-left: 1px !important; } }
  @media print {
    .neg-margin-lr-1px-print {
      margin-left: 1px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-1px-mobile {
      margin-right: 1px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-1px-tablet {
      margin-right: 1px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-1px-computer {
      margin-right: 1px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-1px-largeScreen {
      margin-right: 1px !important; } }
  @media print {
    .neg-margin-lr-1px-print {
      margin-right: 1px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-2px {
  padding: 2px !important; }
  @media (max-width: 767px) {
    .padding-2px-mobile {
      padding: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-2px-tablet {
      padding: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-2px-computer {
      padding: 2px !important; } }
  @media (min-width: 1200px) {
    .padding-2px-largeScreen {
      padding: 2px !important; } }
  @media print {
    .padding-2px-print {
      padding: 2px !important; } }

.padding-top-2px {
  padding-top: 2px !important; }
  @media (max-width: 767px) {
    .padding-top-2px-mobile {
      padding-top: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-2px-tablet {
      padding-top: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-2px-computer {
      padding-top: 2px !important; } }
  @media (min-width: 1200px) {
    .padding-top-2px-largeScreen {
      padding-top: 2px !important; } }
  @media print {
    .padding-top-2px-print {
      padding-top: 2px !important; } }

.padding-bottom-2px {
  padding-bottom: 2px !important; }
  @media (max-width: 767px) {
    .padding-bottom-2px-mobile {
      padding-bottom: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-2px-tablet {
      padding-bottom: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-2px-computer {
      padding-bottom: 2px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-2px-largeScreen {
      padding-bottom: 2px !important; } }
  @media print {
    .padding-bottom-2px-print {
      padding-bottom: 2px !important; } }

.padding-left-2px {
  padding-left: 2px !important; }
  @media (max-width: 767px) {
    .padding-left-2px-mobile {
      padding-left: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-2px-tablet {
      padding-left: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-2px-computer {
      padding-left: 2px !important; } }
  @media (min-width: 1200px) {
    .padding-left-2px-largeScreen {
      padding-left: 2px !important; } }
  @media print {
    .padding-left-2px-print {
      padding-left: 2px !important; } }

.padding-right-2px {
  padding-right: 2px !important; }
  @media (max-width: 767px) {
    .padding-right-2px-mobile {
      padding-right: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-2px-tablet {
      padding-right: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-2px-computer {
      padding-right: 2px !important; } }
  @media (min-width: 1200px) {
    .padding-right-2px-largeScreen {
      padding-right: 2px !important; } }
  @media print {
    .padding-right-2px-print {
      padding-right: 2px !important; } }

.padding-tb-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important; }
  @media (max-width: 767px) {
    .padding-tb-2px-mobile {
      padding-top: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-2px-tablet {
      padding-top: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-2px-computer {
      padding-top: 2px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-2px-largeScreen {
      padding-top: 2px !important; } }
  @media print {
    .padding-tb-2px-print {
      padding-top: 2px !important; } }
  @media (max-width: 767px) {
    .padding-tb-2px-mobile {
      padding-bottom: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-2px-tablet {
      padding-bottom: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-2px-computer {
      padding-bottom: 2px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-2px-largeScreen {
      padding-bottom: 2px !important; } }
  @media print {
    .padding-tb-2px-print {
      padding-bottom: 2px !important; } }

.padding-lr-2px {
  padding-left: 2px !important;
  padding-right: 2px !important; }
  @media (max-width: 767px) {
    .padding-lr-2px-mobile {
      padding-left: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-2px-tablet {
      padding-left: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-2px-computer {
      padding-left: 2px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-2px-largeScreen {
      padding-left: 2px !important; } }
  @media print {
    .padding-lr-2px-print {
      padding-left: 2px !important; } }
  @media (max-width: 767px) {
    .padding-lr-2px-mobile {
      padding-right: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-2px-tablet {
      padding-right: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-2px-computer {
      padding-right: 2px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-2px-largeScreen {
      padding-right: 2px !important; } }
  @media print {
    .padding-lr-2px-print {
      padding-right: 2px !important; } }

.margin-2px {
  margin: 2px !important; }
  @media (max-width: 767px) {
    .margin-2px-mobile {
      margin: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-2px-tablet {
      margin: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-2px-computer {
      margin: 2px !important; } }
  @media (min-width: 1200px) {
    .margin-2px-largeScreen {
      margin: 2px !important; } }
  @media print {
    .margin-2px-print {
      margin: 2px !important; } }

.margin-top-2px {
  margin-top: 2px !important; }
  @media (max-width: 767px) {
    .margin-top-2px-mobile {
      margin-top: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-2px-tablet {
      margin-top: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-2px-computer {
      margin-top: 2px !important; } }
  @media (min-width: 1200px) {
    .margin-top-2px-largeScreen {
      margin-top: 2px !important; } }
  @media print {
    .margin-top-2px-print {
      margin-top: 2px !important; } }

.margin-bottom-2px {
  margin-bottom: 2px !important; }
  @media (max-width: 767px) {
    .margin-bottom-2px-mobile {
      margin-bottom: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-2px-tablet {
      margin-bottom: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-2px-computer {
      margin-bottom: 2px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-2px-largeScreen {
      margin-bottom: 2px !important; } }
  @media print {
    .margin-bottom-2px-print {
      margin-bottom: 2px !important; } }

.margin-left-2px {
  margin-left: 2px !important; }
  @media (max-width: 767px) {
    .margin-left-2px-mobile {
      margin-left: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-2px-tablet {
      margin-left: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-2px-computer {
      margin-left: 2px !important; } }
  @media (min-width: 1200px) {
    .margin-left-2px-largeScreen {
      margin-left: 2px !important; } }
  @media print {
    .margin-left-2px-print {
      margin-left: 2px !important; } }

.margin-right-2px {
  margin-right: 2px !important; }
  @media (max-width: 767px) {
    .margin-right-2px-mobile {
      margin-right: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-2px-tablet {
      margin-right: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-2px-computer {
      margin-right: 2px !important; } }
  @media (min-width: 1200px) {
    .margin-right-2px-largeScreen {
      margin-right: 2px !important; } }
  @media print {
    .margin-right-2px-print {
      margin-right: 2px !important; } }

.margin-tb-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important; }
  @media (max-width: 767px) {
    .margin-tb-2px-mobile {
      margin-top: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-2px-tablet {
      margin-top: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-2px-computer {
      margin-top: 2px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-2px-largeScreen {
      margin-top: 2px !important; } }
  @media print {
    .margin-tb-2px-print {
      margin-top: 2px !important; } }
  @media (max-width: 767px) {
    .margin-tb-2px-mobile {
      margin-bottom: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-2px-tablet {
      margin-bottom: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-2px-computer {
      margin-bottom: 2px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-2px-largeScreen {
      margin-bottom: 2px !important; } }
  @media print {
    .margin-tb-2px-print {
      margin-bottom: 2px !important; } }

.margin-lr-2px {
  margin-left: 2px !important;
  margin-right: 2px !important; }
  @media (max-width: 767px) {
    .margin-lr-2px-mobile {
      margin-left: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-2px-tablet {
      margin-left: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-2px-computer {
      margin-left: 2px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-2px-largeScreen {
      margin-left: 2px !important; } }
  @media print {
    .margin-lr-2px-print {
      margin-left: 2px !important; } }
  @media (max-width: 767px) {
    .margin-lr-2px-mobile {
      margin-right: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-2px-tablet {
      margin-right: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-2px-computer {
      margin-right: 2px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-2px-largeScreen {
      margin-right: 2px !important; } }
  @media print {
    .margin-lr-2px-print {
      margin-right: 2px !important; } }

/* Negative Margin*/
.neg-margin-2px {
  margin: 2px !important; }
  @media (max-width: 767px) {
    .neg-margin-2px-mobile {
      margin: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-2px-tablet {
      margin: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-2px-computer {
      margin: 2px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-2px-largeScreen {
      margin: 2px !important; } }
  @media print {
    .neg-margin-2px-print {
      margin: 2px !important; } }

.neg-margin-top-2px {
  margin-top: 2px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-2px-mobile {
      margin-top: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-2px-tablet {
      margin-top: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-2px-computer {
      margin-top: 2px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-2px-largeScreen {
      margin-top: 2px !important; } }
  @media print {
    .neg-margin-top-2px-print {
      margin-top: 2px !important; } }

.neg-margin-bottom-2px {
  margin-bottom: 2px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-2px-mobile {
      margin-bottom: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-2px-tablet {
      margin-bottom: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-2px-computer {
      margin-bottom: 2px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-2px-largeScreen {
      margin-bottom: 2px !important; } }
  @media print {
    .neg-margin-bottom-2px-print {
      margin-bottom: 2px !important; } }

.neg-margin-left-2px {
  margin-left: 2px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-2px-mobile {
      margin-left: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-2px-tablet {
      margin-left: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-2px-computer {
      margin-left: 2px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-2px-largeScreen {
      margin-left: 2px !important; } }
  @media print {
    .neg-margin-left-2px-print {
      margin-left: 2px !important; } }

.neg-margin-right-2px {
  margin-right: 2px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-2px-mobile {
      margin-right: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-2px-tablet {
      margin-right: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-2px-computer {
      margin-right: 2px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-2px-largeScreen {
      margin-right: 2px !important; } }
  @media print {
    .neg-margin-right-2px-print {
      margin-right: 2px !important; } }

.neg-margin-tb-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-2px-mobile {
      margin-top: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-2px-tablet {
      margin-top: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-2px-computer {
      margin-top: 2px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-2px-largeScreen {
      margin-top: 2px !important; } }
  @media print {
    .neg-margin-tb-2px-print {
      margin-top: 2px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-2px-mobile {
      margin-bottom: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-2px-tablet {
      margin-bottom: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-2px-computer {
      margin-bottom: 2px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-2px-largeScreen {
      margin-bottom: 2px !important; } }
  @media print {
    .neg-margin-tb-2px-print {
      margin-bottom: 2px !important; } }

.neg-margin-lr-2px {
  margin-left: 2px !important;
  margin-right: 2px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-2px-mobile {
      margin-left: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-2px-tablet {
      margin-left: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-2px-computer {
      margin-left: 2px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-2px-largeScreen {
      margin-left: 2px !important; } }
  @media print {
    .neg-margin-lr-2px-print {
      margin-left: 2px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-2px-mobile {
      margin-right: 2px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-2px-tablet {
      margin-right: 2px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-2px-computer {
      margin-right: 2px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-2px-largeScreen {
      margin-right: 2px !important; } }
  @media print {
    .neg-margin-lr-2px-print {
      margin-right: 2px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-4px {
  padding: 4px !important; }
  @media (max-width: 767px) {
    .padding-4px-mobile {
      padding: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-4px-tablet {
      padding: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-4px-computer {
      padding: 4px !important; } }
  @media (min-width: 1200px) {
    .padding-4px-largeScreen {
      padding: 4px !important; } }
  @media print {
    .padding-4px-print {
      padding: 4px !important; } }

.padding-top-4px {
  padding-top: 4px !important; }
  @media (max-width: 767px) {
    .padding-top-4px-mobile {
      padding-top: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-4px-tablet {
      padding-top: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-4px-computer {
      padding-top: 4px !important; } }
  @media (min-width: 1200px) {
    .padding-top-4px-largeScreen {
      padding-top: 4px !important; } }
  @media print {
    .padding-top-4px-print {
      padding-top: 4px !important; } }

.padding-bottom-4px {
  padding-bottom: 4px !important; }
  @media (max-width: 767px) {
    .padding-bottom-4px-mobile {
      padding-bottom: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-4px-tablet {
      padding-bottom: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-4px-computer {
      padding-bottom: 4px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-4px-largeScreen {
      padding-bottom: 4px !important; } }
  @media print {
    .padding-bottom-4px-print {
      padding-bottom: 4px !important; } }

.padding-left-4px {
  padding-left: 4px !important; }
  @media (max-width: 767px) {
    .padding-left-4px-mobile {
      padding-left: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-4px-tablet {
      padding-left: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-4px-computer {
      padding-left: 4px !important; } }
  @media (min-width: 1200px) {
    .padding-left-4px-largeScreen {
      padding-left: 4px !important; } }
  @media print {
    .padding-left-4px-print {
      padding-left: 4px !important; } }

.padding-right-4px {
  padding-right: 4px !important; }
  @media (max-width: 767px) {
    .padding-right-4px-mobile {
      padding-right: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-4px-tablet {
      padding-right: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-4px-computer {
      padding-right: 4px !important; } }
  @media (min-width: 1200px) {
    .padding-right-4px-largeScreen {
      padding-right: 4px !important; } }
  @media print {
    .padding-right-4px-print {
      padding-right: 4px !important; } }

.padding-tb-4px {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }
  @media (max-width: 767px) {
    .padding-tb-4px-mobile {
      padding-top: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-4px-tablet {
      padding-top: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-4px-computer {
      padding-top: 4px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-4px-largeScreen {
      padding-top: 4px !important; } }
  @media print {
    .padding-tb-4px-print {
      padding-top: 4px !important; } }
  @media (max-width: 767px) {
    .padding-tb-4px-mobile {
      padding-bottom: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-4px-tablet {
      padding-bottom: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-4px-computer {
      padding-bottom: 4px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-4px-largeScreen {
      padding-bottom: 4px !important; } }
  @media print {
    .padding-tb-4px-print {
      padding-bottom: 4px !important; } }

.padding-lr-4px {
  padding-left: 4px !important;
  padding-right: 4px !important; }
  @media (max-width: 767px) {
    .padding-lr-4px-mobile {
      padding-left: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-4px-tablet {
      padding-left: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-4px-computer {
      padding-left: 4px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-4px-largeScreen {
      padding-left: 4px !important; } }
  @media print {
    .padding-lr-4px-print {
      padding-left: 4px !important; } }
  @media (max-width: 767px) {
    .padding-lr-4px-mobile {
      padding-right: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-4px-tablet {
      padding-right: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-4px-computer {
      padding-right: 4px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-4px-largeScreen {
      padding-right: 4px !important; } }
  @media print {
    .padding-lr-4px-print {
      padding-right: 4px !important; } }

.margin-4px {
  margin: 4px !important; }
  @media (max-width: 767px) {
    .margin-4px-mobile {
      margin: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-4px-tablet {
      margin: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-4px-computer {
      margin: 4px !important; } }
  @media (min-width: 1200px) {
    .margin-4px-largeScreen {
      margin: 4px !important; } }
  @media print {
    .margin-4px-print {
      margin: 4px !important; } }

.margin-top-4px {
  margin-top: 4px !important; }
  @media (max-width: 767px) {
    .margin-top-4px-mobile {
      margin-top: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-4px-tablet {
      margin-top: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-4px-computer {
      margin-top: 4px !important; } }
  @media (min-width: 1200px) {
    .margin-top-4px-largeScreen {
      margin-top: 4px !important; } }
  @media print {
    .margin-top-4px-print {
      margin-top: 4px !important; } }

.margin-bottom-4px {
  margin-bottom: 4px !important; }
  @media (max-width: 767px) {
    .margin-bottom-4px-mobile {
      margin-bottom: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-4px-tablet {
      margin-bottom: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-4px-computer {
      margin-bottom: 4px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-4px-largeScreen {
      margin-bottom: 4px !important; } }
  @media print {
    .margin-bottom-4px-print {
      margin-bottom: 4px !important; } }

.margin-left-4px {
  margin-left: 4px !important; }
  @media (max-width: 767px) {
    .margin-left-4px-mobile {
      margin-left: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-4px-tablet {
      margin-left: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-4px-computer {
      margin-left: 4px !important; } }
  @media (min-width: 1200px) {
    .margin-left-4px-largeScreen {
      margin-left: 4px !important; } }
  @media print {
    .margin-left-4px-print {
      margin-left: 4px !important; } }

.margin-right-4px {
  margin-right: 4px !important; }
  @media (max-width: 767px) {
    .margin-right-4px-mobile {
      margin-right: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-4px-tablet {
      margin-right: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-4px-computer {
      margin-right: 4px !important; } }
  @media (min-width: 1200px) {
    .margin-right-4px-largeScreen {
      margin-right: 4px !important; } }
  @media print {
    .margin-right-4px-print {
      margin-right: 4px !important; } }

.margin-tb-4px {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }
  @media (max-width: 767px) {
    .margin-tb-4px-mobile {
      margin-top: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-4px-tablet {
      margin-top: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-4px-computer {
      margin-top: 4px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-4px-largeScreen {
      margin-top: 4px !important; } }
  @media print {
    .margin-tb-4px-print {
      margin-top: 4px !important; } }
  @media (max-width: 767px) {
    .margin-tb-4px-mobile {
      margin-bottom: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-4px-tablet {
      margin-bottom: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-4px-computer {
      margin-bottom: 4px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-4px-largeScreen {
      margin-bottom: 4px !important; } }
  @media print {
    .margin-tb-4px-print {
      margin-bottom: 4px !important; } }

.margin-lr-4px {
  margin-left: 4px !important;
  margin-right: 4px !important; }
  @media (max-width: 767px) {
    .margin-lr-4px-mobile {
      margin-left: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-4px-tablet {
      margin-left: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-4px-computer {
      margin-left: 4px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-4px-largeScreen {
      margin-left: 4px !important; } }
  @media print {
    .margin-lr-4px-print {
      margin-left: 4px !important; } }
  @media (max-width: 767px) {
    .margin-lr-4px-mobile {
      margin-right: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-4px-tablet {
      margin-right: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-4px-computer {
      margin-right: 4px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-4px-largeScreen {
      margin-right: 4px !important; } }
  @media print {
    .margin-lr-4px-print {
      margin-right: 4px !important; } }

/* Negative Margin*/
.neg-margin-4px {
  margin: 4px !important; }
  @media (max-width: 767px) {
    .neg-margin-4px-mobile {
      margin: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-4px-tablet {
      margin: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-4px-computer {
      margin: 4px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-4px-largeScreen {
      margin: 4px !important; } }
  @media print {
    .neg-margin-4px-print {
      margin: 4px !important; } }

.neg-margin-top-4px {
  margin-top: 4px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-4px-mobile {
      margin-top: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-4px-tablet {
      margin-top: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-4px-computer {
      margin-top: 4px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-4px-largeScreen {
      margin-top: 4px !important; } }
  @media print {
    .neg-margin-top-4px-print {
      margin-top: 4px !important; } }

.neg-margin-bottom-4px {
  margin-bottom: 4px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-4px-mobile {
      margin-bottom: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-4px-tablet {
      margin-bottom: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-4px-computer {
      margin-bottom: 4px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-4px-largeScreen {
      margin-bottom: 4px !important; } }
  @media print {
    .neg-margin-bottom-4px-print {
      margin-bottom: 4px !important; } }

.neg-margin-left-4px {
  margin-left: 4px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-4px-mobile {
      margin-left: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-4px-tablet {
      margin-left: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-4px-computer {
      margin-left: 4px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-4px-largeScreen {
      margin-left: 4px !important; } }
  @media print {
    .neg-margin-left-4px-print {
      margin-left: 4px !important; } }

.neg-margin-right-4px {
  margin-right: 4px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-4px-mobile {
      margin-right: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-4px-tablet {
      margin-right: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-4px-computer {
      margin-right: 4px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-4px-largeScreen {
      margin-right: 4px !important; } }
  @media print {
    .neg-margin-right-4px-print {
      margin-right: 4px !important; } }

.neg-margin-tb-4px {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-4px-mobile {
      margin-top: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-4px-tablet {
      margin-top: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-4px-computer {
      margin-top: 4px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-4px-largeScreen {
      margin-top: 4px !important; } }
  @media print {
    .neg-margin-tb-4px-print {
      margin-top: 4px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-4px-mobile {
      margin-bottom: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-4px-tablet {
      margin-bottom: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-4px-computer {
      margin-bottom: 4px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-4px-largeScreen {
      margin-bottom: 4px !important; } }
  @media print {
    .neg-margin-tb-4px-print {
      margin-bottom: 4px !important; } }

.neg-margin-lr-4px {
  margin-left: 4px !important;
  margin-right: 4px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-4px-mobile {
      margin-left: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-4px-tablet {
      margin-left: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-4px-computer {
      margin-left: 4px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-4px-largeScreen {
      margin-left: 4px !important; } }
  @media print {
    .neg-margin-lr-4px-print {
      margin-left: 4px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-4px-mobile {
      margin-right: 4px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-4px-tablet {
      margin-right: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-4px-computer {
      margin-right: 4px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-4px-largeScreen {
      margin-right: 4px !important; } }
  @media print {
    .neg-margin-lr-4px-print {
      margin-right: 4px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-6px {
  padding: 6px !important; }
  @media (max-width: 767px) {
    .padding-6px-mobile {
      padding: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-6px-tablet {
      padding: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-6px-computer {
      padding: 6px !important; } }
  @media (min-width: 1200px) {
    .padding-6px-largeScreen {
      padding: 6px !important; } }
  @media print {
    .padding-6px-print {
      padding: 6px !important; } }

.padding-top-6px {
  padding-top: 6px !important; }
  @media (max-width: 767px) {
    .padding-top-6px-mobile {
      padding-top: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-6px-tablet {
      padding-top: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-6px-computer {
      padding-top: 6px !important; } }
  @media (min-width: 1200px) {
    .padding-top-6px-largeScreen {
      padding-top: 6px !important; } }
  @media print {
    .padding-top-6px-print {
      padding-top: 6px !important; } }

.padding-bottom-6px {
  padding-bottom: 6px !important; }
  @media (max-width: 767px) {
    .padding-bottom-6px-mobile {
      padding-bottom: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-6px-tablet {
      padding-bottom: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-6px-computer {
      padding-bottom: 6px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-6px-largeScreen {
      padding-bottom: 6px !important; } }
  @media print {
    .padding-bottom-6px-print {
      padding-bottom: 6px !important; } }

.padding-left-6px {
  padding-left: 6px !important; }
  @media (max-width: 767px) {
    .padding-left-6px-mobile {
      padding-left: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-6px-tablet {
      padding-left: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-6px-computer {
      padding-left: 6px !important; } }
  @media (min-width: 1200px) {
    .padding-left-6px-largeScreen {
      padding-left: 6px !important; } }
  @media print {
    .padding-left-6px-print {
      padding-left: 6px !important; } }

.padding-right-6px {
  padding-right: 6px !important; }
  @media (max-width: 767px) {
    .padding-right-6px-mobile {
      padding-right: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-6px-tablet {
      padding-right: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-6px-computer {
      padding-right: 6px !important; } }
  @media (min-width: 1200px) {
    .padding-right-6px-largeScreen {
      padding-right: 6px !important; } }
  @media print {
    .padding-right-6px-print {
      padding-right: 6px !important; } }

.padding-tb-6px {
  padding-top: 6px !important;
  padding-bottom: 6px !important; }
  @media (max-width: 767px) {
    .padding-tb-6px-mobile {
      padding-top: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-6px-tablet {
      padding-top: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-6px-computer {
      padding-top: 6px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-6px-largeScreen {
      padding-top: 6px !important; } }
  @media print {
    .padding-tb-6px-print {
      padding-top: 6px !important; } }
  @media (max-width: 767px) {
    .padding-tb-6px-mobile {
      padding-bottom: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-6px-tablet {
      padding-bottom: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-6px-computer {
      padding-bottom: 6px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-6px-largeScreen {
      padding-bottom: 6px !important; } }
  @media print {
    .padding-tb-6px-print {
      padding-bottom: 6px !important; } }

.padding-lr-6px {
  padding-left: 6px !important;
  padding-right: 6px !important; }
  @media (max-width: 767px) {
    .padding-lr-6px-mobile {
      padding-left: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-6px-tablet {
      padding-left: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-6px-computer {
      padding-left: 6px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-6px-largeScreen {
      padding-left: 6px !important; } }
  @media print {
    .padding-lr-6px-print {
      padding-left: 6px !important; } }
  @media (max-width: 767px) {
    .padding-lr-6px-mobile {
      padding-right: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-6px-tablet {
      padding-right: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-6px-computer {
      padding-right: 6px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-6px-largeScreen {
      padding-right: 6px !important; } }
  @media print {
    .padding-lr-6px-print {
      padding-right: 6px !important; } }

.margin-6px {
  margin: 6px !important; }
  @media (max-width: 767px) {
    .margin-6px-mobile {
      margin: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-6px-tablet {
      margin: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-6px-computer {
      margin: 6px !important; } }
  @media (min-width: 1200px) {
    .margin-6px-largeScreen {
      margin: 6px !important; } }
  @media print {
    .margin-6px-print {
      margin: 6px !important; } }

.margin-top-6px {
  margin-top: 6px !important; }
  @media (max-width: 767px) {
    .margin-top-6px-mobile {
      margin-top: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-6px-tablet {
      margin-top: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-6px-computer {
      margin-top: 6px !important; } }
  @media (min-width: 1200px) {
    .margin-top-6px-largeScreen {
      margin-top: 6px !important; } }
  @media print {
    .margin-top-6px-print {
      margin-top: 6px !important; } }

.margin-bottom-6px {
  margin-bottom: 6px !important; }
  @media (max-width: 767px) {
    .margin-bottom-6px-mobile {
      margin-bottom: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-6px-tablet {
      margin-bottom: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-6px-computer {
      margin-bottom: 6px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-6px-largeScreen {
      margin-bottom: 6px !important; } }
  @media print {
    .margin-bottom-6px-print {
      margin-bottom: 6px !important; } }

.margin-left-6px {
  margin-left: 6px !important; }
  @media (max-width: 767px) {
    .margin-left-6px-mobile {
      margin-left: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-6px-tablet {
      margin-left: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-6px-computer {
      margin-left: 6px !important; } }
  @media (min-width: 1200px) {
    .margin-left-6px-largeScreen {
      margin-left: 6px !important; } }
  @media print {
    .margin-left-6px-print {
      margin-left: 6px !important; } }

.margin-right-6px {
  margin-right: 6px !important; }
  @media (max-width: 767px) {
    .margin-right-6px-mobile {
      margin-right: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-6px-tablet {
      margin-right: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-6px-computer {
      margin-right: 6px !important; } }
  @media (min-width: 1200px) {
    .margin-right-6px-largeScreen {
      margin-right: 6px !important; } }
  @media print {
    .margin-right-6px-print {
      margin-right: 6px !important; } }

.margin-tb-6px {
  margin-top: 6px !important;
  margin-bottom: 6px !important; }
  @media (max-width: 767px) {
    .margin-tb-6px-mobile {
      margin-top: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-6px-tablet {
      margin-top: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-6px-computer {
      margin-top: 6px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-6px-largeScreen {
      margin-top: 6px !important; } }
  @media print {
    .margin-tb-6px-print {
      margin-top: 6px !important; } }
  @media (max-width: 767px) {
    .margin-tb-6px-mobile {
      margin-bottom: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-6px-tablet {
      margin-bottom: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-6px-computer {
      margin-bottom: 6px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-6px-largeScreen {
      margin-bottom: 6px !important; } }
  @media print {
    .margin-tb-6px-print {
      margin-bottom: 6px !important; } }

.margin-lr-6px {
  margin-left: 6px !important;
  margin-right: 6px !important; }
  @media (max-width: 767px) {
    .margin-lr-6px-mobile {
      margin-left: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-6px-tablet {
      margin-left: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-6px-computer {
      margin-left: 6px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-6px-largeScreen {
      margin-left: 6px !important; } }
  @media print {
    .margin-lr-6px-print {
      margin-left: 6px !important; } }
  @media (max-width: 767px) {
    .margin-lr-6px-mobile {
      margin-right: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-6px-tablet {
      margin-right: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-6px-computer {
      margin-right: 6px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-6px-largeScreen {
      margin-right: 6px !important; } }
  @media print {
    .margin-lr-6px-print {
      margin-right: 6px !important; } }

/* Negative Margin*/
.neg-margin-6px {
  margin: 6px !important; }
  @media (max-width: 767px) {
    .neg-margin-6px-mobile {
      margin: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-6px-tablet {
      margin: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-6px-computer {
      margin: 6px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-6px-largeScreen {
      margin: 6px !important; } }
  @media print {
    .neg-margin-6px-print {
      margin: 6px !important; } }

.neg-margin-top-6px {
  margin-top: 6px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-6px-mobile {
      margin-top: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-6px-tablet {
      margin-top: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-6px-computer {
      margin-top: 6px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-6px-largeScreen {
      margin-top: 6px !important; } }
  @media print {
    .neg-margin-top-6px-print {
      margin-top: 6px !important; } }

.neg-margin-bottom-6px {
  margin-bottom: 6px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-6px-mobile {
      margin-bottom: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-6px-tablet {
      margin-bottom: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-6px-computer {
      margin-bottom: 6px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-6px-largeScreen {
      margin-bottom: 6px !important; } }
  @media print {
    .neg-margin-bottom-6px-print {
      margin-bottom: 6px !important; } }

.neg-margin-left-6px {
  margin-left: 6px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-6px-mobile {
      margin-left: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-6px-tablet {
      margin-left: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-6px-computer {
      margin-left: 6px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-6px-largeScreen {
      margin-left: 6px !important; } }
  @media print {
    .neg-margin-left-6px-print {
      margin-left: 6px !important; } }

.neg-margin-right-6px {
  margin-right: 6px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-6px-mobile {
      margin-right: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-6px-tablet {
      margin-right: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-6px-computer {
      margin-right: 6px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-6px-largeScreen {
      margin-right: 6px !important; } }
  @media print {
    .neg-margin-right-6px-print {
      margin-right: 6px !important; } }

.neg-margin-tb-6px {
  margin-top: 6px !important;
  margin-bottom: 6px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-6px-mobile {
      margin-top: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-6px-tablet {
      margin-top: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-6px-computer {
      margin-top: 6px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-6px-largeScreen {
      margin-top: 6px !important; } }
  @media print {
    .neg-margin-tb-6px-print {
      margin-top: 6px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-6px-mobile {
      margin-bottom: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-6px-tablet {
      margin-bottom: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-6px-computer {
      margin-bottom: 6px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-6px-largeScreen {
      margin-bottom: 6px !important; } }
  @media print {
    .neg-margin-tb-6px-print {
      margin-bottom: 6px !important; } }

.neg-margin-lr-6px {
  margin-left: 6px !important;
  margin-right: 6px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-6px-mobile {
      margin-left: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-6px-tablet {
      margin-left: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-6px-computer {
      margin-left: 6px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-6px-largeScreen {
      margin-left: 6px !important; } }
  @media print {
    .neg-margin-lr-6px-print {
      margin-left: 6px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-6px-mobile {
      margin-right: 6px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-6px-tablet {
      margin-right: 6px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-6px-computer {
      margin-right: 6px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-6px-largeScreen {
      margin-right: 6px !important; } }
  @media print {
    .neg-margin-lr-6px-print {
      margin-right: 6px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-8px {
  padding: 8px !important; }
  @media (max-width: 767px) {
    .padding-8px-mobile {
      padding: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-8px-tablet {
      padding: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-8px-computer {
      padding: 8px !important; } }
  @media (min-width: 1200px) {
    .padding-8px-largeScreen {
      padding: 8px !important; } }
  @media print {
    .padding-8px-print {
      padding: 8px !important; } }

.padding-top-8px {
  padding-top: 8px !important; }
  @media (max-width: 767px) {
    .padding-top-8px-mobile {
      padding-top: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-8px-tablet {
      padding-top: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-8px-computer {
      padding-top: 8px !important; } }
  @media (min-width: 1200px) {
    .padding-top-8px-largeScreen {
      padding-top: 8px !important; } }
  @media print {
    .padding-top-8px-print {
      padding-top: 8px !important; } }

.padding-bottom-8px {
  padding-bottom: 8px !important; }
  @media (max-width: 767px) {
    .padding-bottom-8px-mobile {
      padding-bottom: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-8px-tablet {
      padding-bottom: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-8px-computer {
      padding-bottom: 8px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-8px-largeScreen {
      padding-bottom: 8px !important; } }
  @media print {
    .padding-bottom-8px-print {
      padding-bottom: 8px !important; } }

.padding-left-8px {
  padding-left: 8px !important; }
  @media (max-width: 767px) {
    .padding-left-8px-mobile {
      padding-left: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-8px-tablet {
      padding-left: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-8px-computer {
      padding-left: 8px !important; } }
  @media (min-width: 1200px) {
    .padding-left-8px-largeScreen {
      padding-left: 8px !important; } }
  @media print {
    .padding-left-8px-print {
      padding-left: 8px !important; } }

.padding-right-8px {
  padding-right: 8px !important; }
  @media (max-width: 767px) {
    .padding-right-8px-mobile {
      padding-right: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-8px-tablet {
      padding-right: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-8px-computer {
      padding-right: 8px !important; } }
  @media (min-width: 1200px) {
    .padding-right-8px-largeScreen {
      padding-right: 8px !important; } }
  @media print {
    .padding-right-8px-print {
      padding-right: 8px !important; } }

.padding-tb-8px {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }
  @media (max-width: 767px) {
    .padding-tb-8px-mobile {
      padding-top: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-8px-tablet {
      padding-top: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-8px-computer {
      padding-top: 8px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-8px-largeScreen {
      padding-top: 8px !important; } }
  @media print {
    .padding-tb-8px-print {
      padding-top: 8px !important; } }
  @media (max-width: 767px) {
    .padding-tb-8px-mobile {
      padding-bottom: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-8px-tablet {
      padding-bottom: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-8px-computer {
      padding-bottom: 8px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-8px-largeScreen {
      padding-bottom: 8px !important; } }
  @media print {
    .padding-tb-8px-print {
      padding-bottom: 8px !important; } }

.padding-lr-8px {
  padding-left: 8px !important;
  padding-right: 8px !important; }
  @media (max-width: 767px) {
    .padding-lr-8px-mobile {
      padding-left: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-8px-tablet {
      padding-left: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-8px-computer {
      padding-left: 8px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-8px-largeScreen {
      padding-left: 8px !important; } }
  @media print {
    .padding-lr-8px-print {
      padding-left: 8px !important; } }
  @media (max-width: 767px) {
    .padding-lr-8px-mobile {
      padding-right: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-8px-tablet {
      padding-right: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-8px-computer {
      padding-right: 8px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-8px-largeScreen {
      padding-right: 8px !important; } }
  @media print {
    .padding-lr-8px-print {
      padding-right: 8px !important; } }

.margin-8px {
  margin: 8px !important; }
  @media (max-width: 767px) {
    .margin-8px-mobile {
      margin: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-8px-tablet {
      margin: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-8px-computer {
      margin: 8px !important; } }
  @media (min-width: 1200px) {
    .margin-8px-largeScreen {
      margin: 8px !important; } }
  @media print {
    .margin-8px-print {
      margin: 8px !important; } }

.margin-top-8px {
  margin-top: 8px !important; }
  @media (max-width: 767px) {
    .margin-top-8px-mobile {
      margin-top: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-8px-tablet {
      margin-top: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-8px-computer {
      margin-top: 8px !important; } }
  @media (min-width: 1200px) {
    .margin-top-8px-largeScreen {
      margin-top: 8px !important; } }
  @media print {
    .margin-top-8px-print {
      margin-top: 8px !important; } }

.margin-bottom-8px {
  margin-bottom: 8px !important; }
  @media (max-width: 767px) {
    .margin-bottom-8px-mobile {
      margin-bottom: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-8px-tablet {
      margin-bottom: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-8px-computer {
      margin-bottom: 8px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-8px-largeScreen {
      margin-bottom: 8px !important; } }
  @media print {
    .margin-bottom-8px-print {
      margin-bottom: 8px !important; } }

.margin-left-8px {
  margin-left: 8px !important; }
  @media (max-width: 767px) {
    .margin-left-8px-mobile {
      margin-left: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-8px-tablet {
      margin-left: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-8px-computer {
      margin-left: 8px !important; } }
  @media (min-width: 1200px) {
    .margin-left-8px-largeScreen {
      margin-left: 8px !important; } }
  @media print {
    .margin-left-8px-print {
      margin-left: 8px !important; } }

.margin-right-8px {
  margin-right: 8px !important; }
  @media (max-width: 767px) {
    .margin-right-8px-mobile {
      margin-right: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-8px-tablet {
      margin-right: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-8px-computer {
      margin-right: 8px !important; } }
  @media (min-width: 1200px) {
    .margin-right-8px-largeScreen {
      margin-right: 8px !important; } }
  @media print {
    .margin-right-8px-print {
      margin-right: 8px !important; } }

.margin-tb-8px {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }
  @media (max-width: 767px) {
    .margin-tb-8px-mobile {
      margin-top: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-8px-tablet {
      margin-top: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-8px-computer {
      margin-top: 8px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-8px-largeScreen {
      margin-top: 8px !important; } }
  @media print {
    .margin-tb-8px-print {
      margin-top: 8px !important; } }
  @media (max-width: 767px) {
    .margin-tb-8px-mobile {
      margin-bottom: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-8px-tablet {
      margin-bottom: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-8px-computer {
      margin-bottom: 8px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-8px-largeScreen {
      margin-bottom: 8px !important; } }
  @media print {
    .margin-tb-8px-print {
      margin-bottom: 8px !important; } }

.margin-lr-8px {
  margin-left: 8px !important;
  margin-right: 8px !important; }
  @media (max-width: 767px) {
    .margin-lr-8px-mobile {
      margin-left: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-8px-tablet {
      margin-left: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-8px-computer {
      margin-left: 8px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-8px-largeScreen {
      margin-left: 8px !important; } }
  @media print {
    .margin-lr-8px-print {
      margin-left: 8px !important; } }
  @media (max-width: 767px) {
    .margin-lr-8px-mobile {
      margin-right: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-8px-tablet {
      margin-right: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-8px-computer {
      margin-right: 8px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-8px-largeScreen {
      margin-right: 8px !important; } }
  @media print {
    .margin-lr-8px-print {
      margin-right: 8px !important; } }

/* Negative Margin*/
.neg-margin-8px {
  margin: 8px !important; }
  @media (max-width: 767px) {
    .neg-margin-8px-mobile {
      margin: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-8px-tablet {
      margin: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-8px-computer {
      margin: 8px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-8px-largeScreen {
      margin: 8px !important; } }
  @media print {
    .neg-margin-8px-print {
      margin: 8px !important; } }

.neg-margin-top-8px {
  margin-top: 8px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-8px-mobile {
      margin-top: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-8px-tablet {
      margin-top: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-8px-computer {
      margin-top: 8px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-8px-largeScreen {
      margin-top: 8px !important; } }
  @media print {
    .neg-margin-top-8px-print {
      margin-top: 8px !important; } }

.neg-margin-bottom-8px {
  margin-bottom: 8px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-8px-mobile {
      margin-bottom: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-8px-tablet {
      margin-bottom: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-8px-computer {
      margin-bottom: 8px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-8px-largeScreen {
      margin-bottom: 8px !important; } }
  @media print {
    .neg-margin-bottom-8px-print {
      margin-bottom: 8px !important; } }

.neg-margin-left-8px {
  margin-left: 8px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-8px-mobile {
      margin-left: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-8px-tablet {
      margin-left: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-8px-computer {
      margin-left: 8px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-8px-largeScreen {
      margin-left: 8px !important; } }
  @media print {
    .neg-margin-left-8px-print {
      margin-left: 8px !important; } }

.neg-margin-right-8px {
  margin-right: 8px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-8px-mobile {
      margin-right: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-8px-tablet {
      margin-right: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-8px-computer {
      margin-right: 8px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-8px-largeScreen {
      margin-right: 8px !important; } }
  @media print {
    .neg-margin-right-8px-print {
      margin-right: 8px !important; } }

.neg-margin-tb-8px {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-8px-mobile {
      margin-top: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-8px-tablet {
      margin-top: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-8px-computer {
      margin-top: 8px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-8px-largeScreen {
      margin-top: 8px !important; } }
  @media print {
    .neg-margin-tb-8px-print {
      margin-top: 8px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-8px-mobile {
      margin-bottom: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-8px-tablet {
      margin-bottom: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-8px-computer {
      margin-bottom: 8px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-8px-largeScreen {
      margin-bottom: 8px !important; } }
  @media print {
    .neg-margin-tb-8px-print {
      margin-bottom: 8px !important; } }

.neg-margin-lr-8px {
  margin-left: 8px !important;
  margin-right: 8px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-8px-mobile {
      margin-left: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-8px-tablet {
      margin-left: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-8px-computer {
      margin-left: 8px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-8px-largeScreen {
      margin-left: 8px !important; } }
  @media print {
    .neg-margin-lr-8px-print {
      margin-left: 8px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-8px-mobile {
      margin-right: 8px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-8px-tablet {
      margin-right: 8px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-8px-computer {
      margin-right: 8px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-8px-largeScreen {
      margin-right: 8px !important; } }
  @media print {
    .neg-margin-lr-8px-print {
      margin-right: 8px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-16px {
  padding: 16px !important; }
  @media (max-width: 767px) {
    .padding-16px-mobile {
      padding: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-16px-tablet {
      padding: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-16px-computer {
      padding: 16px !important; } }
  @media (min-width: 1200px) {
    .padding-16px-largeScreen {
      padding: 16px !important; } }
  @media print {
    .padding-16px-print {
      padding: 16px !important; } }

.padding-top-16px {
  padding-top: 16px !important; }
  @media (max-width: 767px) {
    .padding-top-16px-mobile {
      padding-top: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-16px-tablet {
      padding-top: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-16px-computer {
      padding-top: 16px !important; } }
  @media (min-width: 1200px) {
    .padding-top-16px-largeScreen {
      padding-top: 16px !important; } }
  @media print {
    .padding-top-16px-print {
      padding-top: 16px !important; } }

.padding-bottom-16px {
  padding-bottom: 16px !important; }
  @media (max-width: 767px) {
    .padding-bottom-16px-mobile {
      padding-bottom: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-16px-tablet {
      padding-bottom: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-16px-computer {
      padding-bottom: 16px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-16px-largeScreen {
      padding-bottom: 16px !important; } }
  @media print {
    .padding-bottom-16px-print {
      padding-bottom: 16px !important; } }

.padding-left-16px {
  padding-left: 16px !important; }
  @media (max-width: 767px) {
    .padding-left-16px-mobile {
      padding-left: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-16px-tablet {
      padding-left: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-16px-computer {
      padding-left: 16px !important; } }
  @media (min-width: 1200px) {
    .padding-left-16px-largeScreen {
      padding-left: 16px !important; } }
  @media print {
    .padding-left-16px-print {
      padding-left: 16px !important; } }

.padding-right-16px {
  padding-right: 16px !important; }
  @media (max-width: 767px) {
    .padding-right-16px-mobile {
      padding-right: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-16px-tablet {
      padding-right: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-16px-computer {
      padding-right: 16px !important; } }
  @media (min-width: 1200px) {
    .padding-right-16px-largeScreen {
      padding-right: 16px !important; } }
  @media print {
    .padding-right-16px-print {
      padding-right: 16px !important; } }

.padding-tb-16px {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }
  @media (max-width: 767px) {
    .padding-tb-16px-mobile {
      padding-top: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-16px-tablet {
      padding-top: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-16px-computer {
      padding-top: 16px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-16px-largeScreen {
      padding-top: 16px !important; } }
  @media print {
    .padding-tb-16px-print {
      padding-top: 16px !important; } }
  @media (max-width: 767px) {
    .padding-tb-16px-mobile {
      padding-bottom: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-16px-tablet {
      padding-bottom: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-16px-computer {
      padding-bottom: 16px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-16px-largeScreen {
      padding-bottom: 16px !important; } }
  @media print {
    .padding-tb-16px-print {
      padding-bottom: 16px !important; } }

.padding-lr-16px {
  padding-left: 16px !important;
  padding-right: 16px !important; }
  @media (max-width: 767px) {
    .padding-lr-16px-mobile {
      padding-left: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-16px-tablet {
      padding-left: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-16px-computer {
      padding-left: 16px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-16px-largeScreen {
      padding-left: 16px !important; } }
  @media print {
    .padding-lr-16px-print {
      padding-left: 16px !important; } }
  @media (max-width: 767px) {
    .padding-lr-16px-mobile {
      padding-right: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-16px-tablet {
      padding-right: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-16px-computer {
      padding-right: 16px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-16px-largeScreen {
      padding-right: 16px !important; } }
  @media print {
    .padding-lr-16px-print {
      padding-right: 16px !important; } }

.margin-16px {
  margin: 16px !important; }
  @media (max-width: 767px) {
    .margin-16px-mobile {
      margin: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-16px-tablet {
      margin: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-16px-computer {
      margin: 16px !important; } }
  @media (min-width: 1200px) {
    .margin-16px-largeScreen {
      margin: 16px !important; } }
  @media print {
    .margin-16px-print {
      margin: 16px !important; } }

.margin-top-16px {
  margin-top: 16px !important; }
  @media (max-width: 767px) {
    .margin-top-16px-mobile {
      margin-top: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-16px-tablet {
      margin-top: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-16px-computer {
      margin-top: 16px !important; } }
  @media (min-width: 1200px) {
    .margin-top-16px-largeScreen {
      margin-top: 16px !important; } }
  @media print {
    .margin-top-16px-print {
      margin-top: 16px !important; } }

.margin-bottom-16px {
  margin-bottom: 16px !important; }
  @media (max-width: 767px) {
    .margin-bottom-16px-mobile {
      margin-bottom: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-16px-tablet {
      margin-bottom: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-16px-computer {
      margin-bottom: 16px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-16px-largeScreen {
      margin-bottom: 16px !important; } }
  @media print {
    .margin-bottom-16px-print {
      margin-bottom: 16px !important; } }

.margin-left-16px {
  margin-left: 16px !important; }
  @media (max-width: 767px) {
    .margin-left-16px-mobile {
      margin-left: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-16px-tablet {
      margin-left: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-16px-computer {
      margin-left: 16px !important; } }
  @media (min-width: 1200px) {
    .margin-left-16px-largeScreen {
      margin-left: 16px !important; } }
  @media print {
    .margin-left-16px-print {
      margin-left: 16px !important; } }

.margin-right-16px {
  margin-right: 16px !important; }
  @media (max-width: 767px) {
    .margin-right-16px-mobile {
      margin-right: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-16px-tablet {
      margin-right: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-16px-computer {
      margin-right: 16px !important; } }
  @media (min-width: 1200px) {
    .margin-right-16px-largeScreen {
      margin-right: 16px !important; } }
  @media print {
    .margin-right-16px-print {
      margin-right: 16px !important; } }

.margin-tb-16px {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }
  @media (max-width: 767px) {
    .margin-tb-16px-mobile {
      margin-top: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-16px-tablet {
      margin-top: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-16px-computer {
      margin-top: 16px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-16px-largeScreen {
      margin-top: 16px !important; } }
  @media print {
    .margin-tb-16px-print {
      margin-top: 16px !important; } }
  @media (max-width: 767px) {
    .margin-tb-16px-mobile {
      margin-bottom: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-16px-tablet {
      margin-bottom: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-16px-computer {
      margin-bottom: 16px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-16px-largeScreen {
      margin-bottom: 16px !important; } }
  @media print {
    .margin-tb-16px-print {
      margin-bottom: 16px !important; } }

.margin-lr-16px {
  margin-left: 16px !important;
  margin-right: 16px !important; }
  @media (max-width: 767px) {
    .margin-lr-16px-mobile {
      margin-left: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-16px-tablet {
      margin-left: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-16px-computer {
      margin-left: 16px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-16px-largeScreen {
      margin-left: 16px !important; } }
  @media print {
    .margin-lr-16px-print {
      margin-left: 16px !important; } }
  @media (max-width: 767px) {
    .margin-lr-16px-mobile {
      margin-right: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-16px-tablet {
      margin-right: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-16px-computer {
      margin-right: 16px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-16px-largeScreen {
      margin-right: 16px !important; } }
  @media print {
    .margin-lr-16px-print {
      margin-right: 16px !important; } }

/* Negative Margin*/
.neg-margin-16px {
  margin: 16px !important; }
  @media (max-width: 767px) {
    .neg-margin-16px-mobile {
      margin: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-16px-tablet {
      margin: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-16px-computer {
      margin: 16px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-16px-largeScreen {
      margin: 16px !important; } }
  @media print {
    .neg-margin-16px-print {
      margin: 16px !important; } }

.neg-margin-top-16px {
  margin-top: 16px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-16px-mobile {
      margin-top: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-16px-tablet {
      margin-top: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-16px-computer {
      margin-top: 16px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-16px-largeScreen {
      margin-top: 16px !important; } }
  @media print {
    .neg-margin-top-16px-print {
      margin-top: 16px !important; } }

.neg-margin-bottom-16px {
  margin-bottom: 16px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-16px-mobile {
      margin-bottom: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-16px-tablet {
      margin-bottom: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-16px-computer {
      margin-bottom: 16px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-16px-largeScreen {
      margin-bottom: 16px !important; } }
  @media print {
    .neg-margin-bottom-16px-print {
      margin-bottom: 16px !important; } }

.neg-margin-left-16px {
  margin-left: 16px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-16px-mobile {
      margin-left: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-16px-tablet {
      margin-left: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-16px-computer {
      margin-left: 16px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-16px-largeScreen {
      margin-left: 16px !important; } }
  @media print {
    .neg-margin-left-16px-print {
      margin-left: 16px !important; } }

.neg-margin-right-16px {
  margin-right: 16px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-16px-mobile {
      margin-right: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-16px-tablet {
      margin-right: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-16px-computer {
      margin-right: 16px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-16px-largeScreen {
      margin-right: 16px !important; } }
  @media print {
    .neg-margin-right-16px-print {
      margin-right: 16px !important; } }

.neg-margin-tb-16px {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-16px-mobile {
      margin-top: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-16px-tablet {
      margin-top: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-16px-computer {
      margin-top: 16px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-16px-largeScreen {
      margin-top: 16px !important; } }
  @media print {
    .neg-margin-tb-16px-print {
      margin-top: 16px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-16px-mobile {
      margin-bottom: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-16px-tablet {
      margin-bottom: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-16px-computer {
      margin-bottom: 16px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-16px-largeScreen {
      margin-bottom: 16px !important; } }
  @media print {
    .neg-margin-tb-16px-print {
      margin-bottom: 16px !important; } }

.neg-margin-lr-16px {
  margin-left: 16px !important;
  margin-right: 16px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-16px-mobile {
      margin-left: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-16px-tablet {
      margin-left: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-16px-computer {
      margin-left: 16px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-16px-largeScreen {
      margin-left: 16px !important; } }
  @media print {
    .neg-margin-lr-16px-print {
      margin-left: 16px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-16px-mobile {
      margin-right: 16px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-16px-tablet {
      margin-right: 16px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-16px-computer {
      margin-right: 16px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-16px-largeScreen {
      margin-right: 16px !important; } }
  @media print {
    .neg-margin-lr-16px-print {
      margin-right: 16px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-24px {
  padding: 24px !important; }
  @media (max-width: 767px) {
    .padding-24px-mobile {
      padding: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-24px-tablet {
      padding: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-24px-computer {
      padding: 24px !important; } }
  @media (min-width: 1200px) {
    .padding-24px-largeScreen {
      padding: 24px !important; } }
  @media print {
    .padding-24px-print {
      padding: 24px !important; } }

.padding-top-24px {
  padding-top: 24px !important; }
  @media (max-width: 767px) {
    .padding-top-24px-mobile {
      padding-top: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-24px-tablet {
      padding-top: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-24px-computer {
      padding-top: 24px !important; } }
  @media (min-width: 1200px) {
    .padding-top-24px-largeScreen {
      padding-top: 24px !important; } }
  @media print {
    .padding-top-24px-print {
      padding-top: 24px !important; } }

.padding-bottom-24px {
  padding-bottom: 24px !important; }
  @media (max-width: 767px) {
    .padding-bottom-24px-mobile {
      padding-bottom: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-24px-tablet {
      padding-bottom: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-24px-computer {
      padding-bottom: 24px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-24px-largeScreen {
      padding-bottom: 24px !important; } }
  @media print {
    .padding-bottom-24px-print {
      padding-bottom: 24px !important; } }

.padding-left-24px {
  padding-left: 24px !important; }
  @media (max-width: 767px) {
    .padding-left-24px-mobile {
      padding-left: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-24px-tablet {
      padding-left: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-24px-computer {
      padding-left: 24px !important; } }
  @media (min-width: 1200px) {
    .padding-left-24px-largeScreen {
      padding-left: 24px !important; } }
  @media print {
    .padding-left-24px-print {
      padding-left: 24px !important; } }

.padding-right-24px {
  padding-right: 24px !important; }
  @media (max-width: 767px) {
    .padding-right-24px-mobile {
      padding-right: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-24px-tablet {
      padding-right: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-24px-computer {
      padding-right: 24px !important; } }
  @media (min-width: 1200px) {
    .padding-right-24px-largeScreen {
      padding-right: 24px !important; } }
  @media print {
    .padding-right-24px-print {
      padding-right: 24px !important; } }

.padding-tb-24px {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }
  @media (max-width: 767px) {
    .padding-tb-24px-mobile {
      padding-top: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-24px-tablet {
      padding-top: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-24px-computer {
      padding-top: 24px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-24px-largeScreen {
      padding-top: 24px !important; } }
  @media print {
    .padding-tb-24px-print {
      padding-top: 24px !important; } }
  @media (max-width: 767px) {
    .padding-tb-24px-mobile {
      padding-bottom: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-24px-tablet {
      padding-bottom: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-24px-computer {
      padding-bottom: 24px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-24px-largeScreen {
      padding-bottom: 24px !important; } }
  @media print {
    .padding-tb-24px-print {
      padding-bottom: 24px !important; } }

.padding-lr-24px {
  padding-left: 24px !important;
  padding-right: 24px !important; }
  @media (max-width: 767px) {
    .padding-lr-24px-mobile {
      padding-left: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-24px-tablet {
      padding-left: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-24px-computer {
      padding-left: 24px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-24px-largeScreen {
      padding-left: 24px !important; } }
  @media print {
    .padding-lr-24px-print {
      padding-left: 24px !important; } }
  @media (max-width: 767px) {
    .padding-lr-24px-mobile {
      padding-right: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-24px-tablet {
      padding-right: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-24px-computer {
      padding-right: 24px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-24px-largeScreen {
      padding-right: 24px !important; } }
  @media print {
    .padding-lr-24px-print {
      padding-right: 24px !important; } }

.margin-24px {
  margin: 24px !important; }
  @media (max-width: 767px) {
    .margin-24px-mobile {
      margin: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-24px-tablet {
      margin: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-24px-computer {
      margin: 24px !important; } }
  @media (min-width: 1200px) {
    .margin-24px-largeScreen {
      margin: 24px !important; } }
  @media print {
    .margin-24px-print {
      margin: 24px !important; } }

.margin-top-24px {
  margin-top: 24px !important; }
  @media (max-width: 767px) {
    .margin-top-24px-mobile {
      margin-top: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-24px-tablet {
      margin-top: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-24px-computer {
      margin-top: 24px !important; } }
  @media (min-width: 1200px) {
    .margin-top-24px-largeScreen {
      margin-top: 24px !important; } }
  @media print {
    .margin-top-24px-print {
      margin-top: 24px !important; } }

.margin-bottom-24px {
  margin-bottom: 24px !important; }
  @media (max-width: 767px) {
    .margin-bottom-24px-mobile {
      margin-bottom: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-24px-tablet {
      margin-bottom: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-24px-computer {
      margin-bottom: 24px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-24px-largeScreen {
      margin-bottom: 24px !important; } }
  @media print {
    .margin-bottom-24px-print {
      margin-bottom: 24px !important; } }

.margin-left-24px {
  margin-left: 24px !important; }
  @media (max-width: 767px) {
    .margin-left-24px-mobile {
      margin-left: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-24px-tablet {
      margin-left: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-24px-computer {
      margin-left: 24px !important; } }
  @media (min-width: 1200px) {
    .margin-left-24px-largeScreen {
      margin-left: 24px !important; } }
  @media print {
    .margin-left-24px-print {
      margin-left: 24px !important; } }

.margin-right-24px {
  margin-right: 24px !important; }
  @media (max-width: 767px) {
    .margin-right-24px-mobile {
      margin-right: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-24px-tablet {
      margin-right: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-24px-computer {
      margin-right: 24px !important; } }
  @media (min-width: 1200px) {
    .margin-right-24px-largeScreen {
      margin-right: 24px !important; } }
  @media print {
    .margin-right-24px-print {
      margin-right: 24px !important; } }

.margin-tb-24px {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }
  @media (max-width: 767px) {
    .margin-tb-24px-mobile {
      margin-top: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-24px-tablet {
      margin-top: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-24px-computer {
      margin-top: 24px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-24px-largeScreen {
      margin-top: 24px !important; } }
  @media print {
    .margin-tb-24px-print {
      margin-top: 24px !important; } }
  @media (max-width: 767px) {
    .margin-tb-24px-mobile {
      margin-bottom: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-24px-tablet {
      margin-bottom: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-24px-computer {
      margin-bottom: 24px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-24px-largeScreen {
      margin-bottom: 24px !important; } }
  @media print {
    .margin-tb-24px-print {
      margin-bottom: 24px !important; } }

.margin-lr-24px {
  margin-left: 24px !important;
  margin-right: 24px !important; }
  @media (max-width: 767px) {
    .margin-lr-24px-mobile {
      margin-left: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-24px-tablet {
      margin-left: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-24px-computer {
      margin-left: 24px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-24px-largeScreen {
      margin-left: 24px !important; } }
  @media print {
    .margin-lr-24px-print {
      margin-left: 24px !important; } }
  @media (max-width: 767px) {
    .margin-lr-24px-mobile {
      margin-right: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-24px-tablet {
      margin-right: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-24px-computer {
      margin-right: 24px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-24px-largeScreen {
      margin-right: 24px !important; } }
  @media print {
    .margin-lr-24px-print {
      margin-right: 24px !important; } }

/* Negative Margin*/
.neg-margin-24px {
  margin: 24px !important; }
  @media (max-width: 767px) {
    .neg-margin-24px-mobile {
      margin: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-24px-tablet {
      margin: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-24px-computer {
      margin: 24px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-24px-largeScreen {
      margin: 24px !important; } }
  @media print {
    .neg-margin-24px-print {
      margin: 24px !important; } }

.neg-margin-top-24px {
  margin-top: 24px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-24px-mobile {
      margin-top: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-24px-tablet {
      margin-top: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-24px-computer {
      margin-top: 24px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-24px-largeScreen {
      margin-top: 24px !important; } }
  @media print {
    .neg-margin-top-24px-print {
      margin-top: 24px !important; } }

.neg-margin-bottom-24px {
  margin-bottom: 24px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-24px-mobile {
      margin-bottom: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-24px-tablet {
      margin-bottom: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-24px-computer {
      margin-bottom: 24px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-24px-largeScreen {
      margin-bottom: 24px !important; } }
  @media print {
    .neg-margin-bottom-24px-print {
      margin-bottom: 24px !important; } }

.neg-margin-left-24px {
  margin-left: 24px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-24px-mobile {
      margin-left: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-24px-tablet {
      margin-left: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-24px-computer {
      margin-left: 24px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-24px-largeScreen {
      margin-left: 24px !important; } }
  @media print {
    .neg-margin-left-24px-print {
      margin-left: 24px !important; } }

.neg-margin-right-24px {
  margin-right: 24px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-24px-mobile {
      margin-right: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-24px-tablet {
      margin-right: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-24px-computer {
      margin-right: 24px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-24px-largeScreen {
      margin-right: 24px !important; } }
  @media print {
    .neg-margin-right-24px-print {
      margin-right: 24px !important; } }

.neg-margin-tb-24px {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-24px-mobile {
      margin-top: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-24px-tablet {
      margin-top: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-24px-computer {
      margin-top: 24px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-24px-largeScreen {
      margin-top: 24px !important; } }
  @media print {
    .neg-margin-tb-24px-print {
      margin-top: 24px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-24px-mobile {
      margin-bottom: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-24px-tablet {
      margin-bottom: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-24px-computer {
      margin-bottom: 24px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-24px-largeScreen {
      margin-bottom: 24px !important; } }
  @media print {
    .neg-margin-tb-24px-print {
      margin-bottom: 24px !important; } }

.neg-margin-lr-24px {
  margin-left: 24px !important;
  margin-right: 24px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-24px-mobile {
      margin-left: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-24px-tablet {
      margin-left: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-24px-computer {
      margin-left: 24px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-24px-largeScreen {
      margin-left: 24px !important; } }
  @media print {
    .neg-margin-lr-24px-print {
      margin-left: 24px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-24px-mobile {
      margin-right: 24px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-24px-tablet {
      margin-right: 24px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-24px-computer {
      margin-right: 24px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-24px-largeScreen {
      margin-right: 24px !important; } }
  @media print {
    .neg-margin-lr-24px-print {
      margin-right: 24px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-32px {
  padding: 32px !important; }
  @media (max-width: 767px) {
    .padding-32px-mobile {
      padding: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-32px-tablet {
      padding: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-32px-computer {
      padding: 32px !important; } }
  @media (min-width: 1200px) {
    .padding-32px-largeScreen {
      padding: 32px !important; } }
  @media print {
    .padding-32px-print {
      padding: 32px !important; } }

.padding-top-32px {
  padding-top: 32px !important; }
  @media (max-width: 767px) {
    .padding-top-32px-mobile {
      padding-top: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-32px-tablet {
      padding-top: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-32px-computer {
      padding-top: 32px !important; } }
  @media (min-width: 1200px) {
    .padding-top-32px-largeScreen {
      padding-top: 32px !important; } }
  @media print {
    .padding-top-32px-print {
      padding-top: 32px !important; } }

.padding-bottom-32px {
  padding-bottom: 32px !important; }
  @media (max-width: 767px) {
    .padding-bottom-32px-mobile {
      padding-bottom: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-32px-tablet {
      padding-bottom: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-32px-computer {
      padding-bottom: 32px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-32px-largeScreen {
      padding-bottom: 32px !important; } }
  @media print {
    .padding-bottom-32px-print {
      padding-bottom: 32px !important; } }

.padding-left-32px {
  padding-left: 32px !important; }
  @media (max-width: 767px) {
    .padding-left-32px-mobile {
      padding-left: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-32px-tablet {
      padding-left: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-32px-computer {
      padding-left: 32px !important; } }
  @media (min-width: 1200px) {
    .padding-left-32px-largeScreen {
      padding-left: 32px !important; } }
  @media print {
    .padding-left-32px-print {
      padding-left: 32px !important; } }

.padding-right-32px {
  padding-right: 32px !important; }
  @media (max-width: 767px) {
    .padding-right-32px-mobile {
      padding-right: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-32px-tablet {
      padding-right: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-32px-computer {
      padding-right: 32px !important; } }
  @media (min-width: 1200px) {
    .padding-right-32px-largeScreen {
      padding-right: 32px !important; } }
  @media print {
    .padding-right-32px-print {
      padding-right: 32px !important; } }

.padding-tb-32px {
  padding-top: 32px !important;
  padding-bottom: 32px !important; }
  @media (max-width: 767px) {
    .padding-tb-32px-mobile {
      padding-top: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-32px-tablet {
      padding-top: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-32px-computer {
      padding-top: 32px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-32px-largeScreen {
      padding-top: 32px !important; } }
  @media print {
    .padding-tb-32px-print {
      padding-top: 32px !important; } }
  @media (max-width: 767px) {
    .padding-tb-32px-mobile {
      padding-bottom: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-32px-tablet {
      padding-bottom: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-32px-computer {
      padding-bottom: 32px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-32px-largeScreen {
      padding-bottom: 32px !important; } }
  @media print {
    .padding-tb-32px-print {
      padding-bottom: 32px !important; } }

.padding-lr-32px {
  padding-left: 32px !important;
  padding-right: 32px !important; }
  @media (max-width: 767px) {
    .padding-lr-32px-mobile {
      padding-left: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-32px-tablet {
      padding-left: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-32px-computer {
      padding-left: 32px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-32px-largeScreen {
      padding-left: 32px !important; } }
  @media print {
    .padding-lr-32px-print {
      padding-left: 32px !important; } }
  @media (max-width: 767px) {
    .padding-lr-32px-mobile {
      padding-right: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-32px-tablet {
      padding-right: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-32px-computer {
      padding-right: 32px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-32px-largeScreen {
      padding-right: 32px !important; } }
  @media print {
    .padding-lr-32px-print {
      padding-right: 32px !important; } }

.margin-32px {
  margin: 32px !important; }
  @media (max-width: 767px) {
    .margin-32px-mobile {
      margin: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-32px-tablet {
      margin: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-32px-computer {
      margin: 32px !important; } }
  @media (min-width: 1200px) {
    .margin-32px-largeScreen {
      margin: 32px !important; } }
  @media print {
    .margin-32px-print {
      margin: 32px !important; } }

.margin-top-32px {
  margin-top: 32px !important; }
  @media (max-width: 767px) {
    .margin-top-32px-mobile {
      margin-top: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-32px-tablet {
      margin-top: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-32px-computer {
      margin-top: 32px !important; } }
  @media (min-width: 1200px) {
    .margin-top-32px-largeScreen {
      margin-top: 32px !important; } }
  @media print {
    .margin-top-32px-print {
      margin-top: 32px !important; } }

.margin-bottom-32px {
  margin-bottom: 32px !important; }
  @media (max-width: 767px) {
    .margin-bottom-32px-mobile {
      margin-bottom: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-32px-tablet {
      margin-bottom: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-32px-computer {
      margin-bottom: 32px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-32px-largeScreen {
      margin-bottom: 32px !important; } }
  @media print {
    .margin-bottom-32px-print {
      margin-bottom: 32px !important; } }

.margin-left-32px {
  margin-left: 32px !important; }
  @media (max-width: 767px) {
    .margin-left-32px-mobile {
      margin-left: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-32px-tablet {
      margin-left: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-32px-computer {
      margin-left: 32px !important; } }
  @media (min-width: 1200px) {
    .margin-left-32px-largeScreen {
      margin-left: 32px !important; } }
  @media print {
    .margin-left-32px-print {
      margin-left: 32px !important; } }

.margin-right-32px {
  margin-right: 32px !important; }
  @media (max-width: 767px) {
    .margin-right-32px-mobile {
      margin-right: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-32px-tablet {
      margin-right: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-32px-computer {
      margin-right: 32px !important; } }
  @media (min-width: 1200px) {
    .margin-right-32px-largeScreen {
      margin-right: 32px !important; } }
  @media print {
    .margin-right-32px-print {
      margin-right: 32px !important; } }

.margin-tb-32px {
  margin-top: 32px !important;
  margin-bottom: 32px !important; }
  @media (max-width: 767px) {
    .margin-tb-32px-mobile {
      margin-top: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-32px-tablet {
      margin-top: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-32px-computer {
      margin-top: 32px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-32px-largeScreen {
      margin-top: 32px !important; } }
  @media print {
    .margin-tb-32px-print {
      margin-top: 32px !important; } }
  @media (max-width: 767px) {
    .margin-tb-32px-mobile {
      margin-bottom: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-32px-tablet {
      margin-bottom: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-32px-computer {
      margin-bottom: 32px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-32px-largeScreen {
      margin-bottom: 32px !important; } }
  @media print {
    .margin-tb-32px-print {
      margin-bottom: 32px !important; } }

.margin-lr-32px {
  margin-left: 32px !important;
  margin-right: 32px !important; }
  @media (max-width: 767px) {
    .margin-lr-32px-mobile {
      margin-left: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-32px-tablet {
      margin-left: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-32px-computer {
      margin-left: 32px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-32px-largeScreen {
      margin-left: 32px !important; } }
  @media print {
    .margin-lr-32px-print {
      margin-left: 32px !important; } }
  @media (max-width: 767px) {
    .margin-lr-32px-mobile {
      margin-right: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-32px-tablet {
      margin-right: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-32px-computer {
      margin-right: 32px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-32px-largeScreen {
      margin-right: 32px !important; } }
  @media print {
    .margin-lr-32px-print {
      margin-right: 32px !important; } }

/* Negative Margin*/
.neg-margin-32px {
  margin: 32px !important; }
  @media (max-width: 767px) {
    .neg-margin-32px-mobile {
      margin: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-32px-tablet {
      margin: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-32px-computer {
      margin: 32px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-32px-largeScreen {
      margin: 32px !important; } }
  @media print {
    .neg-margin-32px-print {
      margin: 32px !important; } }

.neg-margin-top-32px {
  margin-top: 32px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-32px-mobile {
      margin-top: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-32px-tablet {
      margin-top: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-32px-computer {
      margin-top: 32px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-32px-largeScreen {
      margin-top: 32px !important; } }
  @media print {
    .neg-margin-top-32px-print {
      margin-top: 32px !important; } }

.neg-margin-bottom-32px {
  margin-bottom: 32px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-32px-mobile {
      margin-bottom: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-32px-tablet {
      margin-bottom: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-32px-computer {
      margin-bottom: 32px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-32px-largeScreen {
      margin-bottom: 32px !important; } }
  @media print {
    .neg-margin-bottom-32px-print {
      margin-bottom: 32px !important; } }

.neg-margin-left-32px {
  margin-left: 32px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-32px-mobile {
      margin-left: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-32px-tablet {
      margin-left: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-32px-computer {
      margin-left: 32px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-32px-largeScreen {
      margin-left: 32px !important; } }
  @media print {
    .neg-margin-left-32px-print {
      margin-left: 32px !important; } }

.neg-margin-right-32px {
  margin-right: 32px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-32px-mobile {
      margin-right: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-32px-tablet {
      margin-right: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-32px-computer {
      margin-right: 32px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-32px-largeScreen {
      margin-right: 32px !important; } }
  @media print {
    .neg-margin-right-32px-print {
      margin-right: 32px !important; } }

.neg-margin-tb-32px {
  margin-top: 32px !important;
  margin-bottom: 32px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-32px-mobile {
      margin-top: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-32px-tablet {
      margin-top: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-32px-computer {
      margin-top: 32px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-32px-largeScreen {
      margin-top: 32px !important; } }
  @media print {
    .neg-margin-tb-32px-print {
      margin-top: 32px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-32px-mobile {
      margin-bottom: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-32px-tablet {
      margin-bottom: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-32px-computer {
      margin-bottom: 32px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-32px-largeScreen {
      margin-bottom: 32px !important; } }
  @media print {
    .neg-margin-tb-32px-print {
      margin-bottom: 32px !important; } }

.neg-margin-lr-32px {
  margin-left: 32px !important;
  margin-right: 32px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-32px-mobile {
      margin-left: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-32px-tablet {
      margin-left: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-32px-computer {
      margin-left: 32px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-32px-largeScreen {
      margin-left: 32px !important; } }
  @media print {
    .neg-margin-lr-32px-print {
      margin-left: 32px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-32px-mobile {
      margin-right: 32px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-32px-tablet {
      margin-right: 32px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-32px-computer {
      margin-right: 32px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-32px-largeScreen {
      margin-right: 32px !important; } }
  @media print {
    .neg-margin-lr-32px-print {
      margin-right: 32px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-40px {
  padding: 40px !important; }
  @media (max-width: 767px) {
    .padding-40px-mobile {
      padding: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-40px-tablet {
      padding: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-40px-computer {
      padding: 40px !important; } }
  @media (min-width: 1200px) {
    .padding-40px-largeScreen {
      padding: 40px !important; } }
  @media print {
    .padding-40px-print {
      padding: 40px !important; } }

.padding-top-40px {
  padding-top: 40px !important; }
  @media (max-width: 767px) {
    .padding-top-40px-mobile {
      padding-top: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-40px-tablet {
      padding-top: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-40px-computer {
      padding-top: 40px !important; } }
  @media (min-width: 1200px) {
    .padding-top-40px-largeScreen {
      padding-top: 40px !important; } }
  @media print {
    .padding-top-40px-print {
      padding-top: 40px !important; } }

.padding-bottom-40px {
  padding-bottom: 40px !important; }
  @media (max-width: 767px) {
    .padding-bottom-40px-mobile {
      padding-bottom: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-40px-tablet {
      padding-bottom: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-40px-computer {
      padding-bottom: 40px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-40px-largeScreen {
      padding-bottom: 40px !important; } }
  @media print {
    .padding-bottom-40px-print {
      padding-bottom: 40px !important; } }

.padding-left-40px {
  padding-left: 40px !important; }
  @media (max-width: 767px) {
    .padding-left-40px-mobile {
      padding-left: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-40px-tablet {
      padding-left: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-40px-computer {
      padding-left: 40px !important; } }
  @media (min-width: 1200px) {
    .padding-left-40px-largeScreen {
      padding-left: 40px !important; } }
  @media print {
    .padding-left-40px-print {
      padding-left: 40px !important; } }

.padding-right-40px {
  padding-right: 40px !important; }
  @media (max-width: 767px) {
    .padding-right-40px-mobile {
      padding-right: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-40px-tablet {
      padding-right: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-40px-computer {
      padding-right: 40px !important; } }
  @media (min-width: 1200px) {
    .padding-right-40px-largeScreen {
      padding-right: 40px !important; } }
  @media print {
    .padding-right-40px-print {
      padding-right: 40px !important; } }

.padding-tb-40px {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }
  @media (max-width: 767px) {
    .padding-tb-40px-mobile {
      padding-top: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-40px-tablet {
      padding-top: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-40px-computer {
      padding-top: 40px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-40px-largeScreen {
      padding-top: 40px !important; } }
  @media print {
    .padding-tb-40px-print {
      padding-top: 40px !important; } }
  @media (max-width: 767px) {
    .padding-tb-40px-mobile {
      padding-bottom: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-40px-tablet {
      padding-bottom: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-40px-computer {
      padding-bottom: 40px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-40px-largeScreen {
      padding-bottom: 40px !important; } }
  @media print {
    .padding-tb-40px-print {
      padding-bottom: 40px !important; } }

.padding-lr-40px {
  padding-left: 40px !important;
  padding-right: 40px !important; }
  @media (max-width: 767px) {
    .padding-lr-40px-mobile {
      padding-left: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-40px-tablet {
      padding-left: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-40px-computer {
      padding-left: 40px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-40px-largeScreen {
      padding-left: 40px !important; } }
  @media print {
    .padding-lr-40px-print {
      padding-left: 40px !important; } }
  @media (max-width: 767px) {
    .padding-lr-40px-mobile {
      padding-right: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-40px-tablet {
      padding-right: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-40px-computer {
      padding-right: 40px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-40px-largeScreen {
      padding-right: 40px !important; } }
  @media print {
    .padding-lr-40px-print {
      padding-right: 40px !important; } }

.margin-40px {
  margin: 40px !important; }
  @media (max-width: 767px) {
    .margin-40px-mobile {
      margin: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-40px-tablet {
      margin: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-40px-computer {
      margin: 40px !important; } }
  @media (min-width: 1200px) {
    .margin-40px-largeScreen {
      margin: 40px !important; } }
  @media print {
    .margin-40px-print {
      margin: 40px !important; } }

.margin-top-40px {
  margin-top: 40px !important; }
  @media (max-width: 767px) {
    .margin-top-40px-mobile {
      margin-top: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-40px-tablet {
      margin-top: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-40px-computer {
      margin-top: 40px !important; } }
  @media (min-width: 1200px) {
    .margin-top-40px-largeScreen {
      margin-top: 40px !important; } }
  @media print {
    .margin-top-40px-print {
      margin-top: 40px !important; } }

.margin-bottom-40px {
  margin-bottom: 40px !important; }
  @media (max-width: 767px) {
    .margin-bottom-40px-mobile {
      margin-bottom: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-40px-tablet {
      margin-bottom: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-40px-computer {
      margin-bottom: 40px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-40px-largeScreen {
      margin-bottom: 40px !important; } }
  @media print {
    .margin-bottom-40px-print {
      margin-bottom: 40px !important; } }

.margin-left-40px {
  margin-left: 40px !important; }
  @media (max-width: 767px) {
    .margin-left-40px-mobile {
      margin-left: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-40px-tablet {
      margin-left: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-40px-computer {
      margin-left: 40px !important; } }
  @media (min-width: 1200px) {
    .margin-left-40px-largeScreen {
      margin-left: 40px !important; } }
  @media print {
    .margin-left-40px-print {
      margin-left: 40px !important; } }

.margin-right-40px {
  margin-right: 40px !important; }
  @media (max-width: 767px) {
    .margin-right-40px-mobile {
      margin-right: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-40px-tablet {
      margin-right: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-40px-computer {
      margin-right: 40px !important; } }
  @media (min-width: 1200px) {
    .margin-right-40px-largeScreen {
      margin-right: 40px !important; } }
  @media print {
    .margin-right-40px-print {
      margin-right: 40px !important; } }

.margin-tb-40px {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }
  @media (max-width: 767px) {
    .margin-tb-40px-mobile {
      margin-top: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-40px-tablet {
      margin-top: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-40px-computer {
      margin-top: 40px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-40px-largeScreen {
      margin-top: 40px !important; } }
  @media print {
    .margin-tb-40px-print {
      margin-top: 40px !important; } }
  @media (max-width: 767px) {
    .margin-tb-40px-mobile {
      margin-bottom: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-40px-tablet {
      margin-bottom: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-40px-computer {
      margin-bottom: 40px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-40px-largeScreen {
      margin-bottom: 40px !important; } }
  @media print {
    .margin-tb-40px-print {
      margin-bottom: 40px !important; } }

.margin-lr-40px {
  margin-left: 40px !important;
  margin-right: 40px !important; }
  @media (max-width: 767px) {
    .margin-lr-40px-mobile {
      margin-left: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-40px-tablet {
      margin-left: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-40px-computer {
      margin-left: 40px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-40px-largeScreen {
      margin-left: 40px !important; } }
  @media print {
    .margin-lr-40px-print {
      margin-left: 40px !important; } }
  @media (max-width: 767px) {
    .margin-lr-40px-mobile {
      margin-right: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-40px-tablet {
      margin-right: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-40px-computer {
      margin-right: 40px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-40px-largeScreen {
      margin-right: 40px !important; } }
  @media print {
    .margin-lr-40px-print {
      margin-right: 40px !important; } }

/* Negative Margin*/
.neg-margin-40px {
  margin: 40px !important; }
  @media (max-width: 767px) {
    .neg-margin-40px-mobile {
      margin: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-40px-tablet {
      margin: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-40px-computer {
      margin: 40px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-40px-largeScreen {
      margin: 40px !important; } }
  @media print {
    .neg-margin-40px-print {
      margin: 40px !important; } }

.neg-margin-top-40px {
  margin-top: 40px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-40px-mobile {
      margin-top: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-40px-tablet {
      margin-top: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-40px-computer {
      margin-top: 40px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-40px-largeScreen {
      margin-top: 40px !important; } }
  @media print {
    .neg-margin-top-40px-print {
      margin-top: 40px !important; } }

.neg-margin-bottom-40px {
  margin-bottom: 40px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-40px-mobile {
      margin-bottom: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-40px-tablet {
      margin-bottom: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-40px-computer {
      margin-bottom: 40px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-40px-largeScreen {
      margin-bottom: 40px !important; } }
  @media print {
    .neg-margin-bottom-40px-print {
      margin-bottom: 40px !important; } }

.neg-margin-left-40px {
  margin-left: 40px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-40px-mobile {
      margin-left: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-40px-tablet {
      margin-left: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-40px-computer {
      margin-left: 40px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-40px-largeScreen {
      margin-left: 40px !important; } }
  @media print {
    .neg-margin-left-40px-print {
      margin-left: 40px !important; } }

.neg-margin-right-40px {
  margin-right: 40px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-40px-mobile {
      margin-right: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-40px-tablet {
      margin-right: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-40px-computer {
      margin-right: 40px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-40px-largeScreen {
      margin-right: 40px !important; } }
  @media print {
    .neg-margin-right-40px-print {
      margin-right: 40px !important; } }

.neg-margin-tb-40px {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-40px-mobile {
      margin-top: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-40px-tablet {
      margin-top: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-40px-computer {
      margin-top: 40px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-40px-largeScreen {
      margin-top: 40px !important; } }
  @media print {
    .neg-margin-tb-40px-print {
      margin-top: 40px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-40px-mobile {
      margin-bottom: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-40px-tablet {
      margin-bottom: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-40px-computer {
      margin-bottom: 40px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-40px-largeScreen {
      margin-bottom: 40px !important; } }
  @media print {
    .neg-margin-tb-40px-print {
      margin-bottom: 40px !important; } }

.neg-margin-lr-40px {
  margin-left: 40px !important;
  margin-right: 40px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-40px-mobile {
      margin-left: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-40px-tablet {
      margin-left: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-40px-computer {
      margin-left: 40px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-40px-largeScreen {
      margin-left: 40px !important; } }
  @media print {
    .neg-margin-lr-40px-print {
      margin-left: 40px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-40px-mobile {
      margin-right: 40px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-40px-tablet {
      margin-right: 40px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-40px-computer {
      margin-right: 40px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-40px-largeScreen {
      margin-right: 40px !important; } }
  @media print {
    .neg-margin-lr-40px-print {
      margin-right: 40px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-48px {
  padding: 48px !important; }
  @media (max-width: 767px) {
    .padding-48px-mobile {
      padding: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-48px-tablet {
      padding: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-48px-computer {
      padding: 48px !important; } }
  @media (min-width: 1200px) {
    .padding-48px-largeScreen {
      padding: 48px !important; } }
  @media print {
    .padding-48px-print {
      padding: 48px !important; } }

.padding-top-48px {
  padding-top: 48px !important; }
  @media (max-width: 767px) {
    .padding-top-48px-mobile {
      padding-top: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-48px-tablet {
      padding-top: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-48px-computer {
      padding-top: 48px !important; } }
  @media (min-width: 1200px) {
    .padding-top-48px-largeScreen {
      padding-top: 48px !important; } }
  @media print {
    .padding-top-48px-print {
      padding-top: 48px !important; } }

.padding-bottom-48px {
  padding-bottom: 48px !important; }
  @media (max-width: 767px) {
    .padding-bottom-48px-mobile {
      padding-bottom: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-48px-tablet {
      padding-bottom: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-48px-computer {
      padding-bottom: 48px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-48px-largeScreen {
      padding-bottom: 48px !important; } }
  @media print {
    .padding-bottom-48px-print {
      padding-bottom: 48px !important; } }

.padding-left-48px {
  padding-left: 48px !important; }
  @media (max-width: 767px) {
    .padding-left-48px-mobile {
      padding-left: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-48px-tablet {
      padding-left: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-48px-computer {
      padding-left: 48px !important; } }
  @media (min-width: 1200px) {
    .padding-left-48px-largeScreen {
      padding-left: 48px !important; } }
  @media print {
    .padding-left-48px-print {
      padding-left: 48px !important; } }

.padding-right-48px {
  padding-right: 48px !important; }
  @media (max-width: 767px) {
    .padding-right-48px-mobile {
      padding-right: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-48px-tablet {
      padding-right: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-48px-computer {
      padding-right: 48px !important; } }
  @media (min-width: 1200px) {
    .padding-right-48px-largeScreen {
      padding-right: 48px !important; } }
  @media print {
    .padding-right-48px-print {
      padding-right: 48px !important; } }

.padding-tb-48px {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }
  @media (max-width: 767px) {
    .padding-tb-48px-mobile {
      padding-top: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-48px-tablet {
      padding-top: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-48px-computer {
      padding-top: 48px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-48px-largeScreen {
      padding-top: 48px !important; } }
  @media print {
    .padding-tb-48px-print {
      padding-top: 48px !important; } }
  @media (max-width: 767px) {
    .padding-tb-48px-mobile {
      padding-bottom: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-48px-tablet {
      padding-bottom: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-48px-computer {
      padding-bottom: 48px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-48px-largeScreen {
      padding-bottom: 48px !important; } }
  @media print {
    .padding-tb-48px-print {
      padding-bottom: 48px !important; } }

.padding-lr-48px {
  padding-left: 48px !important;
  padding-right: 48px !important; }
  @media (max-width: 767px) {
    .padding-lr-48px-mobile {
      padding-left: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-48px-tablet {
      padding-left: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-48px-computer {
      padding-left: 48px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-48px-largeScreen {
      padding-left: 48px !important; } }
  @media print {
    .padding-lr-48px-print {
      padding-left: 48px !important; } }
  @media (max-width: 767px) {
    .padding-lr-48px-mobile {
      padding-right: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-48px-tablet {
      padding-right: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-48px-computer {
      padding-right: 48px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-48px-largeScreen {
      padding-right: 48px !important; } }
  @media print {
    .padding-lr-48px-print {
      padding-right: 48px !important; } }

.margin-48px {
  margin: 48px !important; }
  @media (max-width: 767px) {
    .margin-48px-mobile {
      margin: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-48px-tablet {
      margin: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-48px-computer {
      margin: 48px !important; } }
  @media (min-width: 1200px) {
    .margin-48px-largeScreen {
      margin: 48px !important; } }
  @media print {
    .margin-48px-print {
      margin: 48px !important; } }

.margin-top-48px {
  margin-top: 48px !important; }
  @media (max-width: 767px) {
    .margin-top-48px-mobile {
      margin-top: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-48px-tablet {
      margin-top: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-48px-computer {
      margin-top: 48px !important; } }
  @media (min-width: 1200px) {
    .margin-top-48px-largeScreen {
      margin-top: 48px !important; } }
  @media print {
    .margin-top-48px-print {
      margin-top: 48px !important; } }

.margin-bottom-48px {
  margin-bottom: 48px !important; }
  @media (max-width: 767px) {
    .margin-bottom-48px-mobile {
      margin-bottom: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-48px-tablet {
      margin-bottom: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-48px-computer {
      margin-bottom: 48px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-48px-largeScreen {
      margin-bottom: 48px !important; } }
  @media print {
    .margin-bottom-48px-print {
      margin-bottom: 48px !important; } }

.margin-left-48px {
  margin-left: 48px !important; }
  @media (max-width: 767px) {
    .margin-left-48px-mobile {
      margin-left: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-48px-tablet {
      margin-left: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-48px-computer {
      margin-left: 48px !important; } }
  @media (min-width: 1200px) {
    .margin-left-48px-largeScreen {
      margin-left: 48px !important; } }
  @media print {
    .margin-left-48px-print {
      margin-left: 48px !important; } }

.margin-right-48px {
  margin-right: 48px !important; }
  @media (max-width: 767px) {
    .margin-right-48px-mobile {
      margin-right: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-48px-tablet {
      margin-right: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-48px-computer {
      margin-right: 48px !important; } }
  @media (min-width: 1200px) {
    .margin-right-48px-largeScreen {
      margin-right: 48px !important; } }
  @media print {
    .margin-right-48px-print {
      margin-right: 48px !important; } }

.margin-tb-48px {
  margin-top: 48px !important;
  margin-bottom: 48px !important; }
  @media (max-width: 767px) {
    .margin-tb-48px-mobile {
      margin-top: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-48px-tablet {
      margin-top: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-48px-computer {
      margin-top: 48px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-48px-largeScreen {
      margin-top: 48px !important; } }
  @media print {
    .margin-tb-48px-print {
      margin-top: 48px !important; } }
  @media (max-width: 767px) {
    .margin-tb-48px-mobile {
      margin-bottom: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-48px-tablet {
      margin-bottom: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-48px-computer {
      margin-bottom: 48px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-48px-largeScreen {
      margin-bottom: 48px !important; } }
  @media print {
    .margin-tb-48px-print {
      margin-bottom: 48px !important; } }

.margin-lr-48px {
  margin-left: 48px !important;
  margin-right: 48px !important; }
  @media (max-width: 767px) {
    .margin-lr-48px-mobile {
      margin-left: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-48px-tablet {
      margin-left: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-48px-computer {
      margin-left: 48px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-48px-largeScreen {
      margin-left: 48px !important; } }
  @media print {
    .margin-lr-48px-print {
      margin-left: 48px !important; } }
  @media (max-width: 767px) {
    .margin-lr-48px-mobile {
      margin-right: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-48px-tablet {
      margin-right: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-48px-computer {
      margin-right: 48px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-48px-largeScreen {
      margin-right: 48px !important; } }
  @media print {
    .margin-lr-48px-print {
      margin-right: 48px !important; } }

/* Negative Margin*/
.neg-margin-48px {
  margin: 48px !important; }
  @media (max-width: 767px) {
    .neg-margin-48px-mobile {
      margin: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-48px-tablet {
      margin: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-48px-computer {
      margin: 48px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-48px-largeScreen {
      margin: 48px !important; } }
  @media print {
    .neg-margin-48px-print {
      margin: 48px !important; } }

.neg-margin-top-48px {
  margin-top: 48px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-48px-mobile {
      margin-top: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-48px-tablet {
      margin-top: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-48px-computer {
      margin-top: 48px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-48px-largeScreen {
      margin-top: 48px !important; } }
  @media print {
    .neg-margin-top-48px-print {
      margin-top: 48px !important; } }

.neg-margin-bottom-48px {
  margin-bottom: 48px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-48px-mobile {
      margin-bottom: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-48px-tablet {
      margin-bottom: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-48px-computer {
      margin-bottom: 48px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-48px-largeScreen {
      margin-bottom: 48px !important; } }
  @media print {
    .neg-margin-bottom-48px-print {
      margin-bottom: 48px !important; } }

.neg-margin-left-48px {
  margin-left: 48px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-48px-mobile {
      margin-left: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-48px-tablet {
      margin-left: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-48px-computer {
      margin-left: 48px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-48px-largeScreen {
      margin-left: 48px !important; } }
  @media print {
    .neg-margin-left-48px-print {
      margin-left: 48px !important; } }

.neg-margin-right-48px {
  margin-right: 48px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-48px-mobile {
      margin-right: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-48px-tablet {
      margin-right: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-48px-computer {
      margin-right: 48px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-48px-largeScreen {
      margin-right: 48px !important; } }
  @media print {
    .neg-margin-right-48px-print {
      margin-right: 48px !important; } }

.neg-margin-tb-48px {
  margin-top: 48px !important;
  margin-bottom: 48px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-48px-mobile {
      margin-top: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-48px-tablet {
      margin-top: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-48px-computer {
      margin-top: 48px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-48px-largeScreen {
      margin-top: 48px !important; } }
  @media print {
    .neg-margin-tb-48px-print {
      margin-top: 48px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-48px-mobile {
      margin-bottom: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-48px-tablet {
      margin-bottom: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-48px-computer {
      margin-bottom: 48px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-48px-largeScreen {
      margin-bottom: 48px !important; } }
  @media print {
    .neg-margin-tb-48px-print {
      margin-bottom: 48px !important; } }

.neg-margin-lr-48px {
  margin-left: 48px !important;
  margin-right: 48px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-48px-mobile {
      margin-left: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-48px-tablet {
      margin-left: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-48px-computer {
      margin-left: 48px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-48px-largeScreen {
      margin-left: 48px !important; } }
  @media print {
    .neg-margin-lr-48px-print {
      margin-left: 48px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-48px-mobile {
      margin-right: 48px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-48px-tablet {
      margin-right: 48px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-48px-computer {
      margin-right: 48px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-48px-largeScreen {
      margin-right: 48px !important; } }
  @media print {
    .neg-margin-lr-48px-print {
      margin-right: 48px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-56px {
  padding: 56px !important; }
  @media (max-width: 767px) {
    .padding-56px-mobile {
      padding: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-56px-tablet {
      padding: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-56px-computer {
      padding: 56px !important; } }
  @media (min-width: 1200px) {
    .padding-56px-largeScreen {
      padding: 56px !important; } }
  @media print {
    .padding-56px-print {
      padding: 56px !important; } }

.padding-top-56px {
  padding-top: 56px !important; }
  @media (max-width: 767px) {
    .padding-top-56px-mobile {
      padding-top: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-56px-tablet {
      padding-top: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-56px-computer {
      padding-top: 56px !important; } }
  @media (min-width: 1200px) {
    .padding-top-56px-largeScreen {
      padding-top: 56px !important; } }
  @media print {
    .padding-top-56px-print {
      padding-top: 56px !important; } }

.padding-bottom-56px {
  padding-bottom: 56px !important; }
  @media (max-width: 767px) {
    .padding-bottom-56px-mobile {
      padding-bottom: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-56px-tablet {
      padding-bottom: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-56px-computer {
      padding-bottom: 56px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-56px-largeScreen {
      padding-bottom: 56px !important; } }
  @media print {
    .padding-bottom-56px-print {
      padding-bottom: 56px !important; } }

.padding-left-56px {
  padding-left: 56px !important; }
  @media (max-width: 767px) {
    .padding-left-56px-mobile {
      padding-left: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-56px-tablet {
      padding-left: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-56px-computer {
      padding-left: 56px !important; } }
  @media (min-width: 1200px) {
    .padding-left-56px-largeScreen {
      padding-left: 56px !important; } }
  @media print {
    .padding-left-56px-print {
      padding-left: 56px !important; } }

.padding-right-56px {
  padding-right: 56px !important; }
  @media (max-width: 767px) {
    .padding-right-56px-mobile {
      padding-right: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-56px-tablet {
      padding-right: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-56px-computer {
      padding-right: 56px !important; } }
  @media (min-width: 1200px) {
    .padding-right-56px-largeScreen {
      padding-right: 56px !important; } }
  @media print {
    .padding-right-56px-print {
      padding-right: 56px !important; } }

.padding-tb-56px {
  padding-top: 56px !important;
  padding-bottom: 56px !important; }
  @media (max-width: 767px) {
    .padding-tb-56px-mobile {
      padding-top: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-56px-tablet {
      padding-top: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-56px-computer {
      padding-top: 56px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-56px-largeScreen {
      padding-top: 56px !important; } }
  @media print {
    .padding-tb-56px-print {
      padding-top: 56px !important; } }
  @media (max-width: 767px) {
    .padding-tb-56px-mobile {
      padding-bottom: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-56px-tablet {
      padding-bottom: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-56px-computer {
      padding-bottom: 56px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-56px-largeScreen {
      padding-bottom: 56px !important; } }
  @media print {
    .padding-tb-56px-print {
      padding-bottom: 56px !important; } }

.padding-lr-56px {
  padding-left: 56px !important;
  padding-right: 56px !important; }
  @media (max-width: 767px) {
    .padding-lr-56px-mobile {
      padding-left: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-56px-tablet {
      padding-left: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-56px-computer {
      padding-left: 56px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-56px-largeScreen {
      padding-left: 56px !important; } }
  @media print {
    .padding-lr-56px-print {
      padding-left: 56px !important; } }
  @media (max-width: 767px) {
    .padding-lr-56px-mobile {
      padding-right: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-56px-tablet {
      padding-right: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-56px-computer {
      padding-right: 56px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-56px-largeScreen {
      padding-right: 56px !important; } }
  @media print {
    .padding-lr-56px-print {
      padding-right: 56px !important; } }

.margin-56px {
  margin: 56px !important; }
  @media (max-width: 767px) {
    .margin-56px-mobile {
      margin: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-56px-tablet {
      margin: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-56px-computer {
      margin: 56px !important; } }
  @media (min-width: 1200px) {
    .margin-56px-largeScreen {
      margin: 56px !important; } }
  @media print {
    .margin-56px-print {
      margin: 56px !important; } }

.margin-top-56px {
  margin-top: 56px !important; }
  @media (max-width: 767px) {
    .margin-top-56px-mobile {
      margin-top: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-56px-tablet {
      margin-top: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-56px-computer {
      margin-top: 56px !important; } }
  @media (min-width: 1200px) {
    .margin-top-56px-largeScreen {
      margin-top: 56px !important; } }
  @media print {
    .margin-top-56px-print {
      margin-top: 56px !important; } }

.margin-bottom-56px {
  margin-bottom: 56px !important; }
  @media (max-width: 767px) {
    .margin-bottom-56px-mobile {
      margin-bottom: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-56px-tablet {
      margin-bottom: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-56px-computer {
      margin-bottom: 56px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-56px-largeScreen {
      margin-bottom: 56px !important; } }
  @media print {
    .margin-bottom-56px-print {
      margin-bottom: 56px !important; } }

.margin-left-56px {
  margin-left: 56px !important; }
  @media (max-width: 767px) {
    .margin-left-56px-mobile {
      margin-left: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-56px-tablet {
      margin-left: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-56px-computer {
      margin-left: 56px !important; } }
  @media (min-width: 1200px) {
    .margin-left-56px-largeScreen {
      margin-left: 56px !important; } }
  @media print {
    .margin-left-56px-print {
      margin-left: 56px !important; } }

.margin-right-56px {
  margin-right: 56px !important; }
  @media (max-width: 767px) {
    .margin-right-56px-mobile {
      margin-right: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-56px-tablet {
      margin-right: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-56px-computer {
      margin-right: 56px !important; } }
  @media (min-width: 1200px) {
    .margin-right-56px-largeScreen {
      margin-right: 56px !important; } }
  @media print {
    .margin-right-56px-print {
      margin-right: 56px !important; } }

.margin-tb-56px {
  margin-top: 56px !important;
  margin-bottom: 56px !important; }
  @media (max-width: 767px) {
    .margin-tb-56px-mobile {
      margin-top: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-56px-tablet {
      margin-top: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-56px-computer {
      margin-top: 56px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-56px-largeScreen {
      margin-top: 56px !important; } }
  @media print {
    .margin-tb-56px-print {
      margin-top: 56px !important; } }
  @media (max-width: 767px) {
    .margin-tb-56px-mobile {
      margin-bottom: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-56px-tablet {
      margin-bottom: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-56px-computer {
      margin-bottom: 56px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-56px-largeScreen {
      margin-bottom: 56px !important; } }
  @media print {
    .margin-tb-56px-print {
      margin-bottom: 56px !important; } }

.margin-lr-56px {
  margin-left: 56px !important;
  margin-right: 56px !important; }
  @media (max-width: 767px) {
    .margin-lr-56px-mobile {
      margin-left: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-56px-tablet {
      margin-left: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-56px-computer {
      margin-left: 56px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-56px-largeScreen {
      margin-left: 56px !important; } }
  @media print {
    .margin-lr-56px-print {
      margin-left: 56px !important; } }
  @media (max-width: 767px) {
    .margin-lr-56px-mobile {
      margin-right: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-56px-tablet {
      margin-right: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-56px-computer {
      margin-right: 56px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-56px-largeScreen {
      margin-right: 56px !important; } }
  @media print {
    .margin-lr-56px-print {
      margin-right: 56px !important; } }

/* Negative Margin*/
.neg-margin-56px {
  margin: 56px !important; }
  @media (max-width: 767px) {
    .neg-margin-56px-mobile {
      margin: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-56px-tablet {
      margin: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-56px-computer {
      margin: 56px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-56px-largeScreen {
      margin: 56px !important; } }
  @media print {
    .neg-margin-56px-print {
      margin: 56px !important; } }

.neg-margin-top-56px {
  margin-top: 56px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-56px-mobile {
      margin-top: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-56px-tablet {
      margin-top: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-56px-computer {
      margin-top: 56px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-56px-largeScreen {
      margin-top: 56px !important; } }
  @media print {
    .neg-margin-top-56px-print {
      margin-top: 56px !important; } }

.neg-margin-bottom-56px {
  margin-bottom: 56px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-56px-mobile {
      margin-bottom: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-56px-tablet {
      margin-bottom: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-56px-computer {
      margin-bottom: 56px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-56px-largeScreen {
      margin-bottom: 56px !important; } }
  @media print {
    .neg-margin-bottom-56px-print {
      margin-bottom: 56px !important; } }

.neg-margin-left-56px {
  margin-left: 56px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-56px-mobile {
      margin-left: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-56px-tablet {
      margin-left: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-56px-computer {
      margin-left: 56px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-56px-largeScreen {
      margin-left: 56px !important; } }
  @media print {
    .neg-margin-left-56px-print {
      margin-left: 56px !important; } }

.neg-margin-right-56px {
  margin-right: 56px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-56px-mobile {
      margin-right: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-56px-tablet {
      margin-right: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-56px-computer {
      margin-right: 56px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-56px-largeScreen {
      margin-right: 56px !important; } }
  @media print {
    .neg-margin-right-56px-print {
      margin-right: 56px !important; } }

.neg-margin-tb-56px {
  margin-top: 56px !important;
  margin-bottom: 56px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-56px-mobile {
      margin-top: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-56px-tablet {
      margin-top: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-56px-computer {
      margin-top: 56px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-56px-largeScreen {
      margin-top: 56px !important; } }
  @media print {
    .neg-margin-tb-56px-print {
      margin-top: 56px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-56px-mobile {
      margin-bottom: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-56px-tablet {
      margin-bottom: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-56px-computer {
      margin-bottom: 56px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-56px-largeScreen {
      margin-bottom: 56px !important; } }
  @media print {
    .neg-margin-tb-56px-print {
      margin-bottom: 56px !important; } }

.neg-margin-lr-56px {
  margin-left: 56px !important;
  margin-right: 56px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-56px-mobile {
      margin-left: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-56px-tablet {
      margin-left: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-56px-computer {
      margin-left: 56px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-56px-largeScreen {
      margin-left: 56px !important; } }
  @media print {
    .neg-margin-lr-56px-print {
      margin-left: 56px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-56px-mobile {
      margin-right: 56px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-56px-tablet {
      margin-right: 56px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-56px-computer {
      margin-right: 56px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-56px-largeScreen {
      margin-right: 56px !important; } }
  @media print {
    .neg-margin-lr-56px-print {
      margin-right: 56px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-64px {
  padding: 64px !important; }
  @media (max-width: 767px) {
    .padding-64px-mobile {
      padding: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-64px-tablet {
      padding: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-64px-computer {
      padding: 64px !important; } }
  @media (min-width: 1200px) {
    .padding-64px-largeScreen {
      padding: 64px !important; } }
  @media print {
    .padding-64px-print {
      padding: 64px !important; } }

.padding-top-64px {
  padding-top: 64px !important; }
  @media (max-width: 767px) {
    .padding-top-64px-mobile {
      padding-top: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-64px-tablet {
      padding-top: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-64px-computer {
      padding-top: 64px !important; } }
  @media (min-width: 1200px) {
    .padding-top-64px-largeScreen {
      padding-top: 64px !important; } }
  @media print {
    .padding-top-64px-print {
      padding-top: 64px !important; } }

.padding-bottom-64px {
  padding-bottom: 64px !important; }
  @media (max-width: 767px) {
    .padding-bottom-64px-mobile {
      padding-bottom: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-64px-tablet {
      padding-bottom: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-64px-computer {
      padding-bottom: 64px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-64px-largeScreen {
      padding-bottom: 64px !important; } }
  @media print {
    .padding-bottom-64px-print {
      padding-bottom: 64px !important; } }

.padding-left-64px {
  padding-left: 64px !important; }
  @media (max-width: 767px) {
    .padding-left-64px-mobile {
      padding-left: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-64px-tablet {
      padding-left: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-64px-computer {
      padding-left: 64px !important; } }
  @media (min-width: 1200px) {
    .padding-left-64px-largeScreen {
      padding-left: 64px !important; } }
  @media print {
    .padding-left-64px-print {
      padding-left: 64px !important; } }

.padding-right-64px {
  padding-right: 64px !important; }
  @media (max-width: 767px) {
    .padding-right-64px-mobile {
      padding-right: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-64px-tablet {
      padding-right: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-64px-computer {
      padding-right: 64px !important; } }
  @media (min-width: 1200px) {
    .padding-right-64px-largeScreen {
      padding-right: 64px !important; } }
  @media print {
    .padding-right-64px-print {
      padding-right: 64px !important; } }

.padding-tb-64px {
  padding-top: 64px !important;
  padding-bottom: 64px !important; }
  @media (max-width: 767px) {
    .padding-tb-64px-mobile {
      padding-top: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-64px-tablet {
      padding-top: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-64px-computer {
      padding-top: 64px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-64px-largeScreen {
      padding-top: 64px !important; } }
  @media print {
    .padding-tb-64px-print {
      padding-top: 64px !important; } }
  @media (max-width: 767px) {
    .padding-tb-64px-mobile {
      padding-bottom: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-64px-tablet {
      padding-bottom: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-64px-computer {
      padding-bottom: 64px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-64px-largeScreen {
      padding-bottom: 64px !important; } }
  @media print {
    .padding-tb-64px-print {
      padding-bottom: 64px !important; } }

.padding-lr-64px {
  padding-left: 64px !important;
  padding-right: 64px !important; }
  @media (max-width: 767px) {
    .padding-lr-64px-mobile {
      padding-left: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-64px-tablet {
      padding-left: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-64px-computer {
      padding-left: 64px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-64px-largeScreen {
      padding-left: 64px !important; } }
  @media print {
    .padding-lr-64px-print {
      padding-left: 64px !important; } }
  @media (max-width: 767px) {
    .padding-lr-64px-mobile {
      padding-right: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-64px-tablet {
      padding-right: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-64px-computer {
      padding-right: 64px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-64px-largeScreen {
      padding-right: 64px !important; } }
  @media print {
    .padding-lr-64px-print {
      padding-right: 64px !important; } }

.margin-64px {
  margin: 64px !important; }
  @media (max-width: 767px) {
    .margin-64px-mobile {
      margin: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-64px-tablet {
      margin: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-64px-computer {
      margin: 64px !important; } }
  @media (min-width: 1200px) {
    .margin-64px-largeScreen {
      margin: 64px !important; } }
  @media print {
    .margin-64px-print {
      margin: 64px !important; } }

.margin-top-64px {
  margin-top: 64px !important; }
  @media (max-width: 767px) {
    .margin-top-64px-mobile {
      margin-top: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-64px-tablet {
      margin-top: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-64px-computer {
      margin-top: 64px !important; } }
  @media (min-width: 1200px) {
    .margin-top-64px-largeScreen {
      margin-top: 64px !important; } }
  @media print {
    .margin-top-64px-print {
      margin-top: 64px !important; } }

.margin-bottom-64px {
  margin-bottom: 64px !important; }
  @media (max-width: 767px) {
    .margin-bottom-64px-mobile {
      margin-bottom: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-64px-tablet {
      margin-bottom: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-64px-computer {
      margin-bottom: 64px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-64px-largeScreen {
      margin-bottom: 64px !important; } }
  @media print {
    .margin-bottom-64px-print {
      margin-bottom: 64px !important; } }

.margin-left-64px {
  margin-left: 64px !important; }
  @media (max-width: 767px) {
    .margin-left-64px-mobile {
      margin-left: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-64px-tablet {
      margin-left: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-64px-computer {
      margin-left: 64px !important; } }
  @media (min-width: 1200px) {
    .margin-left-64px-largeScreen {
      margin-left: 64px !important; } }
  @media print {
    .margin-left-64px-print {
      margin-left: 64px !important; } }

.margin-right-64px {
  margin-right: 64px !important; }
  @media (max-width: 767px) {
    .margin-right-64px-mobile {
      margin-right: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-64px-tablet {
      margin-right: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-64px-computer {
      margin-right: 64px !important; } }
  @media (min-width: 1200px) {
    .margin-right-64px-largeScreen {
      margin-right: 64px !important; } }
  @media print {
    .margin-right-64px-print {
      margin-right: 64px !important; } }

.margin-tb-64px {
  margin-top: 64px !important;
  margin-bottom: 64px !important; }
  @media (max-width: 767px) {
    .margin-tb-64px-mobile {
      margin-top: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-64px-tablet {
      margin-top: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-64px-computer {
      margin-top: 64px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-64px-largeScreen {
      margin-top: 64px !important; } }
  @media print {
    .margin-tb-64px-print {
      margin-top: 64px !important; } }
  @media (max-width: 767px) {
    .margin-tb-64px-mobile {
      margin-bottom: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-64px-tablet {
      margin-bottom: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-64px-computer {
      margin-bottom: 64px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-64px-largeScreen {
      margin-bottom: 64px !important; } }
  @media print {
    .margin-tb-64px-print {
      margin-bottom: 64px !important; } }

.margin-lr-64px {
  margin-left: 64px !important;
  margin-right: 64px !important; }
  @media (max-width: 767px) {
    .margin-lr-64px-mobile {
      margin-left: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-64px-tablet {
      margin-left: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-64px-computer {
      margin-left: 64px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-64px-largeScreen {
      margin-left: 64px !important; } }
  @media print {
    .margin-lr-64px-print {
      margin-left: 64px !important; } }
  @media (max-width: 767px) {
    .margin-lr-64px-mobile {
      margin-right: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-64px-tablet {
      margin-right: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-64px-computer {
      margin-right: 64px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-64px-largeScreen {
      margin-right: 64px !important; } }
  @media print {
    .margin-lr-64px-print {
      margin-right: 64px !important; } }

/* Negative Margin*/
.neg-margin-64px {
  margin: 64px !important; }
  @media (max-width: 767px) {
    .neg-margin-64px-mobile {
      margin: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-64px-tablet {
      margin: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-64px-computer {
      margin: 64px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-64px-largeScreen {
      margin: 64px !important; } }
  @media print {
    .neg-margin-64px-print {
      margin: 64px !important; } }

.neg-margin-top-64px {
  margin-top: 64px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-64px-mobile {
      margin-top: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-64px-tablet {
      margin-top: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-64px-computer {
      margin-top: 64px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-64px-largeScreen {
      margin-top: 64px !important; } }
  @media print {
    .neg-margin-top-64px-print {
      margin-top: 64px !important; } }

.neg-margin-bottom-64px {
  margin-bottom: 64px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-64px-mobile {
      margin-bottom: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-64px-tablet {
      margin-bottom: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-64px-computer {
      margin-bottom: 64px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-64px-largeScreen {
      margin-bottom: 64px !important; } }
  @media print {
    .neg-margin-bottom-64px-print {
      margin-bottom: 64px !important; } }

.neg-margin-left-64px {
  margin-left: 64px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-64px-mobile {
      margin-left: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-64px-tablet {
      margin-left: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-64px-computer {
      margin-left: 64px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-64px-largeScreen {
      margin-left: 64px !important; } }
  @media print {
    .neg-margin-left-64px-print {
      margin-left: 64px !important; } }

.neg-margin-right-64px {
  margin-right: 64px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-64px-mobile {
      margin-right: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-64px-tablet {
      margin-right: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-64px-computer {
      margin-right: 64px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-64px-largeScreen {
      margin-right: 64px !important; } }
  @media print {
    .neg-margin-right-64px-print {
      margin-right: 64px !important; } }

.neg-margin-tb-64px {
  margin-top: 64px !important;
  margin-bottom: 64px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-64px-mobile {
      margin-top: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-64px-tablet {
      margin-top: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-64px-computer {
      margin-top: 64px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-64px-largeScreen {
      margin-top: 64px !important; } }
  @media print {
    .neg-margin-tb-64px-print {
      margin-top: 64px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-64px-mobile {
      margin-bottom: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-64px-tablet {
      margin-bottom: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-64px-computer {
      margin-bottom: 64px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-64px-largeScreen {
      margin-bottom: 64px !important; } }
  @media print {
    .neg-margin-tb-64px-print {
      margin-bottom: 64px !important; } }

.neg-margin-lr-64px {
  margin-left: 64px !important;
  margin-right: 64px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-64px-mobile {
      margin-left: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-64px-tablet {
      margin-left: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-64px-computer {
      margin-left: 64px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-64px-largeScreen {
      margin-left: 64px !important; } }
  @media print {
    .neg-margin-lr-64px-print {
      margin-left: 64px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-64px-mobile {
      margin-right: 64px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-64px-tablet {
      margin-right: 64px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-64px-computer {
      margin-right: 64px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-64px-largeScreen {
      margin-right: 64px !important; } }
  @media print {
    .neg-margin-lr-64px-print {
      margin-right: 64px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-72px {
  padding: 72px !important; }
  @media (max-width: 767px) {
    .padding-72px-mobile {
      padding: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-72px-tablet {
      padding: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-72px-computer {
      padding: 72px !important; } }
  @media (min-width: 1200px) {
    .padding-72px-largeScreen {
      padding: 72px !important; } }
  @media print {
    .padding-72px-print {
      padding: 72px !important; } }

.padding-top-72px {
  padding-top: 72px !important; }
  @media (max-width: 767px) {
    .padding-top-72px-mobile {
      padding-top: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-72px-tablet {
      padding-top: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-72px-computer {
      padding-top: 72px !important; } }
  @media (min-width: 1200px) {
    .padding-top-72px-largeScreen {
      padding-top: 72px !important; } }
  @media print {
    .padding-top-72px-print {
      padding-top: 72px !important; } }

.padding-bottom-72px {
  padding-bottom: 72px !important; }
  @media (max-width: 767px) {
    .padding-bottom-72px-mobile {
      padding-bottom: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-72px-tablet {
      padding-bottom: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-72px-computer {
      padding-bottom: 72px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-72px-largeScreen {
      padding-bottom: 72px !important; } }
  @media print {
    .padding-bottom-72px-print {
      padding-bottom: 72px !important; } }

.padding-left-72px {
  padding-left: 72px !important; }
  @media (max-width: 767px) {
    .padding-left-72px-mobile {
      padding-left: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-72px-tablet {
      padding-left: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-72px-computer {
      padding-left: 72px !important; } }
  @media (min-width: 1200px) {
    .padding-left-72px-largeScreen {
      padding-left: 72px !important; } }
  @media print {
    .padding-left-72px-print {
      padding-left: 72px !important; } }

.padding-right-72px {
  padding-right: 72px !important; }
  @media (max-width: 767px) {
    .padding-right-72px-mobile {
      padding-right: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-72px-tablet {
      padding-right: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-72px-computer {
      padding-right: 72px !important; } }
  @media (min-width: 1200px) {
    .padding-right-72px-largeScreen {
      padding-right: 72px !important; } }
  @media print {
    .padding-right-72px-print {
      padding-right: 72px !important; } }

.padding-tb-72px {
  padding-top: 72px !important;
  padding-bottom: 72px !important; }
  @media (max-width: 767px) {
    .padding-tb-72px-mobile {
      padding-top: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-72px-tablet {
      padding-top: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-72px-computer {
      padding-top: 72px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-72px-largeScreen {
      padding-top: 72px !important; } }
  @media print {
    .padding-tb-72px-print {
      padding-top: 72px !important; } }
  @media (max-width: 767px) {
    .padding-tb-72px-mobile {
      padding-bottom: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-72px-tablet {
      padding-bottom: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-72px-computer {
      padding-bottom: 72px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-72px-largeScreen {
      padding-bottom: 72px !important; } }
  @media print {
    .padding-tb-72px-print {
      padding-bottom: 72px !important; } }

.padding-lr-72px {
  padding-left: 72px !important;
  padding-right: 72px !important; }
  @media (max-width: 767px) {
    .padding-lr-72px-mobile {
      padding-left: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-72px-tablet {
      padding-left: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-72px-computer {
      padding-left: 72px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-72px-largeScreen {
      padding-left: 72px !important; } }
  @media print {
    .padding-lr-72px-print {
      padding-left: 72px !important; } }
  @media (max-width: 767px) {
    .padding-lr-72px-mobile {
      padding-right: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-72px-tablet {
      padding-right: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-72px-computer {
      padding-right: 72px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-72px-largeScreen {
      padding-right: 72px !important; } }
  @media print {
    .padding-lr-72px-print {
      padding-right: 72px !important; } }

.margin-72px {
  margin: 72px !important; }
  @media (max-width: 767px) {
    .margin-72px-mobile {
      margin: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-72px-tablet {
      margin: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-72px-computer {
      margin: 72px !important; } }
  @media (min-width: 1200px) {
    .margin-72px-largeScreen {
      margin: 72px !important; } }
  @media print {
    .margin-72px-print {
      margin: 72px !important; } }

.margin-top-72px {
  margin-top: 72px !important; }
  @media (max-width: 767px) {
    .margin-top-72px-mobile {
      margin-top: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-72px-tablet {
      margin-top: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-72px-computer {
      margin-top: 72px !important; } }
  @media (min-width: 1200px) {
    .margin-top-72px-largeScreen {
      margin-top: 72px !important; } }
  @media print {
    .margin-top-72px-print {
      margin-top: 72px !important; } }

.margin-bottom-72px {
  margin-bottom: 72px !important; }
  @media (max-width: 767px) {
    .margin-bottom-72px-mobile {
      margin-bottom: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-72px-tablet {
      margin-bottom: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-72px-computer {
      margin-bottom: 72px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-72px-largeScreen {
      margin-bottom: 72px !important; } }
  @media print {
    .margin-bottom-72px-print {
      margin-bottom: 72px !important; } }

.margin-left-72px {
  margin-left: 72px !important; }
  @media (max-width: 767px) {
    .margin-left-72px-mobile {
      margin-left: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-72px-tablet {
      margin-left: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-72px-computer {
      margin-left: 72px !important; } }
  @media (min-width: 1200px) {
    .margin-left-72px-largeScreen {
      margin-left: 72px !important; } }
  @media print {
    .margin-left-72px-print {
      margin-left: 72px !important; } }

.margin-right-72px {
  margin-right: 72px !important; }
  @media (max-width: 767px) {
    .margin-right-72px-mobile {
      margin-right: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-72px-tablet {
      margin-right: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-72px-computer {
      margin-right: 72px !important; } }
  @media (min-width: 1200px) {
    .margin-right-72px-largeScreen {
      margin-right: 72px !important; } }
  @media print {
    .margin-right-72px-print {
      margin-right: 72px !important; } }

.margin-tb-72px {
  margin-top: 72px !important;
  margin-bottom: 72px !important; }
  @media (max-width: 767px) {
    .margin-tb-72px-mobile {
      margin-top: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-72px-tablet {
      margin-top: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-72px-computer {
      margin-top: 72px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-72px-largeScreen {
      margin-top: 72px !important; } }
  @media print {
    .margin-tb-72px-print {
      margin-top: 72px !important; } }
  @media (max-width: 767px) {
    .margin-tb-72px-mobile {
      margin-bottom: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-72px-tablet {
      margin-bottom: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-72px-computer {
      margin-bottom: 72px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-72px-largeScreen {
      margin-bottom: 72px !important; } }
  @media print {
    .margin-tb-72px-print {
      margin-bottom: 72px !important; } }

.margin-lr-72px {
  margin-left: 72px !important;
  margin-right: 72px !important; }
  @media (max-width: 767px) {
    .margin-lr-72px-mobile {
      margin-left: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-72px-tablet {
      margin-left: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-72px-computer {
      margin-left: 72px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-72px-largeScreen {
      margin-left: 72px !important; } }
  @media print {
    .margin-lr-72px-print {
      margin-left: 72px !important; } }
  @media (max-width: 767px) {
    .margin-lr-72px-mobile {
      margin-right: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-72px-tablet {
      margin-right: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-72px-computer {
      margin-right: 72px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-72px-largeScreen {
      margin-right: 72px !important; } }
  @media print {
    .margin-lr-72px-print {
      margin-right: 72px !important; } }

/* Negative Margin*/
.neg-margin-72px {
  margin: 72px !important; }
  @media (max-width: 767px) {
    .neg-margin-72px-mobile {
      margin: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-72px-tablet {
      margin: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-72px-computer {
      margin: 72px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-72px-largeScreen {
      margin: 72px !important; } }
  @media print {
    .neg-margin-72px-print {
      margin: 72px !important; } }

.neg-margin-top-72px {
  margin-top: 72px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-72px-mobile {
      margin-top: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-72px-tablet {
      margin-top: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-72px-computer {
      margin-top: 72px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-72px-largeScreen {
      margin-top: 72px !important; } }
  @media print {
    .neg-margin-top-72px-print {
      margin-top: 72px !important; } }

.neg-margin-bottom-72px {
  margin-bottom: 72px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-72px-mobile {
      margin-bottom: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-72px-tablet {
      margin-bottom: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-72px-computer {
      margin-bottom: 72px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-72px-largeScreen {
      margin-bottom: 72px !important; } }
  @media print {
    .neg-margin-bottom-72px-print {
      margin-bottom: 72px !important; } }

.neg-margin-left-72px {
  margin-left: 72px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-72px-mobile {
      margin-left: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-72px-tablet {
      margin-left: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-72px-computer {
      margin-left: 72px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-72px-largeScreen {
      margin-left: 72px !important; } }
  @media print {
    .neg-margin-left-72px-print {
      margin-left: 72px !important; } }

.neg-margin-right-72px {
  margin-right: 72px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-72px-mobile {
      margin-right: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-72px-tablet {
      margin-right: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-72px-computer {
      margin-right: 72px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-72px-largeScreen {
      margin-right: 72px !important; } }
  @media print {
    .neg-margin-right-72px-print {
      margin-right: 72px !important; } }

.neg-margin-tb-72px {
  margin-top: 72px !important;
  margin-bottom: 72px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-72px-mobile {
      margin-top: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-72px-tablet {
      margin-top: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-72px-computer {
      margin-top: 72px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-72px-largeScreen {
      margin-top: 72px !important; } }
  @media print {
    .neg-margin-tb-72px-print {
      margin-top: 72px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-72px-mobile {
      margin-bottom: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-72px-tablet {
      margin-bottom: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-72px-computer {
      margin-bottom: 72px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-72px-largeScreen {
      margin-bottom: 72px !important; } }
  @media print {
    .neg-margin-tb-72px-print {
      margin-bottom: 72px !important; } }

.neg-margin-lr-72px {
  margin-left: 72px !important;
  margin-right: 72px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-72px-mobile {
      margin-left: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-72px-tablet {
      margin-left: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-72px-computer {
      margin-left: 72px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-72px-largeScreen {
      margin-left: 72px !important; } }
  @media print {
    .neg-margin-lr-72px-print {
      margin-left: 72px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-72px-mobile {
      margin-right: 72px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-72px-tablet {
      margin-right: 72px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-72px-computer {
      margin-right: 72px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-72px-largeScreen {
      margin-right: 72px !important; } }
  @media print {
    .neg-margin-lr-72px-print {
      margin-right: 72px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-80px {
  padding: 80px !important; }
  @media (max-width: 767px) {
    .padding-80px-mobile {
      padding: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-80px-tablet {
      padding: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-80px-computer {
      padding: 80px !important; } }
  @media (min-width: 1200px) {
    .padding-80px-largeScreen {
      padding: 80px !important; } }
  @media print {
    .padding-80px-print {
      padding: 80px !important; } }

.padding-top-80px {
  padding-top: 80px !important; }
  @media (max-width: 767px) {
    .padding-top-80px-mobile {
      padding-top: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-80px-tablet {
      padding-top: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-80px-computer {
      padding-top: 80px !important; } }
  @media (min-width: 1200px) {
    .padding-top-80px-largeScreen {
      padding-top: 80px !important; } }
  @media print {
    .padding-top-80px-print {
      padding-top: 80px !important; } }

.padding-bottom-80px {
  padding-bottom: 80px !important; }
  @media (max-width: 767px) {
    .padding-bottom-80px-mobile {
      padding-bottom: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-80px-tablet {
      padding-bottom: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-80px-computer {
      padding-bottom: 80px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-80px-largeScreen {
      padding-bottom: 80px !important; } }
  @media print {
    .padding-bottom-80px-print {
      padding-bottom: 80px !important; } }

.padding-left-80px {
  padding-left: 80px !important; }
  @media (max-width: 767px) {
    .padding-left-80px-mobile {
      padding-left: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-80px-tablet {
      padding-left: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-80px-computer {
      padding-left: 80px !important; } }
  @media (min-width: 1200px) {
    .padding-left-80px-largeScreen {
      padding-left: 80px !important; } }
  @media print {
    .padding-left-80px-print {
      padding-left: 80px !important; } }

.padding-right-80px {
  padding-right: 80px !important; }
  @media (max-width: 767px) {
    .padding-right-80px-mobile {
      padding-right: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-80px-tablet {
      padding-right: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-80px-computer {
      padding-right: 80px !important; } }
  @media (min-width: 1200px) {
    .padding-right-80px-largeScreen {
      padding-right: 80px !important; } }
  @media print {
    .padding-right-80px-print {
      padding-right: 80px !important; } }

.padding-tb-80px {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }
  @media (max-width: 767px) {
    .padding-tb-80px-mobile {
      padding-top: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-80px-tablet {
      padding-top: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-80px-computer {
      padding-top: 80px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-80px-largeScreen {
      padding-top: 80px !important; } }
  @media print {
    .padding-tb-80px-print {
      padding-top: 80px !important; } }
  @media (max-width: 767px) {
    .padding-tb-80px-mobile {
      padding-bottom: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-80px-tablet {
      padding-bottom: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-80px-computer {
      padding-bottom: 80px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-80px-largeScreen {
      padding-bottom: 80px !important; } }
  @media print {
    .padding-tb-80px-print {
      padding-bottom: 80px !important; } }

.padding-lr-80px {
  padding-left: 80px !important;
  padding-right: 80px !important; }
  @media (max-width: 767px) {
    .padding-lr-80px-mobile {
      padding-left: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-80px-tablet {
      padding-left: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-80px-computer {
      padding-left: 80px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-80px-largeScreen {
      padding-left: 80px !important; } }
  @media print {
    .padding-lr-80px-print {
      padding-left: 80px !important; } }
  @media (max-width: 767px) {
    .padding-lr-80px-mobile {
      padding-right: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-80px-tablet {
      padding-right: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-80px-computer {
      padding-right: 80px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-80px-largeScreen {
      padding-right: 80px !important; } }
  @media print {
    .padding-lr-80px-print {
      padding-right: 80px !important; } }

.margin-80px {
  margin: 80px !important; }
  @media (max-width: 767px) {
    .margin-80px-mobile {
      margin: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-80px-tablet {
      margin: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-80px-computer {
      margin: 80px !important; } }
  @media (min-width: 1200px) {
    .margin-80px-largeScreen {
      margin: 80px !important; } }
  @media print {
    .margin-80px-print {
      margin: 80px !important; } }

.margin-top-80px {
  margin-top: 80px !important; }
  @media (max-width: 767px) {
    .margin-top-80px-mobile {
      margin-top: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-80px-tablet {
      margin-top: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-80px-computer {
      margin-top: 80px !important; } }
  @media (min-width: 1200px) {
    .margin-top-80px-largeScreen {
      margin-top: 80px !important; } }
  @media print {
    .margin-top-80px-print {
      margin-top: 80px !important; } }

.margin-bottom-80px {
  margin-bottom: 80px !important; }
  @media (max-width: 767px) {
    .margin-bottom-80px-mobile {
      margin-bottom: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-80px-tablet {
      margin-bottom: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-80px-computer {
      margin-bottom: 80px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-80px-largeScreen {
      margin-bottom: 80px !important; } }
  @media print {
    .margin-bottom-80px-print {
      margin-bottom: 80px !important; } }

.margin-left-80px {
  margin-left: 80px !important; }
  @media (max-width: 767px) {
    .margin-left-80px-mobile {
      margin-left: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-80px-tablet {
      margin-left: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-80px-computer {
      margin-left: 80px !important; } }
  @media (min-width: 1200px) {
    .margin-left-80px-largeScreen {
      margin-left: 80px !important; } }
  @media print {
    .margin-left-80px-print {
      margin-left: 80px !important; } }

.margin-right-80px {
  margin-right: 80px !important; }
  @media (max-width: 767px) {
    .margin-right-80px-mobile {
      margin-right: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-80px-tablet {
      margin-right: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-80px-computer {
      margin-right: 80px !important; } }
  @media (min-width: 1200px) {
    .margin-right-80px-largeScreen {
      margin-right: 80px !important; } }
  @media print {
    .margin-right-80px-print {
      margin-right: 80px !important; } }

.margin-tb-80px {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }
  @media (max-width: 767px) {
    .margin-tb-80px-mobile {
      margin-top: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-80px-tablet {
      margin-top: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-80px-computer {
      margin-top: 80px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-80px-largeScreen {
      margin-top: 80px !important; } }
  @media print {
    .margin-tb-80px-print {
      margin-top: 80px !important; } }
  @media (max-width: 767px) {
    .margin-tb-80px-mobile {
      margin-bottom: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-80px-tablet {
      margin-bottom: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-80px-computer {
      margin-bottom: 80px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-80px-largeScreen {
      margin-bottom: 80px !important; } }
  @media print {
    .margin-tb-80px-print {
      margin-bottom: 80px !important; } }

.margin-lr-80px {
  margin-left: 80px !important;
  margin-right: 80px !important; }
  @media (max-width: 767px) {
    .margin-lr-80px-mobile {
      margin-left: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-80px-tablet {
      margin-left: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-80px-computer {
      margin-left: 80px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-80px-largeScreen {
      margin-left: 80px !important; } }
  @media print {
    .margin-lr-80px-print {
      margin-left: 80px !important; } }
  @media (max-width: 767px) {
    .margin-lr-80px-mobile {
      margin-right: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-80px-tablet {
      margin-right: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-80px-computer {
      margin-right: 80px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-80px-largeScreen {
      margin-right: 80px !important; } }
  @media print {
    .margin-lr-80px-print {
      margin-right: 80px !important; } }

/* Negative Margin*/
.neg-margin-80px {
  margin: 80px !important; }
  @media (max-width: 767px) {
    .neg-margin-80px-mobile {
      margin: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-80px-tablet {
      margin: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-80px-computer {
      margin: 80px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-80px-largeScreen {
      margin: 80px !important; } }
  @media print {
    .neg-margin-80px-print {
      margin: 80px !important; } }

.neg-margin-top-80px {
  margin-top: 80px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-80px-mobile {
      margin-top: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-80px-tablet {
      margin-top: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-80px-computer {
      margin-top: 80px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-80px-largeScreen {
      margin-top: 80px !important; } }
  @media print {
    .neg-margin-top-80px-print {
      margin-top: 80px !important; } }

.neg-margin-bottom-80px {
  margin-bottom: 80px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-80px-mobile {
      margin-bottom: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-80px-tablet {
      margin-bottom: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-80px-computer {
      margin-bottom: 80px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-80px-largeScreen {
      margin-bottom: 80px !important; } }
  @media print {
    .neg-margin-bottom-80px-print {
      margin-bottom: 80px !important; } }

.neg-margin-left-80px {
  margin-left: 80px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-80px-mobile {
      margin-left: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-80px-tablet {
      margin-left: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-80px-computer {
      margin-left: 80px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-80px-largeScreen {
      margin-left: 80px !important; } }
  @media print {
    .neg-margin-left-80px-print {
      margin-left: 80px !important; } }

.neg-margin-right-80px {
  margin-right: 80px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-80px-mobile {
      margin-right: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-80px-tablet {
      margin-right: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-80px-computer {
      margin-right: 80px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-80px-largeScreen {
      margin-right: 80px !important; } }
  @media print {
    .neg-margin-right-80px-print {
      margin-right: 80px !important; } }

.neg-margin-tb-80px {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-80px-mobile {
      margin-top: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-80px-tablet {
      margin-top: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-80px-computer {
      margin-top: 80px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-80px-largeScreen {
      margin-top: 80px !important; } }
  @media print {
    .neg-margin-tb-80px-print {
      margin-top: 80px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-80px-mobile {
      margin-bottom: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-80px-tablet {
      margin-bottom: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-80px-computer {
      margin-bottom: 80px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-80px-largeScreen {
      margin-bottom: 80px !important; } }
  @media print {
    .neg-margin-tb-80px-print {
      margin-bottom: 80px !important; } }

.neg-margin-lr-80px {
  margin-left: 80px !important;
  margin-right: 80px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-80px-mobile {
      margin-left: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-80px-tablet {
      margin-left: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-80px-computer {
      margin-left: 80px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-80px-largeScreen {
      margin-left: 80px !important; } }
  @media print {
    .neg-margin-lr-80px-print {
      margin-left: 80px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-80px-mobile {
      margin-right: 80px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-80px-tablet {
      margin-right: 80px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-80px-computer {
      margin-right: 80px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-80px-largeScreen {
      margin-right: 80px !important; } }
  @media print {
    .neg-margin-lr-80px-print {
      margin-right: 80px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-88px {
  padding: 88px !important; }
  @media (max-width: 767px) {
    .padding-88px-mobile {
      padding: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-88px-tablet {
      padding: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-88px-computer {
      padding: 88px !important; } }
  @media (min-width: 1200px) {
    .padding-88px-largeScreen {
      padding: 88px !important; } }
  @media print {
    .padding-88px-print {
      padding: 88px !important; } }

.padding-top-88px {
  padding-top: 88px !important; }
  @media (max-width: 767px) {
    .padding-top-88px-mobile {
      padding-top: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-88px-tablet {
      padding-top: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-88px-computer {
      padding-top: 88px !important; } }
  @media (min-width: 1200px) {
    .padding-top-88px-largeScreen {
      padding-top: 88px !important; } }
  @media print {
    .padding-top-88px-print {
      padding-top: 88px !important; } }

.padding-bottom-88px {
  padding-bottom: 88px !important; }
  @media (max-width: 767px) {
    .padding-bottom-88px-mobile {
      padding-bottom: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-88px-tablet {
      padding-bottom: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-88px-computer {
      padding-bottom: 88px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-88px-largeScreen {
      padding-bottom: 88px !important; } }
  @media print {
    .padding-bottom-88px-print {
      padding-bottom: 88px !important; } }

.padding-left-88px {
  padding-left: 88px !important; }
  @media (max-width: 767px) {
    .padding-left-88px-mobile {
      padding-left: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-88px-tablet {
      padding-left: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-88px-computer {
      padding-left: 88px !important; } }
  @media (min-width: 1200px) {
    .padding-left-88px-largeScreen {
      padding-left: 88px !important; } }
  @media print {
    .padding-left-88px-print {
      padding-left: 88px !important; } }

.padding-right-88px {
  padding-right: 88px !important; }
  @media (max-width: 767px) {
    .padding-right-88px-mobile {
      padding-right: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-88px-tablet {
      padding-right: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-88px-computer {
      padding-right: 88px !important; } }
  @media (min-width: 1200px) {
    .padding-right-88px-largeScreen {
      padding-right: 88px !important; } }
  @media print {
    .padding-right-88px-print {
      padding-right: 88px !important; } }

.padding-tb-88px {
  padding-top: 88px !important;
  padding-bottom: 88px !important; }
  @media (max-width: 767px) {
    .padding-tb-88px-mobile {
      padding-top: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-88px-tablet {
      padding-top: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-88px-computer {
      padding-top: 88px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-88px-largeScreen {
      padding-top: 88px !important; } }
  @media print {
    .padding-tb-88px-print {
      padding-top: 88px !important; } }
  @media (max-width: 767px) {
    .padding-tb-88px-mobile {
      padding-bottom: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-88px-tablet {
      padding-bottom: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-88px-computer {
      padding-bottom: 88px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-88px-largeScreen {
      padding-bottom: 88px !important; } }
  @media print {
    .padding-tb-88px-print {
      padding-bottom: 88px !important; } }

.padding-lr-88px {
  padding-left: 88px !important;
  padding-right: 88px !important; }
  @media (max-width: 767px) {
    .padding-lr-88px-mobile {
      padding-left: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-88px-tablet {
      padding-left: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-88px-computer {
      padding-left: 88px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-88px-largeScreen {
      padding-left: 88px !important; } }
  @media print {
    .padding-lr-88px-print {
      padding-left: 88px !important; } }
  @media (max-width: 767px) {
    .padding-lr-88px-mobile {
      padding-right: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-88px-tablet {
      padding-right: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-88px-computer {
      padding-right: 88px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-88px-largeScreen {
      padding-right: 88px !important; } }
  @media print {
    .padding-lr-88px-print {
      padding-right: 88px !important; } }

.margin-88px {
  margin: 88px !important; }
  @media (max-width: 767px) {
    .margin-88px-mobile {
      margin: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-88px-tablet {
      margin: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-88px-computer {
      margin: 88px !important; } }
  @media (min-width: 1200px) {
    .margin-88px-largeScreen {
      margin: 88px !important; } }
  @media print {
    .margin-88px-print {
      margin: 88px !important; } }

.margin-top-88px {
  margin-top: 88px !important; }
  @media (max-width: 767px) {
    .margin-top-88px-mobile {
      margin-top: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-88px-tablet {
      margin-top: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-88px-computer {
      margin-top: 88px !important; } }
  @media (min-width: 1200px) {
    .margin-top-88px-largeScreen {
      margin-top: 88px !important; } }
  @media print {
    .margin-top-88px-print {
      margin-top: 88px !important; } }

.margin-bottom-88px {
  margin-bottom: 88px !important; }
  @media (max-width: 767px) {
    .margin-bottom-88px-mobile {
      margin-bottom: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-88px-tablet {
      margin-bottom: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-88px-computer {
      margin-bottom: 88px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-88px-largeScreen {
      margin-bottom: 88px !important; } }
  @media print {
    .margin-bottom-88px-print {
      margin-bottom: 88px !important; } }

.margin-left-88px {
  margin-left: 88px !important; }
  @media (max-width: 767px) {
    .margin-left-88px-mobile {
      margin-left: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-88px-tablet {
      margin-left: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-88px-computer {
      margin-left: 88px !important; } }
  @media (min-width: 1200px) {
    .margin-left-88px-largeScreen {
      margin-left: 88px !important; } }
  @media print {
    .margin-left-88px-print {
      margin-left: 88px !important; } }

.margin-right-88px {
  margin-right: 88px !important; }
  @media (max-width: 767px) {
    .margin-right-88px-mobile {
      margin-right: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-88px-tablet {
      margin-right: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-88px-computer {
      margin-right: 88px !important; } }
  @media (min-width: 1200px) {
    .margin-right-88px-largeScreen {
      margin-right: 88px !important; } }
  @media print {
    .margin-right-88px-print {
      margin-right: 88px !important; } }

.margin-tb-88px {
  margin-top: 88px !important;
  margin-bottom: 88px !important; }
  @media (max-width: 767px) {
    .margin-tb-88px-mobile {
      margin-top: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-88px-tablet {
      margin-top: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-88px-computer {
      margin-top: 88px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-88px-largeScreen {
      margin-top: 88px !important; } }
  @media print {
    .margin-tb-88px-print {
      margin-top: 88px !important; } }
  @media (max-width: 767px) {
    .margin-tb-88px-mobile {
      margin-bottom: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-88px-tablet {
      margin-bottom: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-88px-computer {
      margin-bottom: 88px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-88px-largeScreen {
      margin-bottom: 88px !important; } }
  @media print {
    .margin-tb-88px-print {
      margin-bottom: 88px !important; } }

.margin-lr-88px {
  margin-left: 88px !important;
  margin-right: 88px !important; }
  @media (max-width: 767px) {
    .margin-lr-88px-mobile {
      margin-left: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-88px-tablet {
      margin-left: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-88px-computer {
      margin-left: 88px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-88px-largeScreen {
      margin-left: 88px !important; } }
  @media print {
    .margin-lr-88px-print {
      margin-left: 88px !important; } }
  @media (max-width: 767px) {
    .margin-lr-88px-mobile {
      margin-right: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-88px-tablet {
      margin-right: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-88px-computer {
      margin-right: 88px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-88px-largeScreen {
      margin-right: 88px !important; } }
  @media print {
    .margin-lr-88px-print {
      margin-right: 88px !important; } }

/* Negative Margin*/
.neg-margin-88px {
  margin: 88px !important; }
  @media (max-width: 767px) {
    .neg-margin-88px-mobile {
      margin: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-88px-tablet {
      margin: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-88px-computer {
      margin: 88px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-88px-largeScreen {
      margin: 88px !important; } }
  @media print {
    .neg-margin-88px-print {
      margin: 88px !important; } }

.neg-margin-top-88px {
  margin-top: 88px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-88px-mobile {
      margin-top: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-88px-tablet {
      margin-top: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-88px-computer {
      margin-top: 88px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-88px-largeScreen {
      margin-top: 88px !important; } }
  @media print {
    .neg-margin-top-88px-print {
      margin-top: 88px !important; } }

.neg-margin-bottom-88px {
  margin-bottom: 88px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-88px-mobile {
      margin-bottom: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-88px-tablet {
      margin-bottom: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-88px-computer {
      margin-bottom: 88px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-88px-largeScreen {
      margin-bottom: 88px !important; } }
  @media print {
    .neg-margin-bottom-88px-print {
      margin-bottom: 88px !important; } }

.neg-margin-left-88px {
  margin-left: 88px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-88px-mobile {
      margin-left: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-88px-tablet {
      margin-left: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-88px-computer {
      margin-left: 88px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-88px-largeScreen {
      margin-left: 88px !important; } }
  @media print {
    .neg-margin-left-88px-print {
      margin-left: 88px !important; } }

.neg-margin-right-88px {
  margin-right: 88px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-88px-mobile {
      margin-right: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-88px-tablet {
      margin-right: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-88px-computer {
      margin-right: 88px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-88px-largeScreen {
      margin-right: 88px !important; } }
  @media print {
    .neg-margin-right-88px-print {
      margin-right: 88px !important; } }

.neg-margin-tb-88px {
  margin-top: 88px !important;
  margin-bottom: 88px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-88px-mobile {
      margin-top: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-88px-tablet {
      margin-top: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-88px-computer {
      margin-top: 88px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-88px-largeScreen {
      margin-top: 88px !important; } }
  @media print {
    .neg-margin-tb-88px-print {
      margin-top: 88px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-88px-mobile {
      margin-bottom: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-88px-tablet {
      margin-bottom: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-88px-computer {
      margin-bottom: 88px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-88px-largeScreen {
      margin-bottom: 88px !important; } }
  @media print {
    .neg-margin-tb-88px-print {
      margin-bottom: 88px !important; } }

.neg-margin-lr-88px {
  margin-left: 88px !important;
  margin-right: 88px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-88px-mobile {
      margin-left: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-88px-tablet {
      margin-left: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-88px-computer {
      margin-left: 88px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-88px-largeScreen {
      margin-left: 88px !important; } }
  @media print {
    .neg-margin-lr-88px-print {
      margin-left: 88px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-88px-mobile {
      margin-right: 88px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-88px-tablet {
      margin-right: 88px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-88px-computer {
      margin-right: 88px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-88px-largeScreen {
      margin-right: 88px !important; } }
  @media print {
    .neg-margin-lr-88px-print {
      margin-right: 88px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-96px {
  padding: 96px !important; }
  @media (max-width: 767px) {
    .padding-96px-mobile {
      padding: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-96px-tablet {
      padding: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-96px-computer {
      padding: 96px !important; } }
  @media (min-width: 1200px) {
    .padding-96px-largeScreen {
      padding: 96px !important; } }
  @media print {
    .padding-96px-print {
      padding: 96px !important; } }

.padding-top-96px {
  padding-top: 96px !important; }
  @media (max-width: 767px) {
    .padding-top-96px-mobile {
      padding-top: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-96px-tablet {
      padding-top: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-96px-computer {
      padding-top: 96px !important; } }
  @media (min-width: 1200px) {
    .padding-top-96px-largeScreen {
      padding-top: 96px !important; } }
  @media print {
    .padding-top-96px-print {
      padding-top: 96px !important; } }

.padding-bottom-96px {
  padding-bottom: 96px !important; }
  @media (max-width: 767px) {
    .padding-bottom-96px-mobile {
      padding-bottom: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-96px-tablet {
      padding-bottom: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-96px-computer {
      padding-bottom: 96px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-96px-largeScreen {
      padding-bottom: 96px !important; } }
  @media print {
    .padding-bottom-96px-print {
      padding-bottom: 96px !important; } }

.padding-left-96px {
  padding-left: 96px !important; }
  @media (max-width: 767px) {
    .padding-left-96px-mobile {
      padding-left: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-96px-tablet {
      padding-left: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-96px-computer {
      padding-left: 96px !important; } }
  @media (min-width: 1200px) {
    .padding-left-96px-largeScreen {
      padding-left: 96px !important; } }
  @media print {
    .padding-left-96px-print {
      padding-left: 96px !important; } }

.padding-right-96px {
  padding-right: 96px !important; }
  @media (max-width: 767px) {
    .padding-right-96px-mobile {
      padding-right: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-96px-tablet {
      padding-right: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-96px-computer {
      padding-right: 96px !important; } }
  @media (min-width: 1200px) {
    .padding-right-96px-largeScreen {
      padding-right: 96px !important; } }
  @media print {
    .padding-right-96px-print {
      padding-right: 96px !important; } }

.padding-tb-96px {
  padding-top: 96px !important;
  padding-bottom: 96px !important; }
  @media (max-width: 767px) {
    .padding-tb-96px-mobile {
      padding-top: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-96px-tablet {
      padding-top: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-96px-computer {
      padding-top: 96px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-96px-largeScreen {
      padding-top: 96px !important; } }
  @media print {
    .padding-tb-96px-print {
      padding-top: 96px !important; } }
  @media (max-width: 767px) {
    .padding-tb-96px-mobile {
      padding-bottom: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-96px-tablet {
      padding-bottom: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-96px-computer {
      padding-bottom: 96px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-96px-largeScreen {
      padding-bottom: 96px !important; } }
  @media print {
    .padding-tb-96px-print {
      padding-bottom: 96px !important; } }

.padding-lr-96px {
  padding-left: 96px !important;
  padding-right: 96px !important; }
  @media (max-width: 767px) {
    .padding-lr-96px-mobile {
      padding-left: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-96px-tablet {
      padding-left: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-96px-computer {
      padding-left: 96px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-96px-largeScreen {
      padding-left: 96px !important; } }
  @media print {
    .padding-lr-96px-print {
      padding-left: 96px !important; } }
  @media (max-width: 767px) {
    .padding-lr-96px-mobile {
      padding-right: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-96px-tablet {
      padding-right: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-96px-computer {
      padding-right: 96px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-96px-largeScreen {
      padding-right: 96px !important; } }
  @media print {
    .padding-lr-96px-print {
      padding-right: 96px !important; } }

.margin-96px {
  margin: 96px !important; }
  @media (max-width: 767px) {
    .margin-96px-mobile {
      margin: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-96px-tablet {
      margin: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-96px-computer {
      margin: 96px !important; } }
  @media (min-width: 1200px) {
    .margin-96px-largeScreen {
      margin: 96px !important; } }
  @media print {
    .margin-96px-print {
      margin: 96px !important; } }

.margin-top-96px {
  margin-top: 96px !important; }
  @media (max-width: 767px) {
    .margin-top-96px-mobile {
      margin-top: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-96px-tablet {
      margin-top: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-96px-computer {
      margin-top: 96px !important; } }
  @media (min-width: 1200px) {
    .margin-top-96px-largeScreen {
      margin-top: 96px !important; } }
  @media print {
    .margin-top-96px-print {
      margin-top: 96px !important; } }

.margin-bottom-96px {
  margin-bottom: 96px !important; }
  @media (max-width: 767px) {
    .margin-bottom-96px-mobile {
      margin-bottom: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-96px-tablet {
      margin-bottom: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-96px-computer {
      margin-bottom: 96px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-96px-largeScreen {
      margin-bottom: 96px !important; } }
  @media print {
    .margin-bottom-96px-print {
      margin-bottom: 96px !important; } }

.margin-left-96px {
  margin-left: 96px !important; }
  @media (max-width: 767px) {
    .margin-left-96px-mobile {
      margin-left: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-96px-tablet {
      margin-left: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-96px-computer {
      margin-left: 96px !important; } }
  @media (min-width: 1200px) {
    .margin-left-96px-largeScreen {
      margin-left: 96px !important; } }
  @media print {
    .margin-left-96px-print {
      margin-left: 96px !important; } }

.margin-right-96px {
  margin-right: 96px !important; }
  @media (max-width: 767px) {
    .margin-right-96px-mobile {
      margin-right: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-96px-tablet {
      margin-right: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-96px-computer {
      margin-right: 96px !important; } }
  @media (min-width: 1200px) {
    .margin-right-96px-largeScreen {
      margin-right: 96px !important; } }
  @media print {
    .margin-right-96px-print {
      margin-right: 96px !important; } }

.margin-tb-96px {
  margin-top: 96px !important;
  margin-bottom: 96px !important; }
  @media (max-width: 767px) {
    .margin-tb-96px-mobile {
      margin-top: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-96px-tablet {
      margin-top: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-96px-computer {
      margin-top: 96px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-96px-largeScreen {
      margin-top: 96px !important; } }
  @media print {
    .margin-tb-96px-print {
      margin-top: 96px !important; } }
  @media (max-width: 767px) {
    .margin-tb-96px-mobile {
      margin-bottom: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-96px-tablet {
      margin-bottom: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-96px-computer {
      margin-bottom: 96px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-96px-largeScreen {
      margin-bottom: 96px !important; } }
  @media print {
    .margin-tb-96px-print {
      margin-bottom: 96px !important; } }

.margin-lr-96px {
  margin-left: 96px !important;
  margin-right: 96px !important; }
  @media (max-width: 767px) {
    .margin-lr-96px-mobile {
      margin-left: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-96px-tablet {
      margin-left: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-96px-computer {
      margin-left: 96px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-96px-largeScreen {
      margin-left: 96px !important; } }
  @media print {
    .margin-lr-96px-print {
      margin-left: 96px !important; } }
  @media (max-width: 767px) {
    .margin-lr-96px-mobile {
      margin-right: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-96px-tablet {
      margin-right: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-96px-computer {
      margin-right: 96px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-96px-largeScreen {
      margin-right: 96px !important; } }
  @media print {
    .margin-lr-96px-print {
      margin-right: 96px !important; } }

/* Negative Margin*/
.neg-margin-96px {
  margin: 96px !important; }
  @media (max-width: 767px) {
    .neg-margin-96px-mobile {
      margin: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-96px-tablet {
      margin: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-96px-computer {
      margin: 96px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-96px-largeScreen {
      margin: 96px !important; } }
  @media print {
    .neg-margin-96px-print {
      margin: 96px !important; } }

.neg-margin-top-96px {
  margin-top: 96px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-96px-mobile {
      margin-top: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-96px-tablet {
      margin-top: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-96px-computer {
      margin-top: 96px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-96px-largeScreen {
      margin-top: 96px !important; } }
  @media print {
    .neg-margin-top-96px-print {
      margin-top: 96px !important; } }

.neg-margin-bottom-96px {
  margin-bottom: 96px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-96px-mobile {
      margin-bottom: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-96px-tablet {
      margin-bottom: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-96px-computer {
      margin-bottom: 96px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-96px-largeScreen {
      margin-bottom: 96px !important; } }
  @media print {
    .neg-margin-bottom-96px-print {
      margin-bottom: 96px !important; } }

.neg-margin-left-96px {
  margin-left: 96px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-96px-mobile {
      margin-left: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-96px-tablet {
      margin-left: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-96px-computer {
      margin-left: 96px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-96px-largeScreen {
      margin-left: 96px !important; } }
  @media print {
    .neg-margin-left-96px-print {
      margin-left: 96px !important; } }

.neg-margin-right-96px {
  margin-right: 96px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-96px-mobile {
      margin-right: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-96px-tablet {
      margin-right: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-96px-computer {
      margin-right: 96px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-96px-largeScreen {
      margin-right: 96px !important; } }
  @media print {
    .neg-margin-right-96px-print {
      margin-right: 96px !important; } }

.neg-margin-tb-96px {
  margin-top: 96px !important;
  margin-bottom: 96px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-96px-mobile {
      margin-top: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-96px-tablet {
      margin-top: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-96px-computer {
      margin-top: 96px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-96px-largeScreen {
      margin-top: 96px !important; } }
  @media print {
    .neg-margin-tb-96px-print {
      margin-top: 96px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-96px-mobile {
      margin-bottom: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-96px-tablet {
      margin-bottom: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-96px-computer {
      margin-bottom: 96px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-96px-largeScreen {
      margin-bottom: 96px !important; } }
  @media print {
    .neg-margin-tb-96px-print {
      margin-bottom: 96px !important; } }

.neg-margin-lr-96px {
  margin-left: 96px !important;
  margin-right: 96px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-96px-mobile {
      margin-left: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-96px-tablet {
      margin-left: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-96px-computer {
      margin-left: 96px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-96px-largeScreen {
      margin-left: 96px !important; } }
  @media print {
    .neg-margin-lr-96px-print {
      margin-left: 96px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-96px-mobile {
      margin-right: 96px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-96px-tablet {
      margin-right: 96px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-96px-computer {
      margin-right: 96px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-96px-largeScreen {
      margin-right: 96px !important; } }
  @media print {
    .neg-margin-lr-96px-print {
      margin-right: 96px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-104px {
  padding: 104px !important; }
  @media (max-width: 767px) {
    .padding-104px-mobile {
      padding: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-104px-tablet {
      padding: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-104px-computer {
      padding: 104px !important; } }
  @media (min-width: 1200px) {
    .padding-104px-largeScreen {
      padding: 104px !important; } }
  @media print {
    .padding-104px-print {
      padding: 104px !important; } }

.padding-top-104px {
  padding-top: 104px !important; }
  @media (max-width: 767px) {
    .padding-top-104px-mobile {
      padding-top: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-104px-tablet {
      padding-top: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-104px-computer {
      padding-top: 104px !important; } }
  @media (min-width: 1200px) {
    .padding-top-104px-largeScreen {
      padding-top: 104px !important; } }
  @media print {
    .padding-top-104px-print {
      padding-top: 104px !important; } }

.padding-bottom-104px {
  padding-bottom: 104px !important; }
  @media (max-width: 767px) {
    .padding-bottom-104px-mobile {
      padding-bottom: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-104px-tablet {
      padding-bottom: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-104px-computer {
      padding-bottom: 104px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-104px-largeScreen {
      padding-bottom: 104px !important; } }
  @media print {
    .padding-bottom-104px-print {
      padding-bottom: 104px !important; } }

.padding-left-104px {
  padding-left: 104px !important; }
  @media (max-width: 767px) {
    .padding-left-104px-mobile {
      padding-left: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-104px-tablet {
      padding-left: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-104px-computer {
      padding-left: 104px !important; } }
  @media (min-width: 1200px) {
    .padding-left-104px-largeScreen {
      padding-left: 104px !important; } }
  @media print {
    .padding-left-104px-print {
      padding-left: 104px !important; } }

.padding-right-104px {
  padding-right: 104px !important; }
  @media (max-width: 767px) {
    .padding-right-104px-mobile {
      padding-right: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-104px-tablet {
      padding-right: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-104px-computer {
      padding-right: 104px !important; } }
  @media (min-width: 1200px) {
    .padding-right-104px-largeScreen {
      padding-right: 104px !important; } }
  @media print {
    .padding-right-104px-print {
      padding-right: 104px !important; } }

.padding-tb-104px {
  padding-top: 104px !important;
  padding-bottom: 104px !important; }
  @media (max-width: 767px) {
    .padding-tb-104px-mobile {
      padding-top: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-104px-tablet {
      padding-top: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-104px-computer {
      padding-top: 104px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-104px-largeScreen {
      padding-top: 104px !important; } }
  @media print {
    .padding-tb-104px-print {
      padding-top: 104px !important; } }
  @media (max-width: 767px) {
    .padding-tb-104px-mobile {
      padding-bottom: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-104px-tablet {
      padding-bottom: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-104px-computer {
      padding-bottom: 104px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-104px-largeScreen {
      padding-bottom: 104px !important; } }
  @media print {
    .padding-tb-104px-print {
      padding-bottom: 104px !important; } }

.padding-lr-104px {
  padding-left: 104px !important;
  padding-right: 104px !important; }
  @media (max-width: 767px) {
    .padding-lr-104px-mobile {
      padding-left: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-104px-tablet {
      padding-left: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-104px-computer {
      padding-left: 104px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-104px-largeScreen {
      padding-left: 104px !important; } }
  @media print {
    .padding-lr-104px-print {
      padding-left: 104px !important; } }
  @media (max-width: 767px) {
    .padding-lr-104px-mobile {
      padding-right: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-104px-tablet {
      padding-right: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-104px-computer {
      padding-right: 104px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-104px-largeScreen {
      padding-right: 104px !important; } }
  @media print {
    .padding-lr-104px-print {
      padding-right: 104px !important; } }

.margin-104px {
  margin: 104px !important; }
  @media (max-width: 767px) {
    .margin-104px-mobile {
      margin: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-104px-tablet {
      margin: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-104px-computer {
      margin: 104px !important; } }
  @media (min-width: 1200px) {
    .margin-104px-largeScreen {
      margin: 104px !important; } }
  @media print {
    .margin-104px-print {
      margin: 104px !important; } }

.margin-top-104px {
  margin-top: 104px !important; }
  @media (max-width: 767px) {
    .margin-top-104px-mobile {
      margin-top: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-104px-tablet {
      margin-top: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-104px-computer {
      margin-top: 104px !important; } }
  @media (min-width: 1200px) {
    .margin-top-104px-largeScreen {
      margin-top: 104px !important; } }
  @media print {
    .margin-top-104px-print {
      margin-top: 104px !important; } }

.margin-bottom-104px {
  margin-bottom: 104px !important; }
  @media (max-width: 767px) {
    .margin-bottom-104px-mobile {
      margin-bottom: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-104px-tablet {
      margin-bottom: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-104px-computer {
      margin-bottom: 104px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-104px-largeScreen {
      margin-bottom: 104px !important; } }
  @media print {
    .margin-bottom-104px-print {
      margin-bottom: 104px !important; } }

.margin-left-104px {
  margin-left: 104px !important; }
  @media (max-width: 767px) {
    .margin-left-104px-mobile {
      margin-left: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-104px-tablet {
      margin-left: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-104px-computer {
      margin-left: 104px !important; } }
  @media (min-width: 1200px) {
    .margin-left-104px-largeScreen {
      margin-left: 104px !important; } }
  @media print {
    .margin-left-104px-print {
      margin-left: 104px !important; } }

.margin-right-104px {
  margin-right: 104px !important; }
  @media (max-width: 767px) {
    .margin-right-104px-mobile {
      margin-right: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-104px-tablet {
      margin-right: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-104px-computer {
      margin-right: 104px !important; } }
  @media (min-width: 1200px) {
    .margin-right-104px-largeScreen {
      margin-right: 104px !important; } }
  @media print {
    .margin-right-104px-print {
      margin-right: 104px !important; } }

.margin-tb-104px {
  margin-top: 104px !important;
  margin-bottom: 104px !important; }
  @media (max-width: 767px) {
    .margin-tb-104px-mobile {
      margin-top: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-104px-tablet {
      margin-top: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-104px-computer {
      margin-top: 104px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-104px-largeScreen {
      margin-top: 104px !important; } }
  @media print {
    .margin-tb-104px-print {
      margin-top: 104px !important; } }
  @media (max-width: 767px) {
    .margin-tb-104px-mobile {
      margin-bottom: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-104px-tablet {
      margin-bottom: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-104px-computer {
      margin-bottom: 104px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-104px-largeScreen {
      margin-bottom: 104px !important; } }
  @media print {
    .margin-tb-104px-print {
      margin-bottom: 104px !important; } }

.margin-lr-104px {
  margin-left: 104px !important;
  margin-right: 104px !important; }
  @media (max-width: 767px) {
    .margin-lr-104px-mobile {
      margin-left: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-104px-tablet {
      margin-left: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-104px-computer {
      margin-left: 104px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-104px-largeScreen {
      margin-left: 104px !important; } }
  @media print {
    .margin-lr-104px-print {
      margin-left: 104px !important; } }
  @media (max-width: 767px) {
    .margin-lr-104px-mobile {
      margin-right: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-104px-tablet {
      margin-right: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-104px-computer {
      margin-right: 104px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-104px-largeScreen {
      margin-right: 104px !important; } }
  @media print {
    .margin-lr-104px-print {
      margin-right: 104px !important; } }

/* Negative Margin*/
.neg-margin-104px {
  margin: 104px !important; }
  @media (max-width: 767px) {
    .neg-margin-104px-mobile {
      margin: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-104px-tablet {
      margin: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-104px-computer {
      margin: 104px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-104px-largeScreen {
      margin: 104px !important; } }
  @media print {
    .neg-margin-104px-print {
      margin: 104px !important; } }

.neg-margin-top-104px {
  margin-top: 104px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-104px-mobile {
      margin-top: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-104px-tablet {
      margin-top: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-104px-computer {
      margin-top: 104px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-104px-largeScreen {
      margin-top: 104px !important; } }
  @media print {
    .neg-margin-top-104px-print {
      margin-top: 104px !important; } }

.neg-margin-bottom-104px {
  margin-bottom: 104px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-104px-mobile {
      margin-bottom: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-104px-tablet {
      margin-bottom: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-104px-computer {
      margin-bottom: 104px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-104px-largeScreen {
      margin-bottom: 104px !important; } }
  @media print {
    .neg-margin-bottom-104px-print {
      margin-bottom: 104px !important; } }

.neg-margin-left-104px {
  margin-left: 104px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-104px-mobile {
      margin-left: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-104px-tablet {
      margin-left: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-104px-computer {
      margin-left: 104px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-104px-largeScreen {
      margin-left: 104px !important; } }
  @media print {
    .neg-margin-left-104px-print {
      margin-left: 104px !important; } }

.neg-margin-right-104px {
  margin-right: 104px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-104px-mobile {
      margin-right: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-104px-tablet {
      margin-right: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-104px-computer {
      margin-right: 104px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-104px-largeScreen {
      margin-right: 104px !important; } }
  @media print {
    .neg-margin-right-104px-print {
      margin-right: 104px !important; } }

.neg-margin-tb-104px {
  margin-top: 104px !important;
  margin-bottom: 104px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-104px-mobile {
      margin-top: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-104px-tablet {
      margin-top: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-104px-computer {
      margin-top: 104px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-104px-largeScreen {
      margin-top: 104px !important; } }
  @media print {
    .neg-margin-tb-104px-print {
      margin-top: 104px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-104px-mobile {
      margin-bottom: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-104px-tablet {
      margin-bottom: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-104px-computer {
      margin-bottom: 104px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-104px-largeScreen {
      margin-bottom: 104px !important; } }
  @media print {
    .neg-margin-tb-104px-print {
      margin-bottom: 104px !important; } }

.neg-margin-lr-104px {
  margin-left: 104px !important;
  margin-right: 104px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-104px-mobile {
      margin-left: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-104px-tablet {
      margin-left: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-104px-computer {
      margin-left: 104px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-104px-largeScreen {
      margin-left: 104px !important; } }
  @media print {
    .neg-margin-lr-104px-print {
      margin-left: 104px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-104px-mobile {
      margin-right: 104px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-104px-tablet {
      margin-right: 104px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-104px-computer {
      margin-right: 104px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-104px-largeScreen {
      margin-right: 104px !important; } }
  @media print {
    .neg-margin-lr-104px-print {
      margin-right: 104px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-112px {
  padding: 112px !important; }
  @media (max-width: 767px) {
    .padding-112px-mobile {
      padding: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-112px-tablet {
      padding: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-112px-computer {
      padding: 112px !important; } }
  @media (min-width: 1200px) {
    .padding-112px-largeScreen {
      padding: 112px !important; } }
  @media print {
    .padding-112px-print {
      padding: 112px !important; } }

.padding-top-112px {
  padding-top: 112px !important; }
  @media (max-width: 767px) {
    .padding-top-112px-mobile {
      padding-top: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-112px-tablet {
      padding-top: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-112px-computer {
      padding-top: 112px !important; } }
  @media (min-width: 1200px) {
    .padding-top-112px-largeScreen {
      padding-top: 112px !important; } }
  @media print {
    .padding-top-112px-print {
      padding-top: 112px !important; } }

.padding-bottom-112px {
  padding-bottom: 112px !important; }
  @media (max-width: 767px) {
    .padding-bottom-112px-mobile {
      padding-bottom: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-112px-tablet {
      padding-bottom: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-112px-computer {
      padding-bottom: 112px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-112px-largeScreen {
      padding-bottom: 112px !important; } }
  @media print {
    .padding-bottom-112px-print {
      padding-bottom: 112px !important; } }

.padding-left-112px {
  padding-left: 112px !important; }
  @media (max-width: 767px) {
    .padding-left-112px-mobile {
      padding-left: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-112px-tablet {
      padding-left: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-112px-computer {
      padding-left: 112px !important; } }
  @media (min-width: 1200px) {
    .padding-left-112px-largeScreen {
      padding-left: 112px !important; } }
  @media print {
    .padding-left-112px-print {
      padding-left: 112px !important; } }

.padding-right-112px {
  padding-right: 112px !important; }
  @media (max-width: 767px) {
    .padding-right-112px-mobile {
      padding-right: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-112px-tablet {
      padding-right: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-112px-computer {
      padding-right: 112px !important; } }
  @media (min-width: 1200px) {
    .padding-right-112px-largeScreen {
      padding-right: 112px !important; } }
  @media print {
    .padding-right-112px-print {
      padding-right: 112px !important; } }

.padding-tb-112px {
  padding-top: 112px !important;
  padding-bottom: 112px !important; }
  @media (max-width: 767px) {
    .padding-tb-112px-mobile {
      padding-top: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-112px-tablet {
      padding-top: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-112px-computer {
      padding-top: 112px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-112px-largeScreen {
      padding-top: 112px !important; } }
  @media print {
    .padding-tb-112px-print {
      padding-top: 112px !important; } }
  @media (max-width: 767px) {
    .padding-tb-112px-mobile {
      padding-bottom: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-112px-tablet {
      padding-bottom: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-112px-computer {
      padding-bottom: 112px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-112px-largeScreen {
      padding-bottom: 112px !important; } }
  @media print {
    .padding-tb-112px-print {
      padding-bottom: 112px !important; } }

.padding-lr-112px {
  padding-left: 112px !important;
  padding-right: 112px !important; }
  @media (max-width: 767px) {
    .padding-lr-112px-mobile {
      padding-left: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-112px-tablet {
      padding-left: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-112px-computer {
      padding-left: 112px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-112px-largeScreen {
      padding-left: 112px !important; } }
  @media print {
    .padding-lr-112px-print {
      padding-left: 112px !important; } }
  @media (max-width: 767px) {
    .padding-lr-112px-mobile {
      padding-right: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-112px-tablet {
      padding-right: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-112px-computer {
      padding-right: 112px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-112px-largeScreen {
      padding-right: 112px !important; } }
  @media print {
    .padding-lr-112px-print {
      padding-right: 112px !important; } }

.margin-112px {
  margin: 112px !important; }
  @media (max-width: 767px) {
    .margin-112px-mobile {
      margin: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-112px-tablet {
      margin: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-112px-computer {
      margin: 112px !important; } }
  @media (min-width: 1200px) {
    .margin-112px-largeScreen {
      margin: 112px !important; } }
  @media print {
    .margin-112px-print {
      margin: 112px !important; } }

.margin-top-112px {
  margin-top: 112px !important; }
  @media (max-width: 767px) {
    .margin-top-112px-mobile {
      margin-top: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-112px-tablet {
      margin-top: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-112px-computer {
      margin-top: 112px !important; } }
  @media (min-width: 1200px) {
    .margin-top-112px-largeScreen {
      margin-top: 112px !important; } }
  @media print {
    .margin-top-112px-print {
      margin-top: 112px !important; } }

.margin-bottom-112px {
  margin-bottom: 112px !important; }
  @media (max-width: 767px) {
    .margin-bottom-112px-mobile {
      margin-bottom: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-112px-tablet {
      margin-bottom: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-112px-computer {
      margin-bottom: 112px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-112px-largeScreen {
      margin-bottom: 112px !important; } }
  @media print {
    .margin-bottom-112px-print {
      margin-bottom: 112px !important; } }

.margin-left-112px {
  margin-left: 112px !important; }
  @media (max-width: 767px) {
    .margin-left-112px-mobile {
      margin-left: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-112px-tablet {
      margin-left: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-112px-computer {
      margin-left: 112px !important; } }
  @media (min-width: 1200px) {
    .margin-left-112px-largeScreen {
      margin-left: 112px !important; } }
  @media print {
    .margin-left-112px-print {
      margin-left: 112px !important; } }

.margin-right-112px {
  margin-right: 112px !important; }
  @media (max-width: 767px) {
    .margin-right-112px-mobile {
      margin-right: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-112px-tablet {
      margin-right: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-112px-computer {
      margin-right: 112px !important; } }
  @media (min-width: 1200px) {
    .margin-right-112px-largeScreen {
      margin-right: 112px !important; } }
  @media print {
    .margin-right-112px-print {
      margin-right: 112px !important; } }

.margin-tb-112px {
  margin-top: 112px !important;
  margin-bottom: 112px !important; }
  @media (max-width: 767px) {
    .margin-tb-112px-mobile {
      margin-top: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-112px-tablet {
      margin-top: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-112px-computer {
      margin-top: 112px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-112px-largeScreen {
      margin-top: 112px !important; } }
  @media print {
    .margin-tb-112px-print {
      margin-top: 112px !important; } }
  @media (max-width: 767px) {
    .margin-tb-112px-mobile {
      margin-bottom: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-112px-tablet {
      margin-bottom: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-112px-computer {
      margin-bottom: 112px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-112px-largeScreen {
      margin-bottom: 112px !important; } }
  @media print {
    .margin-tb-112px-print {
      margin-bottom: 112px !important; } }

.margin-lr-112px {
  margin-left: 112px !important;
  margin-right: 112px !important; }
  @media (max-width: 767px) {
    .margin-lr-112px-mobile {
      margin-left: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-112px-tablet {
      margin-left: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-112px-computer {
      margin-left: 112px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-112px-largeScreen {
      margin-left: 112px !important; } }
  @media print {
    .margin-lr-112px-print {
      margin-left: 112px !important; } }
  @media (max-width: 767px) {
    .margin-lr-112px-mobile {
      margin-right: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-112px-tablet {
      margin-right: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-112px-computer {
      margin-right: 112px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-112px-largeScreen {
      margin-right: 112px !important; } }
  @media print {
    .margin-lr-112px-print {
      margin-right: 112px !important; } }

/* Negative Margin*/
.neg-margin-112px {
  margin: 112px !important; }
  @media (max-width: 767px) {
    .neg-margin-112px-mobile {
      margin: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-112px-tablet {
      margin: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-112px-computer {
      margin: 112px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-112px-largeScreen {
      margin: 112px !important; } }
  @media print {
    .neg-margin-112px-print {
      margin: 112px !important; } }

.neg-margin-top-112px {
  margin-top: 112px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-112px-mobile {
      margin-top: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-112px-tablet {
      margin-top: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-112px-computer {
      margin-top: 112px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-112px-largeScreen {
      margin-top: 112px !important; } }
  @media print {
    .neg-margin-top-112px-print {
      margin-top: 112px !important; } }

.neg-margin-bottom-112px {
  margin-bottom: 112px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-112px-mobile {
      margin-bottom: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-112px-tablet {
      margin-bottom: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-112px-computer {
      margin-bottom: 112px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-112px-largeScreen {
      margin-bottom: 112px !important; } }
  @media print {
    .neg-margin-bottom-112px-print {
      margin-bottom: 112px !important; } }

.neg-margin-left-112px {
  margin-left: 112px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-112px-mobile {
      margin-left: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-112px-tablet {
      margin-left: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-112px-computer {
      margin-left: 112px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-112px-largeScreen {
      margin-left: 112px !important; } }
  @media print {
    .neg-margin-left-112px-print {
      margin-left: 112px !important; } }

.neg-margin-right-112px {
  margin-right: 112px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-112px-mobile {
      margin-right: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-112px-tablet {
      margin-right: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-112px-computer {
      margin-right: 112px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-112px-largeScreen {
      margin-right: 112px !important; } }
  @media print {
    .neg-margin-right-112px-print {
      margin-right: 112px !important; } }

.neg-margin-tb-112px {
  margin-top: 112px !important;
  margin-bottom: 112px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-112px-mobile {
      margin-top: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-112px-tablet {
      margin-top: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-112px-computer {
      margin-top: 112px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-112px-largeScreen {
      margin-top: 112px !important; } }
  @media print {
    .neg-margin-tb-112px-print {
      margin-top: 112px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-112px-mobile {
      margin-bottom: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-112px-tablet {
      margin-bottom: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-112px-computer {
      margin-bottom: 112px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-112px-largeScreen {
      margin-bottom: 112px !important; } }
  @media print {
    .neg-margin-tb-112px-print {
      margin-bottom: 112px !important; } }

.neg-margin-lr-112px {
  margin-left: 112px !important;
  margin-right: 112px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-112px-mobile {
      margin-left: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-112px-tablet {
      margin-left: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-112px-computer {
      margin-left: 112px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-112px-largeScreen {
      margin-left: 112px !important; } }
  @media print {
    .neg-margin-lr-112px-print {
      margin-left: 112px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-112px-mobile {
      margin-right: 112px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-112px-tablet {
      margin-right: 112px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-112px-computer {
      margin-right: 112px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-112px-largeScreen {
      margin-right: 112px !important; } }
  @media print {
    .neg-margin-lr-112px-print {
      margin-right: 112px !important; } }

/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
.padding-120px {
  padding: 120px !important; }
  @media (max-width: 767px) {
    .padding-120px-mobile {
      padding: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-120px-tablet {
      padding: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-120px-computer {
      padding: 120px !important; } }
  @media (min-width: 1200px) {
    .padding-120px-largeScreen {
      padding: 120px !important; } }
  @media print {
    .padding-120px-print {
      padding: 120px !important; } }

.padding-top-120px {
  padding-top: 120px !important; }
  @media (max-width: 767px) {
    .padding-top-120px-mobile {
      padding-top: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-top-120px-tablet {
      padding-top: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-top-120px-computer {
      padding-top: 120px !important; } }
  @media (min-width: 1200px) {
    .padding-top-120px-largeScreen {
      padding-top: 120px !important; } }
  @media print {
    .padding-top-120px-print {
      padding-top: 120px !important; } }

.padding-bottom-120px {
  padding-bottom: 120px !important; }
  @media (max-width: 767px) {
    .padding-bottom-120px-mobile {
      padding-bottom: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-bottom-120px-tablet {
      padding-bottom: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-bottom-120px-computer {
      padding-bottom: 120px !important; } }
  @media (min-width: 1200px) {
    .padding-bottom-120px-largeScreen {
      padding-bottom: 120px !important; } }
  @media print {
    .padding-bottom-120px-print {
      padding-bottom: 120px !important; } }

.padding-left-120px {
  padding-left: 120px !important; }
  @media (max-width: 767px) {
    .padding-left-120px-mobile {
      padding-left: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-left-120px-tablet {
      padding-left: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-left-120px-computer {
      padding-left: 120px !important; } }
  @media (min-width: 1200px) {
    .padding-left-120px-largeScreen {
      padding-left: 120px !important; } }
  @media print {
    .padding-left-120px-print {
      padding-left: 120px !important; } }

.padding-right-120px {
  padding-right: 120px !important; }
  @media (max-width: 767px) {
    .padding-right-120px-mobile {
      padding-right: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-right-120px-tablet {
      padding-right: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-right-120px-computer {
      padding-right: 120px !important; } }
  @media (min-width: 1200px) {
    .padding-right-120px-largeScreen {
      padding-right: 120px !important; } }
  @media print {
    .padding-right-120px-print {
      padding-right: 120px !important; } }

.padding-tb-120px {
  padding-top: 120px !important;
  padding-bottom: 120px !important; }
  @media (max-width: 767px) {
    .padding-tb-120px-mobile {
      padding-top: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-120px-tablet {
      padding-top: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-120px-computer {
      padding-top: 120px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-120px-largeScreen {
      padding-top: 120px !important; } }
  @media print {
    .padding-tb-120px-print {
      padding-top: 120px !important; } }
  @media (max-width: 767px) {
    .padding-tb-120px-mobile {
      padding-bottom: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-tb-120px-tablet {
      padding-bottom: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-tb-120px-computer {
      padding-bottom: 120px !important; } }
  @media (min-width: 1200px) {
    .padding-tb-120px-largeScreen {
      padding-bottom: 120px !important; } }
  @media print {
    .padding-tb-120px-print {
      padding-bottom: 120px !important; } }

.padding-lr-120px {
  padding-left: 120px !important;
  padding-right: 120px !important; }
  @media (max-width: 767px) {
    .padding-lr-120px-mobile {
      padding-left: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-120px-tablet {
      padding-left: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-120px-computer {
      padding-left: 120px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-120px-largeScreen {
      padding-left: 120px !important; } }
  @media print {
    .padding-lr-120px-print {
      padding-left: 120px !important; } }
  @media (max-width: 767px) {
    .padding-lr-120px-mobile {
      padding-right: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .padding-lr-120px-tablet {
      padding-right: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .padding-lr-120px-computer {
      padding-right: 120px !important; } }
  @media (min-width: 1200px) {
    .padding-lr-120px-largeScreen {
      padding-right: 120px !important; } }
  @media print {
    .padding-lr-120px-print {
      padding-right: 120px !important; } }

.margin-120px {
  margin: 120px !important; }
  @media (max-width: 767px) {
    .margin-120px-mobile {
      margin: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-120px-tablet {
      margin: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-120px-computer {
      margin: 120px !important; } }
  @media (min-width: 1200px) {
    .margin-120px-largeScreen {
      margin: 120px !important; } }
  @media print {
    .margin-120px-print {
      margin: 120px !important; } }

.margin-top-120px {
  margin-top: 120px !important; }
  @media (max-width: 767px) {
    .margin-top-120px-mobile {
      margin-top: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-top-120px-tablet {
      margin-top: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-top-120px-computer {
      margin-top: 120px !important; } }
  @media (min-width: 1200px) {
    .margin-top-120px-largeScreen {
      margin-top: 120px !important; } }
  @media print {
    .margin-top-120px-print {
      margin-top: 120px !important; } }

.margin-bottom-120px {
  margin-bottom: 120px !important; }
  @media (max-width: 767px) {
    .margin-bottom-120px-mobile {
      margin-bottom: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-bottom-120px-tablet {
      margin-bottom: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-bottom-120px-computer {
      margin-bottom: 120px !important; } }
  @media (min-width: 1200px) {
    .margin-bottom-120px-largeScreen {
      margin-bottom: 120px !important; } }
  @media print {
    .margin-bottom-120px-print {
      margin-bottom: 120px !important; } }

.margin-left-120px {
  margin-left: 120px !important; }
  @media (max-width: 767px) {
    .margin-left-120px-mobile {
      margin-left: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-left-120px-tablet {
      margin-left: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-left-120px-computer {
      margin-left: 120px !important; } }
  @media (min-width: 1200px) {
    .margin-left-120px-largeScreen {
      margin-left: 120px !important; } }
  @media print {
    .margin-left-120px-print {
      margin-left: 120px !important; } }

.margin-right-120px {
  margin-right: 120px !important; }
  @media (max-width: 767px) {
    .margin-right-120px-mobile {
      margin-right: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-right-120px-tablet {
      margin-right: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-right-120px-computer {
      margin-right: 120px !important; } }
  @media (min-width: 1200px) {
    .margin-right-120px-largeScreen {
      margin-right: 120px !important; } }
  @media print {
    .margin-right-120px-print {
      margin-right: 120px !important; } }

.margin-tb-120px {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }
  @media (max-width: 767px) {
    .margin-tb-120px-mobile {
      margin-top: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-120px-tablet {
      margin-top: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-120px-computer {
      margin-top: 120px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-120px-largeScreen {
      margin-top: 120px !important; } }
  @media print {
    .margin-tb-120px-print {
      margin-top: 120px !important; } }
  @media (max-width: 767px) {
    .margin-tb-120px-mobile {
      margin-bottom: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-tb-120px-tablet {
      margin-bottom: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-tb-120px-computer {
      margin-bottom: 120px !important; } }
  @media (min-width: 1200px) {
    .margin-tb-120px-largeScreen {
      margin-bottom: 120px !important; } }
  @media print {
    .margin-tb-120px-print {
      margin-bottom: 120px !important; } }

.margin-lr-120px {
  margin-left: 120px !important;
  margin-right: 120px !important; }
  @media (max-width: 767px) {
    .margin-lr-120px-mobile {
      margin-left: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-120px-tablet {
      margin-left: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-120px-computer {
      margin-left: 120px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-120px-largeScreen {
      margin-left: 120px !important; } }
  @media print {
    .margin-lr-120px-print {
      margin-left: 120px !important; } }
  @media (max-width: 767px) {
    .margin-lr-120px-mobile {
      margin-right: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .margin-lr-120px-tablet {
      margin-right: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .margin-lr-120px-computer {
      margin-right: 120px !important; } }
  @media (min-width: 1200px) {
    .margin-lr-120px-largeScreen {
      margin-right: 120px !important; } }
  @media print {
    .margin-lr-120px-print {
      margin-right: 120px !important; } }

/* Negative Margin*/
.neg-margin-120px {
  margin: 120px !important; }
  @media (max-width: 767px) {
    .neg-margin-120px-mobile {
      margin: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-120px-tablet {
      margin: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-120px-computer {
      margin: 120px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-120px-largeScreen {
      margin: 120px !important; } }
  @media print {
    .neg-margin-120px-print {
      margin: 120px !important; } }

.neg-margin-top-120px {
  margin-top: 120px !important; }
  @media (max-width: 767px) {
    .neg-margin-top-120px-mobile {
      margin-top: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-top-120px-tablet {
      margin-top: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-top-120px-computer {
      margin-top: 120px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-top-120px-largeScreen {
      margin-top: 120px !important; } }
  @media print {
    .neg-margin-top-120px-print {
      margin-top: 120px !important; } }

.neg-margin-bottom-120px {
  margin-bottom: 120px !important; }
  @media (max-width: 767px) {
    .neg-margin-bottom-120px-mobile {
      margin-bottom: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-bottom-120px-tablet {
      margin-bottom: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-bottom-120px-computer {
      margin-bottom: 120px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-bottom-120px-largeScreen {
      margin-bottom: 120px !important; } }
  @media print {
    .neg-margin-bottom-120px-print {
      margin-bottom: 120px !important; } }

.neg-margin-left-120px {
  margin-left: 120px !important; }
  @media (max-width: 767px) {
    .neg-margin-left-120px-mobile {
      margin-left: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-left-120px-tablet {
      margin-left: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-left-120px-computer {
      margin-left: 120px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-left-120px-largeScreen {
      margin-left: 120px !important; } }
  @media print {
    .neg-margin-left-120px-print {
      margin-left: 120px !important; } }

.neg-margin-right-120px {
  margin-right: 120px !important; }
  @media (max-width: 767px) {
    .neg-margin-right-120px-mobile {
      margin-right: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-right-120px-tablet {
      margin-right: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-right-120px-computer {
      margin-right: 120px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-right-120px-largeScreen {
      margin-right: 120px !important; } }
  @media print {
    .neg-margin-right-120px-print {
      margin-right: 120px !important; } }

.neg-margin-tb-120px {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }
  @media (max-width: 767px) {
    .neg-margin-tb-120px-mobile {
      margin-top: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-120px-tablet {
      margin-top: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-120px-computer {
      margin-top: 120px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-120px-largeScreen {
      margin-top: 120px !important; } }
  @media print {
    .neg-margin-tb-120px-print {
      margin-top: 120px !important; } }
  @media (max-width: 767px) {
    .neg-margin-tb-120px-mobile {
      margin-bottom: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-tb-120px-tablet {
      margin-bottom: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-tb-120px-computer {
      margin-bottom: 120px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-tb-120px-largeScreen {
      margin-bottom: 120px !important; } }
  @media print {
    .neg-margin-tb-120px-print {
      margin-bottom: 120px !important; } }

.neg-margin-lr-120px {
  margin-left: 120px !important;
  margin-right: 120px !important; }
  @media (max-width: 767px) {
    .neg-margin-lr-120px-mobile {
      margin-left: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-120px-tablet {
      margin-left: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-120px-computer {
      margin-left: 120px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-120px-largeScreen {
      margin-left: 120px !important; } }
  @media print {
    .neg-margin-lr-120px-print {
      margin-left: 120px !important; } }
  @media (max-width: 767px) {
    .neg-margin-lr-120px-mobile {
      margin-right: 120px !important; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .neg-margin-lr-120px-tablet {
      margin-right: 120px !important; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .neg-margin-lr-120px-computer {
      margin-right: 120px !important; } }
  @media (min-width: 1200px) {
    .neg-margin-lr-120px-largeScreen {
      margin-right: 120px !important; } }
  @media print {
    .neg-margin-lr-120px-print {
      margin-right: 120px !important; } }

/******************************************** Custom Stylesheets ********************************************/
html {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #2b2e3f;
  line-height: 1.625;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.h0, h1, h2, h3, h4, h5, h6 {
  font-family: "Alike", serif;
  font-weight: 400;
  line-height: 1;
  margin: 0; }

h5, h6 {
  font-family: "Open Sans", sans-serif; }

p {
  font-size: 1.5rem; }

caption {
  font-size: 10.24px; }

a, a:hover, a:focus, a.active {
  color: #2b2e3f; }

i.icon {
  font-size: 1.5rem; }

.pSelladurai-logo:hover path {
  fill: url(#cover_img); }

.fill-primaryColor path {
  fill: #2b2e3f; }

.fill-secondaryColor path {
  fill: #fcf8f3; }

@font-face {
  font-family: "piri_iconfont";
  src: url(../../static/media/piri_iconfont.15e81c4d.eot);
  src: url(../../static/media/piri_iconfont.15e81c4d.eot#iefix) format("embedded-opentype"), url(../../static/media/piri_iconfont.193a3257.ttf) format("truetype"), url(../../static/media/piri_iconfont.41d4d0c8.woff) format("woff"), url(../../static/media/piri_iconfont.cdac7236.svg#piri_iconfont) format("svg");
  font-weight: normal;
  font-style: normal; }

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "piri_iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.close:before {
  content: "\E900"; }

.clear:before {
  content: "\E900"; }

.remove:before {
  content: "\E900"; }

.location:before {
  content: "\E903"; }

.gps:before {
  content: "\E903"; }

.gps-marker:before {
  content: "\E903"; }

.no-location:before {
  content: "\E904"; }

.no-gps:before {
  content: "\E904"; }

.no-gps-marker:before {
  content: "\E904"; }

.arrow-left:before {
  content: "\E902"; }

.arrow-right:before {
  content: "\E901"; }

