$icomoon-font-family: "piri_iconfont" !default;
$icomoon-font-path: "../assets/fonts/iconfont/fonts" !default;

$close: "\e900";
$clear: "\e900";
$remove: "\e900";
$location: "\e903";
$gps: "\e903";
$gps-marker: "\e903";
$no-location: "\e904";
$no-gps: "\e904";
$no-gps-marker: "\e904";
$arrow-left: "\e902";
$arrow-right: "\e901";

