@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
//@import url('https://fonts.googleapis.com/css?family=Cutive+Mono');
//@import url('https://fonts.googleapis.com/css?family=Arapey|Lora:400,400i,700,700i|Old+Standard+TT|Open+Sans:400,600,700');
//@import url('https://fonts.googleapis.com/css?family=Arapey');
@import url('https://fonts.googleapis.com/css?family=Alike');
// @import url('https://fonts.googleapis.com/css?family=Playfair+Display');

//Title logo font
@import url('https://fonts.googleapis.com/css?family=Sacramento');


@import 'settings';
@import 'color';
@import 'mixins';

html{
  font-family: $fontFamilyBase;
  font-size: $body;
  font-weight: 400;
  color: $txtColor;
  line-height: $baseLineHeight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h0, h1, h2, h3, h4, h5, h6 {
	font-family: $fontFamilyHeadline;
	font-weight: 400;
	line-height: 1;
	margin: 0;
}
h5, h6{ font-family: $fontFamilyBase; }

p{ font-size: 1.5rem; }

caption{ font-size: $caption}

a, a:hover, a:focus, a.active{ color: $txtColor }

i.icon{
  font-size: 1.5rem;
}