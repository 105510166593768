@import 'settings';
@import 'color';
@import 'mixins';

.pSelladurai-logo:hover path{
  fill: url(#cover_img)
}

.fill-primaryColor path{
  fill: $primaryColor
}

.fill-secondaryColor path{
  fill: $secondaryColor;
}