@import "settings";
@import "color";
@import "mixins";

*{ @include transition(all 0.2s ease-in-out); }
*:focus, *:active{ outline: none; }

*, *:before, *:after { box-sizing: border-box; }

html{
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}
body{
	height: 100%;
	width: 100%;
	background-color: $bgColor;
}

/* Float */
.float{
	&.left{ float: left; }
	&.right{ float: right; }
}

/* Resetting Padding and Margin*/
.clear-{
	&padding{
    padding: 0 !important;
		&-left{ padding-left: 0 !important}
		&-right{ padding-right: 0 !important}
		&-top{ padding-top: 0 !important}
		&-bottom{ padding-bottom: 0 !important}
		&-tb{ padding-top:0 !important; padding-bottom: 0 !important}
		&-lr{ padding-left:0 !important; padding-right: 0 !important}
	}
	&margin{
    margin: 0 !important;
		&-left{ margin-left: 0 !important}
		&-right{ margin-right: 0 !important}
		&-top{ margin-top: 0 !important}
		&-bottom{ margin-bottom: 0 !important}
		&-tb{ margin-top:0 !important; margin-bottom: 0 !important}
		&-lr{ margin-left:0 !important; margin-right: 0 !important}
	}
}

@each $dimmension in $spacing{
	/* Margin, Padding e.g. -> padding-top-16px | padding-tb-16px-mobile */
	@each $spaceType in $spacing-type{
		.#{$spaceType}-#{$dimmension}{
			#{$spaceType}: $dimmension !important;
			@each $breakpoint, $breakpointVal in $media-queries{
				&-#{$breakpoint}{ @include media-query($breakpoint){ #{$spaceType}: $dimmension !important; } }
			}
		}
		@each $spaceDir, $spaceDirVal in $spacing-directions{
			.#{$spaceType}-#{$spaceDir}-#{$dimmension}{
				@each $spaceDirValue in $spaceDirVal{
					#{$spaceType}-#{$spaceDirValue}: $dimmension !important;
					@each $breakpoint, $breakpointVal in $media-queries{
						&-#{$breakpoint}{ @include media-query($breakpoint){ #{$spaceType}-#{$spaceDirValue}: $dimmension !important; } }
					}
				}
			}
		}
	}
	/* Negative Margin*/
	@each $spaceType in $spacing-type{
		@if $spaceType == margin{
			.neg-{
				&#{$spaceType}-#{$dimmension}{
					#{$spaceType}: $dimmension !important;
					@each $breakpoint, $breakpointVal in $media-queries{
						&-#{$breakpoint}{ @include media-query($breakpoint){ #{$spaceType}: $dimmension !important; } }
					}
				}
				@each $spaceDir, $spaceDirVal in $spacing-directions{
					&#{$spaceType}-#{$spaceDir}-#{$dimmension}{
						@each $spaceDirValue in $spaceDirVal{
							#{$spaceType}-#{$spaceDirValue}: $dimmension !important;
							@each $breakpoint, $breakpointVal in $media-queries{
								&-#{$breakpoint}{ @include media-query($breakpoint){ #{$spaceType}-#{$spaceDirValue}: $dimmension !important; } }
							}
						}
					}
				}
			}
		}
	}
}

/******************************************** Custom Stylesheets ********************************************/
@import 'typography.scss';
@import 'svg.scss';
@import '../assets/fonts/iconfont/style.scss';
