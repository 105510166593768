@import './../../styles/settings';
@import './../../styles/color';
@import './../../styles/mixins';

.main-container {
	//padding: 0 $grid*3;
	&.invert{
		background-color: $primaryColor;
		color: $secondaryColor !important;
		a, a:hover, a:focus, a.active{
			color: $secondaryColor;
		}
	}
}
