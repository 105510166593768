@import '../../styles/settings';
@import '../../styles/color';
@import '../../styles/mixins';

$bgImg: "../../assets/img/bg/piriArtworkBg.jpg";
//$bgImgBW: "../../assets/img/bg/piriArtworkBg_bw.jpg";

.home-cover {
	height: 100vh;
	width: 100vw;
	background-image: url($bgImg);
	background-attachment: fixed;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;

	.section-container.header {
		//padding-top: calc(50vh - ((#{$grid}*26)/2));
		height: 100vh;

		.cover-bg-content {
			svg{
			position: absolute;
			//top: calc(50% - ((#{$grid}*26) / 2));
			top: 50%;
			left: 50%;
			margin-right: -50%;
			transform: translate(-50%, -50%);
		}
			.stripps {
				div {
					height: $grid*5;
					width: 100%;
					background-color: rgba($bgColor, 0.8);
					margin-bottom: $grid*2;
				}

				span.txt {
					width: 100%;
					position: relative;
					@include flexbox;
					@include flex-direction(column);
					@include flex-shrink(2);
					margin-bottom: -($grid*26);

					span {
						height: $grid*12;

						&.left {
							@include align-self(flex-start);
						}

						&.right {
							@include align-self(flex-end);
							margin-top: $grid*2;
						}

						h1 {
							font-family: $fontFamilyBase;
							text-transform: uppercase;
							color: rgba($bgColor, 0.8);
							width: auto;
							padding: 0 $grid*3;
							margin: 0;
							line-height: ($grid*12)-6;
							height: $grid*12;
							background: url($bgImg) no-repeat top center fixed;
							background-size: cover;

							&.left {
								padding-left: 0;
							}

							&.right {
								padding-right: 0;
							}
						}
					}
				}
			}
		}
	}

	&.switchBgToDeco {
		background: $bgColor;

		.stripps {
			div {
				background: url($bgImg) no-repeat top center fixed;
				background-size: cover;
			}

			span.txt {
				span {
					background-color: $bgColor;

					h1 {
						-webkit-text-fill-color: transparent;
						-webkit-background-clip: text !important;
						background-size: unset !important;
						color: $txtColor;
						position: relative;
						z-index: 3;
					}
				}
			}
		}
	}

}
.home-content{
	
	.section-container.about {
		height: 100vh;

		.super-title {

			width: $grid*38;
			height: $grid*38;
			//border-radius: 50%;
			position: relative;
			overflow: hidden;
			top: $grid*15;
			transform: rotate(-45deg);
			border: $grid*0.5 solid $primaryColor;
			//border-radius: $grid*0.5;

	
			&::before{
				content: "";
				position: absolute;
				width: 200%;
				height: 200%;
				top: -50%;
				left: -50%;
				z-index: -1;
				background: url($bgImg) no-repeat top center fixed;
				background-size: cover;
				transform: rotate(45deg);
			}
			
			svg{
				transform: rotate(45deg);
				margin-left: -($grid*10);
				margin-right: ($grid*5);
				//margin: $grid*2.5;
				//padding-top: $grid*10;
				//padding-right: $grid*10;
				//max-width: $grid*40;
				//max-height: $grid*40;
				//border: $grid*2.5 solid transparent;
				//border-radius: 50%;
				//margin: $grid*2.5;
			}

			.h0 {
				color: $primaryColor;
				line-height: $grid*40;
				display: block;
				text-align: center;
			}
		}

		.content {
			position: relative;
			top: $grid*28;
			max-width: 55em;
			margin-left: auto;
		}
	}

	.section-container.experience, .section-container.education, .section-container.skills{
		min-height: 100vh;

		.home-item {
			@include flexbox;
			@include flex-direction(row);
			width: 100%;
			margin-bottom: $grid*5;

			.home-section-title {
				margin-top: $grid/2;

				div {
					height: $grid/4;
					width: 1em;
					margin-top: $grid;
					background-color: $primaryColor;
				}
			}

			.home-content {
			}
		}
	}
}