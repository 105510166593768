@import 'modularscale';

//--- Media Query
$media-queries: (
  mobile: ( breakpoint: "(max-width: 767px)" ),
  tablet: ( breakpoint: "(min-width: 768px) and (max-width: 991px)" ),
  computer: ( breakpoint: "(min-width: 992px) and (max-width: 1199px)" ),
  largeScreen: ( breakpoint: "(min-width: 1200px)" ),
  print: ( breakpoint: "print" )
);

//--- Grid
$grid: 8px;

//--- Layout
$baseLayoutWidth: 1280px;

//--- Font
//$fontFamilyHeadline: 'Cutive Mono', monospace;
//$fontFamilyHeadline: 'Arapey', serif;
$fontFamilyHeadline: 'Alike', serif;
//$fontFamilyHeadline: 'Playfair Display', serif;
$fontFamilyBase: 'Open Sans', sans-serif;
$fontFamilyLogoTitle: 'Sacramento', cursive;
$baseFontSize: $grid*2;
$baseLineHeight: 1.625;

// Font Size
$modularscale: (
  base: $baseFontSize,
  ratio: 1.25
);

$h0: ms(9);
$h1: ms(9);
$h2: ms(5);
$h3: ms(4);
$h4: ms(3);
$h5: ms(2);
$h6: ms(1);
$subtitle-1: ms(0);
$subtitle-2: ms(-1);
$body: ms(0);
$body-2: ms(-1);
$button: ms(-1);
$caption: ms(-2);
$overline: ms(-3);

//--- Spacing
$spacing-type: padding, margin;
$spacing-directions: (
  top: ( top ),
  bottom: ( bottom ),
  left: ( left ),
  right: ( right ),
  tb: ( top, bottom ),
  lr: ( left, right )
);
$spacing:
  ($grid/$grid)*1px,
  $grid*0.25,
  $grid*0.5,
  $grid*0.75,
  $grid,
  $grid*2,
  $grid*3,
  $grid*4,
  $grid*5,
  $grid*6,
  $grid*7,
  $grid*8,
  $grid*9,
  $grid*10,
  $grid*11,
  $grid*12,
  $grid*13,
  $grid*14,
  $grid*15;
