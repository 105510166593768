@import './../../../styles/settings';
@import './../../../styles/color';

span.h0.headline{ font-size: $h0; }
h1.headline{ 
	font-size: $h1;
	height: ($h1 * $baseLineHeight)*0.75;
}
h2.headline{ font-size: $h2; }
h3.headline{ font-size: $h3; }
h4.headline{ font-size: $h4; }
h5.headline{ font-size: $h5; }
h6.headline{ font-size: $h6; }
