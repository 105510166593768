@import '../../styles/settings';
@import '../../styles/color';
@import '../../styles/mixins';

.photo-section{
  min-height: 100vh;
  width: 100vw;
  padding-top: $grid*20 !important;
  padding-bottom: $grid*20 !important;

  img{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    &:hover{
      -webkit-filter: none;
      filter: none;
    }
  }

}