@import "icons";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.close {
  &:before {
    content: $close; 
  }
}
.clear {
  &:before {
    content: $clear; 
  }
}
.remove {
  &:before {
    content: $remove; 
  }
}
.location {
  &:before {
    content: $location; 
  }
}
.gps {
  &:before {
    content: $gps; 
  }
}
.gps-marker {
  &:before {
    content: $gps-marker; 
  }
}
.no-location {
  &:before {
    content: $no-location; 
  }
}
.no-gps {
  &:before {
    content: $no-gps; 
  }
}
.no-gps-marker {
  &:before {
    content: $no-gps-marker; 
  }
}
.arrow-left {
  &:before {
    content: $arrow-left; 
  }
}
.arrow-right {
  &:before {
    content: $arrow-right; 
  }
}

