@import './../../../styles/settings';
@import './../../../styles/color';
@import './../../../styles/mixins';

.skill{
	@include flexbox;
	@include flex-direction(column);
	margin-bottom: $grid*3;
	.skill-name{
		font-size: 1rem;
		line-height: 1;
		margin-bottom: $grid*2;
	}
	.skill-rating{
		@include flexbox;
		@include flex-direction(row);
		.skill-rating-point{
			margin-right: $grid/2;
			width: $grid*3;
			height: $grid/4;
			// border: 1px solid $primaryColor;
			background-color: rgba($primaryColor, .2);
			&.active{ background-color: $primaryColor; }
		}
	}
}