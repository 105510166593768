@import './../../styles/settings';
@import './../../styles/color';
@import './../../styles/mixins';

footer{
  text-align: center;
  padding: $grid*2 $grid*3;
  padding: $grid*30 0 $grid*5 0;
  //background-color: $primaryColor;
  //color: $secondaryColor;
  div{
    content:" ";
    width: $grid*10;
    height: $grid/4;
    background-color: $primaryColor;
    margin: $grid*2 auto;
    @at-root .invert &{
      background-color: $secondaryColor;
    }
  }
}