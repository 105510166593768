@import './../../../styles/settings';
@import './../../../styles/color';
@import './../../../styles/mixins';

svg.progress-rect{
  rect.progress-rect-stroke{
    stroke: rgba($primaryColor, .2);
  }
  rect.progress-rect-progress-cover{
    stroke: $secondaryColor;
  } 
  rect.progress-rect-progress{
    stroke: $primaryColor;
  } 
  .main-txt{
    font-size: $h4
  }
}