@import './../../../styles/settings';
@import './../../../styles/color';
@import './../../../styles/mixins';

$iconSize: $grid*6;
.social-icon {
	@include flexbox;
	@include flex-direction(row);
	min-width: $iconSize;
	height: $iconSize;
	background-color: $txtColor;
	font-family: $fontFamilyHeadline;
	font-size: $h6;
	color: $bgColor;
	line-height: $iconSize;
	text-align: center;
	vertical-align: middle;
	text-decoration: none;
	&:hover{ 	color: $bgColor; }
	.social-abbr {
		width: $iconSize;
		& + .social-name {
			display: none;
			padding: 0 $grid*2;
		}
		&:hover + .social-name {
			display: block;
		}
	}
}

.social-icon-wrapper {
	@include flexbox;
	@include flex-direction(column);
	@include align-items(flex-start);

	.social-icon {
		margin-bottom: 1px;
		float: left;
	}

	&.inline {
		@include flex-direction(row);

		.social-icon {
			margin-right: 1px;
			float: left;
		}
	}
}