@import './../../../styles/settings';
@import './../../../styles/color';
@import './../../../styles/mixins';

.modal-is-open{ overflow: hidden; }

.modal{
	display: none;
	width: 100%;
	height: 100vh;
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	transform:scale(0);

	background-color: rgba($secondaryColor, 0.98);

	&.close {
		transform:scale(1);
		animation:unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
		.modal-content{
			animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
		}
	}
	&.open{ 
		display: block;
		transform:scaleY(.01) scaleX(0);
		animation:unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
		.modal-content {
			transform:scale(0);
			animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
		}
	}
	&.invert{
		background-color: rgba(0,0,0, 0.98);
		color: $secondaryColor;
	}
	.modal-close, .modal-next, .modal-prev{
		height: $grid*5;
		width: $grid*5;
		cursor: pointer;

		color: $secondaryColor;
		background-color: rgba($primaryColor, 0.5);

		&:not(.dis):hover{ background-color: rgba(0,0,0, 1); }
		&.dis{ 
			opacity: 0.3;
			color: rgba($secondaryColor, .5);
			cursor: default;
		}
		i.icon{
			display: inline-block;
			line-height: $grid*5;
			height: $grid*5;
			width: $grid*5;
			text-align: center; 
		}
	}
	.modal-close{
		position: absolute;
		top: $grid*3;
		right: $grid*3;
	}
	.modal-nav-controller{
		position: absolute;
		bottom: $grid*3;
		right: $grid*3;

		@include flexbox;
		@include flex-direction(row);
		.modal-counter{ 
			line-height: $grid*5;
			padding: 0 $grid*2; 
		}
		.modal-prev, .modal-next{ margin-left: 2px; }
	}

	.modal-photography{
		@include flexbox;
		@include flex-direction(row);
		.modal-photo{
			@include flexbox;
			@include align-items(center);
			@include flex-grow(2);
			min-height: 100vh;
			
			img{
				margin: 0 auto;
				max-width: 100%;
				max-height: 100vh;
				//margin: 1vh;
			}
		}

		$details-brd-radius: 0;
		.modal-photo-details{
			width: $grid*45;
			margin: $grid*3;
			background-color: rgba($primaryColor, 0.5);
			border-radius: $details-brd-radius;
			@include flexbox;
			@include flex-direction(column);

			.details, .map{ height: 50%; }
			.details{
				@include flexbox;
				@include flex-direction(column);
				@include justify-content(center);
				padding: $grid*6;

				.detail{
					width: 348px;
					margin-bottom: $grid*3;
					@include flexbox;
					@include flex-direction(row);
					span{
						@include flexbox;
						@include flex-direction(column);
						.label{ font-size: $body-2;  color: rgba($secondaryColor, .5)};
						.val{ font-size: $body; }
					}
				}
			}
			.map{
				.mapboxgl-map{ 
					border-radius:0 0 $details-brd-radius $details-brd-radius; 
					.mapboxgl-ctrl{
						display: none !important;
						visibility: hidden;
						height: 1px;
						width: 1px;
						left: 0;
						bottom: 0;
					}
				}
				.mapboxgl-marker{
					i.icon{
						font-size: 4rem;
						color: $akzentColor;
						text-shadow: 2px 0 4px rgba($primaryColor, 0.24);
					}
				}
				.no-map-msg{
					width: 100%;
					height: 100%;
					background-color: rgba($secondaryColor, 0.05);
					@include flexbox;
					@include flex-direction(column);
					@include justify-content(center);
					@include align-items(center);
					i.icon{
						font-size: 8rem;
						line-height: 12rem; 
						opacity: .1;
					}
				}
			}
		}
	}
}

@keyframes unfoldIn {
  0% {
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform:scaleY(1) scaleX(1);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(.005) scaleX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}